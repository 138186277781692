import parameters from '@atlassian/jira-relay/src/__generated__/srcFullCoreQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncBulkOpsCoreFullEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-bulkOps-core-full" */ './src'),
	),
	getPreloadProps: ({ cloudId }: { cloudId: string }) => ({
		queries: {
			queryReference: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});
