/**
 * @generated SignedSource<<4ff7227cddb108452ddf6b3f35d6d597>>
 * @relayHash ed932e3a9d9639379c52dcf337027f34
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d0399e1dcc5bef453e30083f43581d3c86d60aab8f1a485736e24c3b95e46665

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ui_issueTableHierarchyToggle_HierarchyToggleMutation$variables = {
  isHierarchyEnabled: boolean;
  viewId: string;
};
export type ui_issueTableHierarchyToggle_HierarchyToggleMutation$data = {
  readonly jira: {
    readonly updateIssueSearchHierarchyPreference: {
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueTableHierarchyToggle_HierarchyToggleMutation = {
  response: ui_issueTableHierarchyToggle_HierarchyToggleMutation$data;
  variables: ui_issueTableHierarchyToggle_HierarchyToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHierarchyEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isHierarchyEnabled",
            "variableName": "isHierarchyEnabled"
          },
          {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
          }
        ],
        "concreteType": "JiraIssueSearchHierarchyPreferenceMutationPayload",
        "kind": "LinkedField",
        "name": "updateIssueSearchHierarchyPreference",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "d0399e1dcc5bef453e30083f43581d3c86d60aab8f1a485736e24c3b95e46665",
    "metadata": {},
    "name": "ui_issueTableHierarchyToggle_HierarchyToggleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "caf43b06bb046e13cf83843bc009eeea";

export default node;
