/**
 * @generated SignedSource<<937784964dbee120ab4bf21ed449b3ac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_project">;
  readonly " $fragmentType": "main_issueNavigator_ListView_project";
};
export type main_issueNavigator_ListView_project$key = {
  readonly " $data"?: main_issueNavigator_ListView_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_nativeIssueTable_NativeIssueTable_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "2c238584c7aa26733a9466fcb38e2f91";

export default node;
