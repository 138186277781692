import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	returnText: {
		id: 'issue-navigator.full-page-issue-app.return-text',
		defaultMessage: 'Return to search',
		description:
			'Label that is shown for a button that will return the user to the list of search results when clicked.',
	},
});

export default messages;
