import React, { useCallback, useRef, useMemo } from 'react';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useOrderByOpenState } from '../../../../../controllers/order-by-open-state/index.tsx';
import { useSelectedIssueStateOldActions } from '../../../../../controllers/selected-issue-state-old/index.tsx';
import type { SelectedIndex } from '../../../../../controllers/selected-issue-state-old/types.tsx';
import {
	useSelectedIssueIndex,
	useSelectedIssueKey,
} from '../../../../../controllers/selected-issue/facade.tsx';
import {
	useNextIssueKey,
	usePreviousIssueKey,
	useSelectedIssueActions,
} from '../../../../../controllers/selected-issue/hooks.tsx';
import messages from './messages.tsx';

type Props = {
	disableListControls?: boolean;
	onNavigateToIssue: (issueKey: string) => void;
	// TODO Remove issueIndex argument when cleaning up jira_spreadsheet_component_m1
	onIssueChangeEvent: (issueKey: IssueKey, issueIndex?: number | null) => void;
	focusedIssueIndex: SelectedIndex;
	focusedIssueKey: IssueKey | null;
};

const useUpdatingRef = <T,>(
	value: T,
): {
	current: T;
} => {
	const ref = useRef<T>(value);
	ref.current = value;
	return ref;
};

const KeyboardShortcuts = (props: Props) => {
	const { formatMessage } = useIntl();
	const propsRef = useUpdatingRef(props);
	const openStateRef = useUpdatingRef(useOrderByOpenState());
	const selectedIssueKey = useSelectedIssueKey();
	const selectedIssueKeyRef = useUpdatingRef(selectedIssueKey);

	let handleNext;
	let handlePrevious;
	let selectedIssueIndex = null;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		const { selectNextIssue, selectPreviousIssue } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const nextIssueKey = useNextIssueKey() ?? toIssueKey('');
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const nextIssueKeyRef = useRef(nextIssueKey);
		nextIssueKeyRef.current = nextIssueKey;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleNext = useCallback(() => {
			const { onIssueChangeEvent } = propsRef.current;
			if (selectNextIssue(true)) {
				onIssueChangeEvent && onIssueChangeEvent(nextIssueKeyRef.current);
			}
			// Dependencies must be stable as downstream useMemo has an empty dependencies array
		}, [propsRef, selectNextIssue]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const previousIssueKey = usePreviousIssueKey() ?? toIssueKey('');
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const previousIssueKeyRef = useRef(previousIssueKey);
		previousIssueKeyRef.current = previousIssueKey;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handlePrevious = useCallback(() => {
			const { onIssueChangeEvent } = propsRef.current;
			if (selectPreviousIssue(true)) {
				onIssueChangeEvent && onIssueChangeEvent(previousIssueKeyRef.current);
			}
			// Dependencies must be stable as downstream useMemo has an empty dependencies array
		}, [propsRef, selectPreviousIssue]);
	} else {
		const { getNextIssue, selectNextIssue, getPreviousIssue, selectPreviousIssue } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueStateOldActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleNext = useCallback(() => {
			const { onIssueChangeEvent } = propsRef.current;
			// getNextIssue and selectNextIssue need to be stable function references as
			// useMemo has an empty dependencies array
			const { issueKey, issueIndex } = getNextIssue();
			if (selectNextIssue(true)) {
				onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
			}
		}, [propsRef, getNextIssue, selectNextIssue]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		handlePrevious = useCallback(() => {
			const { onIssueChangeEvent } = propsRef.current;
			// getPreviousIssue and selectPreviousIssue need to be stable function references as
			// useMemo has an empty dependencies array
			const { issueKey, issueIndex } = getPreviousIssue();
			if (selectPreviousIssue(true)) {
				onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
			}
		}, [propsRef, getPreviousIssue, selectPreviousIssue]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		selectedIssueIndex = useSelectedIssueIndex();
	}
	// TODO Remove when cleaning up jira_spreadsheet_component_m1
	const selectedIssueIndexRef = useUpdatingRef(selectedIssueIndex);

	return (
		<Shortcuts
			keyMap={useMemo(
				() => ({
					j: {
						callback: () => {
							const { disableListControls } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							// handleNext needs to be a stable function references as useMemo has an empty dependencies array
							handleNext();
						},
						label: (
							<div>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.nextIssueIssueTermRefresh
										: messages.nextIssue,
								)}
							</div>
						),
					},
					k: {
						callback: () => {
							const { disableListControls } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							// handlePrevious needs to be a stable function references as useMemo has an empty dependencies array
							handlePrevious();
						},
						label: (
							<div>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.previousIssueIssueTermRefresh
										: messages.previousIssue,
								)}
							</div>
						),
					},
					y: {
						callback: () => {
							const [{ isOpen }, { open, close }] = openStateRef.current;
							isOpen ? close() : open();
						},
						label: <div>{formatMessage(messages.displaySortFields)}</div>,
					},
					z: {
						callback: () => {
							if (selectedIssueKeyRef.current) {
								propsRef.current.onNavigateToIssue(selectedIssueKeyRef.current);
							}
						},
						label: (
							<div>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.openIssueFullScreenIssueTermRefresh
										: messages.openIssueFullScreen,
								)}
							</div>
						),
					},
					arrowup: {
						callback: () => {
							const { disableListControls, focusedIssueIndex, focusedIssueKey } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							let isSelectedIssueFocused;
							if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
								isSelectedIssueFocused =
									focusedIssueKey !== null && focusedIssueKey === selectedIssueKeyRef.current;
							} else {
								isSelectedIssueFocused =
									focusedIssueIndex !== null && focusedIssueIndex === selectedIssueIndexRef.current;
							}
							if (isSelectedIssueFocused) {
								handlePrevious();
							}
						},
						label: (
							<div>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.previousIssueIssueTermRefresh
										: messages.previousIssue,
								)}
							</div>
						),
					},
					arrowdown: {
						callback: () => {
							const { disableListControls, focusedIssueIndex, focusedIssueKey } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							let isSelectedIssueFocused;
							if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
								isSelectedIssueFocused =
									focusedIssueKey !== null && focusedIssueKey === selectedIssueKeyRef.current;
							} else {
								isSelectedIssueFocused =
									focusedIssueIndex !== null && focusedIssueIndex === selectedIssueIndexRef.current;
							}
							if (isSelectedIssueFocused) {
								handleNext();
							}
						},
						label: (
							<div>
								{formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.nextIssueIssueTermRefresh
										: messages.nextIssue,
								)}
							</div>
						),
					},
				}),
				[], // eslint-disable-line react-hooks/exhaustive-deps
			)}
		/>
	);
};

KeyboardShortcuts.defaultProps = {
	disableListControls: false,
};

export default KeyboardShortcuts;
