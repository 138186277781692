import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchFailedTitle: {
		id: 'issue-navigator.issue-search.search-failed-title',
		defaultMessage: "We couldn't load your search results",
		description: 'Title of the error flag shown when an issue search failed.',
	},
	searchFailedDescription: {
		id: 'issue-navigator.issue-search.search-failed-description',
		defaultMessage: 'Refresh and try again.',
		description: 'Description of the error flag shown when an issue search failed.',
	},
});
