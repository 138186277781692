import { createContext } from 'react';

export type ColumnPickerMessageContextProps = {
	hasMaxSelectedColumns: boolean;
	filterName?: string;
};

const defaultMessageContext: ColumnPickerMessageContextProps = {
	hasMaxSelectedColumns: false,
};

export const ColumnPickerMessageContext =
	createContext<ColumnPickerMessageContextProps>(defaultMessageContext);
