import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import BaseFavoriteButton from '@atlassian/jira-favourite-button/src/index.tsx';
import { FILTERS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';

type Props = {
	isFavoriteInitial: boolean;
	filterId: string;
	favoriteItemName?: string | undefined;
};

export const FavoriteButton = ({ filterId, isFavoriteInitial, favoriteItemName }: Props) => (
	<BaseFavoriteButton
		key={filterId}
		baseUrl=""
		itemType={FILTERS_ITEM_TYPE}
		itemId={filterId}
		isFavoriteInitial={isFavoriteInitial}
		favoriteItemName={favoriteItemName}
		isShownInList
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavoriteButtonHeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	height: '32px',
	margin: `${token('space.0', '0')} ${token('space.050', '4px')}`,
});
