/**
 * @generated SignedSource<<7565b74db41a9facd1fb321d0c9b5a4b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_refetchQueryOld$data = {
  readonly jira: {
    readonly issueSearchStable: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly key: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults" | "main_issueNavigator_IssueNavigatorUI_issueResults" | "selectedIssueStateOld_issueNavigator_SelectedIssueContainer">;
    } | null | undefined;
    readonly issueSearchViewResult: {
      readonly __typename: "JiraIssueSearchView";
      readonly " $fragmentSpreads": FragmentRefs<"issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view" | "main_issueNavigator_IssueNavigatorUI_viewResult" | "selectedView_issueNavigator_SelectedViewContainer_issueSearchView">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_refetchQueryOld";
};
export type main_issueNavigator_IssueNavigator_refetchQueryOld$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_refetchQueryOld$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryOld">;
};

import IssueNavigatorResultsRefetchQuery_graphql from './IssueNavigatorResultsRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "Variable",
  "name": "issueSearchInput",
  "variableName": "issueSearchInput"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "issueTypeId"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "kind": "RootArgument",
      "name": "projectId"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": IssueNavigatorResultsRefetchQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_refetchQueryOld",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "before",
              "variableName": "before"
            },
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "fieldSetsInput",
              "variableName": "fieldSetsInput"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            },
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "last",
              "variableName": "last"
            },
            {
              "kind": "Variable",
              "name": "options",
              "variableName": "options"
            }
          ],
          "concreteType": "JiraIssueConnection",
          "kind": "LinkedField",
          "name": "issueSearchStable",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults"
            },
            {
              "kind": "FragmentSpread",
              "name": "selectedIssueStateOld_issueNavigator_SelectedIssueContainer"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_IssueNavigatorUI_issueResults"
            }
          ]
        },
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "filterId",
              "variableName": "filterId"
            },
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "namespace",
              "variableName": "namespace"
            },
            {
              "kind": "Variable",
              "name": "viewId",
              "variableName": "viewId"
            }
          ],
          "kind": "LinkedField",
          "name": "issueSearchViewResult",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "selectedView_issueNavigator_SelectedViewContainer_issueSearchView"
                }
              ],
              "type": "JiraIssueSearchView"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_IssueNavigatorUI_viewResult"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "3911e7583ee9b11587478d8e9487d8a2";

export default node;
