import React from 'react';
import { styled } from '@compiled/react';
import EmptyList from '../../../../../common/ui/empty-list-icon/index.tsx';

export const CardEmptyList = () => (
	// eslint-disable-next-line jira/integration/test-id-by-folder-structure
	<StyledContainer data-testid="issue-navigator.issue-results.detail-view.card-list.card-empty-list">
		<EmptyList />
	</StyledContainer>
);

export default CardEmptyList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	height: '100%',
	justifyContent: 'center',
});
