import React, { memo, type ComponentType } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import {
	GroupByDropdown as GroupByDropdownWithoutErrorBoundary,
	type GroupByDropdownProps,
} from './ui/index.tsx';

export const GroupByDropdown: ComponentType<GroupByDropdownProps> = memo<GroupByDropdownProps>(
	(props: GroupByDropdownProps) => (
		<JSErrorBoundary
			fallback="unmount"
			id="issue-table-group-by-dropdown"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<GroupByDropdownWithoutErrorBoundary {...props} />
		</JSErrorBoundary>
	),
);
