import React from 'react';
import { styled } from '@compiled/react';
import { NativeIssueTableSkeleton } from '@atlassian/jira-skeletons/src/ui/native-issue-table/components/NativeIssueTableSkeleton.tsx';

export const IssueTableSkeleton = () => (
	<SkeletonContainer>
		<NativeIssueTableSkeleton />
	</SkeletonContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	flexGrow: 1,
	overflow: 'hidden',
});
