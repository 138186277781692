import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'lodash/isEqual';
import { useRelayEnvironment, type RefetchFn } from 'react-relay';
import {
	type GraphQLTaggedNode,
	createOperationDescriptor,
	commitLocalUpdate,
	type Disposable,
	getRequest,
	fetchQuery,
	type FetchQueryFetchPolicy,
	type Subscription,
} from 'relay-runtime';
import uuid from 'uuid';
import { useFlagsService, toFlagId, type FlagConfiguration } from '@atlassian/jira-flags';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type {
	IssueNavigatorResultsRefetchQuery as IssueNavigatorRefetchQueryOld,
	IssueNavigatorResultsRefetchQuery$variables as IssueNavigatorQueryVariables,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorResultsRefetchQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	MAX_ISSUES_PER_PAGE,
	PACKAGE_NAME,
	MAX_AMOUNT_OF_COLUMNS,
} from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';
import {
	useIssueSearchTotalCount,
	type UncappedTotalIssueCountFetcher,
	type UncappedTotalIssueCount,
} from '../issue-search-total-count/index.tsx';
import { useIssueByFieldsRefetch } from '../refetch-issue/index.tsx';
import messages from './messages.tsx';

export type RequestEventHandlers = Partial<{
	onComplete: () => void;
	onError: () => void;
	onUnsubscribe: () => void;
}>;

type OnIssueSearchRefetchType = (
	variables: Partial<{
		after: string | null;
		first: number;
		last: number;
		viewId: string;
	}>,
	options?: RequestEventHandlers &
		Partial<{
			fetchPolicy: FetchQueryFetchPolicy;
			// Set a new search key when the request has finished. Defaults to `true`.
			isNewSearchKey: boolean;
		}>,
) => void;

type OnIssueByFieldsRefetchType = (issuekey: string) => void;

type IssueSearchRefetchReponse = {
	/**
	 * This property will be `true` while Relay is fetching data.
	 */
	isFetching: boolean;
	/**
	 * This property is used to denote if view id has changed from previous request
	 */
	hasViewIdChanged: boolean;
	/**
	 * This property will be `true` when the most recent fetch operation has failed.
	 */
	isNetworkError: boolean;
	/**
	 * Unique key that can be used to identify a new connection of search results.
	 */
	searchKey: string;
	/**
	 * This will refetch issue and field configuration data using the provided variables and options.
	 */
	onIssueSearchRefetch: OnIssueSearchRefetchType;
	/**
	 * This will refetch issue data using visible field configuration data provided.
	 */
	onIssueByFieldsRefetch: OnIssueByFieldsRefetchType;
	/**
	 * When called will fetch the uncapped total count of an issue search, given
	 * JQL or filterID
	 */
	onFetchUncappedTotalIssueCount: UncappedTotalIssueCountFetcher;
	/**
	 * stores the most recent uncapped total issue count, along with its loading and error state
	 */
	uncappedTotalIssueCount: UncappedTotalIssueCount;
};

type IssueSearchInput = {
	jql?: string | null;
	filterId?: string | null;
};

const searchErrorFlagId = toFlagId('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
const searchErrorFlag: FlagConfiguration = {
	id: searchErrorFlagId,
	type: 'error',
	title: messages.searchFailedTitle,
	description: messages.searchFailedDescription,
};

export const useIssueSearchRefetch = (
	refetch: RefetchFn<IssueNavigatorRefetchQueryOld>,
	query: GraphQLTaggedNode,
	issueSearchInput: IssueSearchInput,
	viewId: IssueNavigatorViewId,
	connectionId: string | undefined,
	fieldSetIds: string[],
	onIssueSearchFail: (location: string, error: Error, view: IssueNavigatorViewId) => void,
): IssueSearchRefetchReponse => {
	// Used to track and force a network-only request when a failed search has been cached
	const lastVariables = useRef<IssueNavigatorQueryVariables>();
	const networkOnlyVariables = useRef(new Set<IssueNavigatorQueryVariables>());

	const connectionIds = useRef(new Set<string>());
	const disposableOperations = useRef(new Set<Disposable>());
	const [searchKey, setSearchKey] = useState(uuid());
	const [isFetching, setIsFetching] = useState(false);
	const [isNetworkError, setNetworkError] = useState(false);

	const { cloudId } = useTenantContext();

	const { uncappedTotalIssueCount, resetTotalIssueCount, onFetchUncappedTotalIssueCount } =
		useIssueSearchTotalCount({ cloudId, issueSearchInput });

	const environment = useRelayEnvironment();
	const { showFlag, dismissFlag } = useFlagsService();

	const prevViewId = usePreviousWithInitial(viewId);
	const prevFieldSetIds = usePreviousWithInitial(fieldSetIds);

	const inFlightRequest = useRef<Subscription>();

	// Release data for each fetchQuery operation allowing them to be garbage collected by Relay
	const onDisposeOperations = useCallback(() => {
		disposableOperations.current.forEach((disposable) => disposable.dispose());
		disposableOperations.current.clear();
	}, []);

	// Dispose operations on unmount. This happens automatically for Relay query hooks, but we need to do it ourselves
	// when using fetchQuery.
	useEffect(() => onDisposeOperations, [onDisposeOperations]);

	const onIssueByFieldsRefetch = useIssueByFieldsRefetch(fieldSetIds);

	/**
	 * Invalidate all tracked issue connections so any subsequent queries with a matching identifier will hit the
	 * network.
	 */
	const onInvalidateIssueConnection = useCallback(() => {
		commitLocalUpdate(environment, (store) => {
			connectionIds.current.forEach((id) => {
				const connection = store.get(id);
				connection && connection.invalidateRecord();
			});
			connectionIds.current.clear();
		});
	}, [environment]);

	const onInvalidateAndDisposeIssueConnection = useCallback(() => {
		onInvalidateIssueConnection();
		onDisposeOperations();
	}, [onDisposeOperations, onInvalidateIssueConnection]);

	useEffect(() => {
		// If our fieldSetIds have changed then we must invalidate our connections so we don't serve stale issue data
		// with mismatched field sets.
		if (!isEqual(fieldSetIds, prevFieldSetIds) && viewId === prevViewId) {
			onInvalidateIssueConnection();
		}
		if (connectionId !== undefined) {
			// Track ids of our issue connections so they can be invalidated
			connectionIds.current.add(connectionId);
		} else if (lastVariables.current) {
			// If we do not receive a connection id then add the last used variables to our set of network-only
			// variables. This prevents us serving null responses from cache when there is no connection id to invalidate.
			networkOnlyVariables.current.add(lastVariables.current);
		}
	});

	const onIssueSearchRefetch: OnIssueSearchRefetchType = useCallback(
		(variables, options) => {
			// Some consumers may fire refetch queries before in-flight query
			// has been completed (e.g. while user is toggling columns on and off in
			// the column picker). In these situations, we always want to fetch latest
			// data from the API as column configuration may have changed for the user.
			// To do that, we disable Relay's automatic de-deduping of requests.
			inFlightRequest.current?.unsubscribe();

			const finalVariables: IssueNavigatorQueryVariables = {
				cloudId,
				issueSearchInput,
				first: MAX_ISSUES_PER_PAGE,
				after: null,
				namespace: 'ISSUE_NAVIGATOR',
				viewId,
				options: null,
				fieldSetIds: [],
				shouldQueryFieldSetsById: false,
				amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
				filterId: null,
				...variables,
			};

			setIsFetching(true);
			setNetworkError(false);
			// Hide any search error flags currently visible
			dismissFlag(searchErrorFlagId);

			let fetchPolicy = options?.fetchPolicy ?? 'store-or-network';

			// Check if our final variables match any of our network-only variables, and if so update the fetch policy.
			networkOnlyVariables.current.forEach((value) => {
				if (isEqual(finalVariables, value)) {
					fetchPolicy = 'network-only';
					networkOnlyVariables.current.delete(value);
				}
			});

			if (fetchPolicy === 'network-only') {
				onInvalidateAndDisposeIssueConnection();
			}

			inFlightRequest.current = fetchQuery<IssueNavigatorRefetchQueryOld>(
				environment,
				query,
				finalVariables,
				{
					fetchPolicy,
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				complete: () => {
					/*
					 * fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
					 * data will remain saved in the Relay store at any point after the request completes.
					 * We need to explicitly retain the operation to ensure it doesn't get deleted.
					 * See https://relay.dev/docs/api-reference/fetch-query/#behavior
					 */
					const operation = createOperationDescriptor(getRequest(query), finalVariables);
					disposableOperations.current.add(environment.retain(operation));

					lastVariables.current = finalVariables;

					let hasStoreRefetchCompleted = false;
					// We need to batch these updates as without it, Relay will cause multiple renders and invoke the
					// onComplete callback multiple times. This causes our success/fail analytics events to become
					// unreliable.
					// We introduced a similar fix for fetchQuery (see src/packages/platform/graphql/relay-scheduler/src/index.js)
					// and in React 18 will be able to remove this in favour of the startTransition/useTransition APIs.
					ReactDOM.unstable_batchedUpdates(() => {
						refetch(finalVariables, {
							fetchPolicy: 'store-only',
							onComplete: () => {
								// Relay will dispose the cached operation after a 5m timeout, in which case it will
								// refetch the query from the store and retrigger the onComplete callback. We need to
								// ensure this callback is invoked strictly once to prevent unexpected side effects,
								// e.g. https://jira.atlassian.com/browse/JRACLOUD-84106.
								if (hasStoreRefetchCompleted) {
									return;
								}

								hasStoreRefetchCompleted = true;
								setIsFetching(false);
								options?.onComplete && options.onComplete();
								if (options?.isNewSearchKey ?? true) {
									setSearchKey(uuid());
									if (
										/*
										 * Once a refetch has completed, go back and inspect
										 * the request variables for the existence of an `after` cursor.
										 * If an `after` cursor was provided to the query, this means that
										 * the query was triggered via user navigating to a different page of
										 * results for the current search (I.e. by clicking pagination controls).
										 * For such events, do not reset the total issue count. We want it to
										 * be preserved across pagination.
										 */
										!finalVariables.after
									) {
										resetTotalIssueCount();
									}
								}
							},
						});
					});
				},
				error: (error: Error) => {
					setIsFetching(false);
					setNetworkError(true);
					onIssueSearchFail(`${PACKAGE_NAME}.issue-search-refetch`, error, viewId);
					showFlag(searchErrorFlag);
					options?.onError && options.onError();
				},
				unsubscribe: () => {
					options?.onUnsubscribe && options.onUnsubscribe();
				},
			});
		},
		[
			cloudId,
			issueSearchInput,
			viewId,
			dismissFlag,
			environment,
			query,
			onInvalidateAndDisposeIssueConnection,
			refetch,
			resetTotalIssueCount,
			onIssueSearchFail,
			showFlag,
		],
	);

	return {
		isFetching,
		hasViewIdChanged: viewId !== prevViewId,
		isNetworkError,
		onFetchUncappedTotalIssueCount,
		onIssueSearchRefetch,
		onIssueByFieldsRefetch,
		searchKey,
		uncappedTotalIssueCount,
	};
};
