/**
 * @generated SignedSource<<30a8994404dfcae5d894d268d148ffe9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraJQLBuilderSearchMode = "ADVANCED" | "BASIC" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type spotlight_issueNavigator_IssueNavigatorSpotlight$data = {
  readonly jqlBuilderSearchMode: JiraJQLBuilderSearchMode | null | undefined;
  readonly " $fragmentType": "spotlight_issueNavigator_IssueNavigatorSpotlight";
};
export type spotlight_issueNavigator_IssueNavigatorSpotlight$key = {
  readonly " $data"?: spotlight_issueNavigator_IssueNavigatorSpotlight$data;
  readonly " $fragmentSpreads": FragmentRefs<"spotlight_issueNavigator_IssueNavigatorSpotlight">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "spotlight_issueNavigator_IssueNavigatorSpotlight",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jqlBuilderSearchMode"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "3c8e72948beb901cccd7a6650610b18e";

export default node;
