import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	onboardingHeading: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.onboarding-heading',
		defaultMessage: 'View issue hierarchy',
		description: 'Onboarding title for the hierarchy feature',
	},
	onboardingDescription: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.onboarding-description',
		defaultMessage: 'Collapse or expand the hierarchy to see parent and child issues.',
		description: 'Onboarding message for the hierarchy feature',
	},
	next: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.next',
		defaultMessage: 'Next',
		description: 'Cta label to navigate to the next card in the onboarding',
	},
	toggleOnboarding: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.rules-order-onboarding',
		defaultMessage: 'Use the toggle to show or hide the issue hierarchy.',
		description: 'Onboarding message for to toggle the hierarchy feature',
	},
	ok: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.done',
		defaultMessage: 'Ok',
		description: 'Cta label to finalize the onboarding',
	},
	dismiss: {
		id: 'business-conditional-formatting-sidebar.format-rules-sidebar.sidebar.onboarding.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Cta label to dismiss the onboarding',
	},
});
