import type { KeyType } from 'react-relay/relay-hooks/helpers';
import { KNOWN_COLUMN_TYPES } from '../constants.tsx';
import type { KnownColumnType } from '../types.tsx';

/**
 * Type predicate to determine that a value is not `null` or `undefined`.
 */
export function isNonNullish<T>(value: T | null | undefined): value is T {
	return value != null;
}

export function isKnownColumnType(value: string | undefined): value is KnownColumnType {
	return value !== undefined && Object.values<string>(KNOWN_COLUMN_TYPES).includes(value);
}

export function exclusiveGroupsOrIssuesFragment<
	GroupsFragment extends KeyType,
	IssuesFragment extends KeyType,
>({
	groups,
	issues,
}: {
	groups: GroupsFragment | null;
	issues: IssuesFragment | null;
}): { groups: GroupsFragment; issues: null } | { groups: null; issues: IssuesFragment } | null {
	if (issues) {
		return { groups: null, issues };
	}
	if (groups) {
		return { groups, issues: null };
	}

	return null;
}

export function getProjectKeyFromIssueKey(issueKey: string): string {
	return issueKey.split('-')[0];
}

// TODO: escapeBackslashes serves as a temporary patch to convert jqlTerm into a format suitable for creator API.
// EM-6563 should resolve this by updating creator API to accept the semantic JQL value for jqlTerm currently returned by hydrateJqlQuery.
export const escapeBackslashes = (text: string) => text.replace(/\\/g, '\\\\');

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getColumnConfig } from './get-column-config';
