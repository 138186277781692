/**
 * @generated SignedSource<<cdeacb15335634351a83b6eeb614c997>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issuePagination_issueNavigator$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly totalIssueSearchResultCount: number;
  readonly " $fragmentType": "issuePagination_issueNavigator";
};
export type issuePagination_issueNavigator$key = {
  readonly " $data"?: issuePagination_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"issuePagination_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issuePagination_issueNavigator",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "totalIssueSearchResultCount"
      },
      "action": "THROW",
      "path": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "252cad0134e2ce14b78595c597895b8f";

export default node;
