import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issues: {
		id: 'issue-navigator.issue-results.detail-view.card-list.issues',
		defaultMessage: 'Issues',
		description: 'Name of card list for screen reader users',
	},
	issuesIssueTermRefresh: {
		id: 'issue-navigator.issue-results.detail-view.card-list.issues-issue-term-refresh',
		defaultMessage: 'Work items',
		description: 'Name of card list for screen reader users',
	},
});
