/**
 * @generated SignedSource<<9b849f64e4a61be907eaec3accbf05bb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_jira$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_jira";
};
export type main_issueNavigator_IssueNavigator_jira$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_jira$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_jira">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigator_jira",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e871bd0eb341a6573bd1df76ea8e5df1";

export default node;
