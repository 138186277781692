/**
 * @generated SignedSource<<abd491e0ea819f726aff4c19629983ff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly fieldSetsForIssueSearchView: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly fields: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly fieldId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      };
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore";
};
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key = {
  readonly " $data"?: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "issueTypeId"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "projectId"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            },
            {
              "kind": "RequiredField",
              "field": {
                "args": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "issueType",
                            "variableName": "issueTypeId"
                          },
                          {
                            "kind": "Variable",
                            "name": "project",
                            "variableName": "projectId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "projectContext"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "context"
                  },
                  {
                    "kind": "Variable",
                    "name": "filterId",
                    "variableName": "filterId"
                  },
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "amountOfColumns"
                  },
                  {
                    "kind": "Variable",
                    "name": "namespace",
                    "variableName": "namespace"
                  },
                  {
                    "kind": "Variable",
                    "name": "viewId",
                    "variableName": "viewId"
                  }
                ],
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsForIssueSearchView",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "fieldId"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "__typename"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              "action": "THROW",
              "path": "edges.node.fieldSetsForIssueSearchView"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "0a4d62fb7518ba0b4add8cfe9ff88015";

export default node;
