import type { ReactNode } from 'react';
import type { selectedIssueStateOld_issueNavigator_SelectedIssueContainer$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/selectedIssueStateOld_issueNavigator_SelectedIssueContainer.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { View } from '../../common/types.tsx';

export type SelectedIndex = number | null;

/**
 * Default reset strategy that will select the first issue in the connection.
 */
export const DEFAULT_FIRST = 'DEFAULT_FIRST' as const;
/**
 * Reset strategy that will force selection of the first issue in the connection.
 *
 * This will select the same issue as DEFAULT_FIRST but has an important semantic difference! FORCE_FIRST is only used
 * when paginating through an issue connection, this allows the full-page-issue-app to stay in full page mode when
 * paginating through issues and exit full page mode when a new search is run.
 */
export const FORCE_FIRST = 'FORCE_FIRST' as const;
/**
 * Reset strategy that will force selection of the last issue in the connection.
 */
export const FORCE_LAST = 'FORCE_LAST' as const;

type InternalStoreType = {
	_rememoize: number;
	selectedIssue: [SelectedIndex, IssueKey];
	focusedIssue: number | null;
	/**
	 * Determines the strategy that will be used to reset the selected issue when a new connection of search results is
	 * loaded.
	 */
	nextResetStrategy: typeof DEFAULT_FIRST | typeof FORCE_FIRST | typeof FORCE_LAST;
	/**
	 * Enable/disable display of the full page issue app.
	 */
	isFullPageIssueAppMode: boolean;
};

type BaseStoreType = {
	/**
	 * Unique key that can be used to identify a new connection of search results.
	 */
	searchKey: string;
};

export type StoreType = InternalStoreType & BaseStoreType;

type RequestEventHandlers = Partial<{
	onComplete: () => void;
	onError: () => void;
	onUnsubscribe: () => void;
}>;

export type PropType = BaseStoreType & {
	isFetching: boolean;
	selectedIssueKey: IssueKey;
	/**
	 * The first issue key in the issue list. This will only be provided when issue data HAS changed, e.g. refresh, JQL
	 * change, pagination. It will be set to `undefined` if there is no first issue or issue data HAS NOT changed, e.g.
	 * toggling list and detail view.
	 */
	firstIssueKey?: IssueKey | undefined;
	onChange?: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
	view: View | undefined;
	/**
	 * Number that is incremented whenever issue list data has changed. The sweet-state container is a pure component so
	 * this is provided to ensure the update method is triggered and first issue key is correctly selected on refresh,
	 * JQL change, pagination etc.
	 */
	dataChangedKey?: number;
	issueKeys: IssueKey[];
	/**
	 * Event emitted to retrieve the next page of issues.
	 */
	onNextPage: (options?: RequestEventHandlers) => boolean;
	/**
	 * Event emitted to retrieve the previous page of issues.
	 */
	onPreviousPage: (options?: RequestEventHandlers) => boolean;
	/**
	 * Event emitted to retrieve a page of issues after the given cursor.
	 */
	onPageChange: (after: string, options?: RequestEventHandlers) => void;
	/**
	 * Position of the first issue in the page relative to a stable search of issues, e.g. page=2; firstIssuePosition 51;
	 */
	firstIssuePosition: number | null;
	/**
	 * Position of the last issue in the page relative to a stable search of issues, e.g. page=2; lastIssuePosition 100;
	 */
	lastIssuePosition: number | null;
	/**
	 * First issue key on the next page of issues to enable optimistic issue selection.
	 */
	firstIssueKeyFromNextPage: string | null;
	/**
	 * Last issue key on the previous page of issues to enable optimistic issue selection.
	 */
	lastIssueKeyFromPreviousPage: string | null;
};

export type PropTypeExternal = {
	selectedIssueKey: IssueKey;
	issueResults: IssueResultsFragment | null;
	onChange: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
	children: ReactNode;
};

export type ActionType = {
	/**
     * Update the selected issue by key or by index in the store and call the onChange function if required to notify listeners.

     * @param shouldDebounce True if the issue key selection should be debounced.
     * @param shouldNotifyOnChange True to call the onChange function.
     * @param isSelectedByUserInteraction True if this issue was selected by a user interaction, e.g. clicking an issue
     * in the list. This will be false if an issue was automatically selected by the app, e.g. automatically selecting
     * the first issue once the issue list has loaded.
     */

	/**
	 * @param issueKey Issue key to select.
	 */
	setSelectedIssueByKey: (
		issueKey: IssueKey,
		shouldDebounce?: boolean,
		shouldNotifyOnChange?: boolean,
		isSelectedByUserInteraction?: boolean,
	) => Action<StoreType, PropType>;
	/**
	 * @param index: represents the position of the issue in the data beginning from 0.
	 * Used by detail view and list view where the issueKey might not exist
	 * because of 'blank' issues (deleted issues or issues where the user has no permissions to see)
	 */
	setSelectedIssueByIndex: (
		index: number,
		shouldDebounce?: boolean,
		shouldNotifyOnChange?: boolean,
		isSelectedByUserInteraction?: boolean,
	) => Action<StoreType, PropType>;

	/**
	 * Return the position of the currently selected issue in the search results or `null` if the selected issue could not
	 * be found.
	 */
	getSelectedIssuePosition: () => Action<StoreType, PropType, number | null>;

	/**
	 * Return the next issue in the search results from the currently selected issue. If the selected issue is the last item
	 * in the page of search results then the first key from the next page is returned.
	 */
	getNextIssue: () => Action<
		StoreType,
		PropType,
		{ issueKey: IssueKey; issueIndex: number | null }
	>;

	/**
	 * Return the previous issue in the search results from the currently selected issue. If the selected issue is the first
	 * item in the page of search results then the last key from the previous page is returned.
	 */
	getPreviousIssue: () => Action<
		StoreType,
		PropType,
		{ issueKey: IssueKey; issueIndex: number | null }
	>;
	/**
	 * Select the next issue in the search results (if there is a valid selection). Returns `true` if an issue was selected,
	 * otherwise `false`.
	 */
	selectNextIssue: (shouldDebounce?: boolean) => Action<StoreType, PropType, boolean>;

	/**
	 * Select the previous issue in the search results (if there is a valid selection). Returns `true` if an issue was
	 * selected, otherwise `false`.
	 */
	selectPreviousIssue: (shouldDebounce?: boolean) => Action<StoreType, PropType, boolean>;

	/**
     * Set the index of the issue that should receive focus.

     * @param index: represents the position of the issue in the data beginning from 0.
     * Used by detail view and list view where the issueKey might not exist
     * because of 'blank' issues (deleted issues or issues where the user has no permissions to see)
     */
	setFocusedIssueByIndex: (index: number) => Action<StoreType, PropType>;

	resetFocusedIssue: () => Action<StoreType, PropType>;

	/**
	 * Used when there is no issue to select. The current selected Issue is removed.
	 * e.g. on delete or data with only blank issues
	 */
	deselectIssue: (
		shouldDebounce?: boolean,
		shouldNotifyOnChange?: boolean,
		isSelectedByUserInteraction?: boolean,
	) => Action<StoreType, PropType>;

	/**
	 * Emit an event to fetch the next page of issues and force the first issue on the page (including null issues) to
	 * be selected when the new connection of search results is loaded.
	 */
	selectFirstIssueOnNextPage: () => Action<StoreType, PropType>;

	/**
	 * Emit an event to fetch the previous page of issues and force the last issue on the page (including null issues)
	 * to be selected when the new connection of search results is loaded.
	 */
	selectLastIssueOnPreviousPage: () => Action<StoreType, PropType>;

	/**
	 * Emit an event to fetch a page of issues after the given cursor. When `shouldSelectLastIssue` is `true` this will
	 * force selection of the last issue in the new connection once loaded.
	 */
	selectIssueOnPage: (after: string, shouldSelectLastIssue: boolean) => Action<StoreType, PropType>;

	/**
	 * Enables display of the full page issue app.
	 */
	enterFullPageIssueAppMode: () => Action<StoreType, PropType>;

	/**
	 * Disables display of the full page issue app.
	 */
	exitFullPageIssueAppMode: () => Action<StoreType, PropType>;
};
