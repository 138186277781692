/**
 * @generated SignedSource<<bf6f4889dc46a16ac5763743e2fd7107>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Header_viewResult$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem">;
  readonly " $fragmentType": "main_issueNavigator_Header_viewResult";
};
export type main_issueNavigator_Header_viewResult$key = {
  readonly " $data"?: main_issueNavigator_Header_viewResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Header_viewResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_Header_viewResult",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "3a995e6dfd4368af6efd0437b7e320e0";

export default node;
