import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldNotFoundMessage: {
		id: 'issue-navigator.jql-builder.no-options-message.field-not-found-message',
		defaultMessage: "We couldn't find the field you are looking for.",
		description: 'The text displayed when search results for fields do not return matching results',
	},
	noOptionsPrimaryMessage: {
		id: 'issue-navigator.jql-builder.no-options-message.no-options-primary-message',
		defaultMessage: "We couldn't find what you're looking for",
		description:
			'The text for when no matches are found in dropdown when a user tries to search for an option',
	},
	noOptionsSecondaryMessage: {
		id: 'issue-navigator.jql-builder.no-options-message.no-options-secondary-message',
		defaultMessage: 'Try using different search terms.',
		description:
			'Helpful tip for when no matches are found in dropdown, prompting user to try a different search',
	},
});
