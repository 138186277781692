import React, { Component, useCallback } from 'react';
import noop from 'lodash/noop';
import { IconButton } from '@atlaskit/button/new';
import Icon, { type IconProps } from '@atlaskit/icon';
import SortAscendingIcon from '@atlaskit/icon/core/sort-ascending';
import SortDescendingIcon from '@atlaskit/icon/core/sort-descending';
import Tooltip from '@atlaskit/tooltip';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	sortDirections,
	supportedOrderFields,
	getSupportedFieldForAlias,
} from '../../../../../common/constants.tsx';
import type { SortDirection } from '../../../../../common/types.tsx';
import { useSortFieldAndDirection } from '../../../../../controllers/sort-field-and-direction-state/index.tsx';
import { ArrowDownIcon as ArrowDownIconGlyph } from './assets/sorting-down-icon.tsx';
import { ArrowUpIcon as ArrowUpIconGlyph } from './assets/sorting-up-icon.tsx';
import messages from './messages.tsx';

type SortButtonProps = {
	isDisabled?: boolean;
	sortDirection: SortDirection | undefined;
	onSort: (sortDirection: SortDirection) => boolean;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class SortButton extends Component<SortButtonProps> {
	static defaultProps = {
		onSort: noop,
	};

	shouldComponentUpdate(nextProps: SortButtonProps) {
		return (
			this.props.sortDirection !== nextProps.sortDirection ||
			this.props.isDisabled !== nextProps.isDisabled
		);
	}

	onClick = () => {
		const { onSort, sortDirection } = this.props;
		const newSortDirection =
			sortDirection === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC;
		onSort(newSortDirection);
	};

	render() {
		const {
			intl: { formatMessage },
			sortDirection,
			isDisabled,
		} = this.props;

		const tooltipText =
			sortDirection === sortDirections.ASC
				? formatMessage(messages.tooltipAsc)
				: formatMessage(messages.tooltipDesc);

		const glyph = sortDirection === sortDirections.ASC ? ArrowUpIconGlyph : ArrowDownIconGlyph;
		const SortIcon = sortDirection === sortDirections.ASC ? SortAscendingIcon : SortDescendingIcon;

		return (
			<Tooltip content={tooltipText}>
				<IconButton
					appearance="subtle"
					onClick={this.onClick}
					testId="issue-navigator.ui.issue-results.detail-view.card-container.sort-button.sort-button"
					isDisabled={isDisabled}
					icon={(props: IconProps) =>
						isVisualRefreshEnabled() ? (
							<SortIcon {...props} color="currentColor" />
						) : (
							<Icon {...props} size="small" glyph={glyph} />
						)
					}
					label={tooltipText}
				/>
			</Tooltip>
		);
	}
}

type Props = {
	className?: string;
};
export const SortButtonWithState = (props: Props) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [{ field, direction }, { setFieldAndDirection }] = useSortFieldAndDirection();
	const handleOnSort = useCallback(
		(newSortDirection: SortDirection) => {
			typeof field === 'string' && setFieldAndDirection(field, newSortDirection, []);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			const orderField = field
				? supportedOrderFields[getSupportedFieldForAlias(field) ?? field]
				: undefined;

			fireUIAnalytics(analyticsEvent, 'sortOrderChangeButton', {
				fieldType: orderField?.fieldType ?? null,
				sortOrder: newSortDirection,
			});

			return true;
		},
		[createAnalyticsEvent, field, setFieldAndDirection],
	);

	return (
		<SortButton
			isDisabled={typeof field !== 'string'}
			sortDirection={direction}
			onSort={handleOnSort}
			{...props}
			intl={intl}
		/>
	);
};

export default SortButtonWithState;
