/**
 * @generated SignedSource<<4c74d84ebb90c8b29e6b6506b7860f2a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardContainer_issueNavigator_view$data = {
  readonly fieldSets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldSetId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "cardContainer_issueNavigator_view";
};
export type cardContainer_issueNavigator_view$key = {
  readonly " $data"?: cardContainer_issueNavigator_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    }
  ],
  "kind": "Fragment",
  "name": "cardContainer_issueNavigator_view",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "fieldSetSelectedState": "SELECTED"
          }
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "amountOfColumns"
        }
      ],
      "concreteType": "JiraIssueSearchFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSets",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueSearchFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueSearchFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldSetId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "ce5870ed254708a733323a7d67bf3692";

export default node;
