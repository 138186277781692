/**
 * @generated SignedSource<<524b4b9dc23d31e46f2bde51014b0f1a>>
 * @relayHash 56eacc89d0fb63c9dd5708edcdf236bb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd64c0ee5748593ab7907d0da59a621781d4b6fdcc4323e7498f01cff0614ee0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  isRefactorNinToViewSchemaEnabled: boolean;
  issueSearchInput: JiraIssueSearchInput;
  issueTypeId?: string | null | undefined;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectId?: string | null | undefined;
  projectKeys?: ReadonlyArray<string> | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
};
export type IssueNavigatorIssueSearchPaginationQuery = {
  response: IssueNavigatorIssueSearchPaginationQuery$data;
  variables: IssueNavigatorIssueSearchPaginationQuery$variables;
};
({
  "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly isRefactorNinToViewSchemaEnabled: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isRefactorNinToViewSchemaEnabled_provider from '@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRefactorNinToViewSchemaEnabled"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeId"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKeys"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v19 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v20 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v21 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v22 = {
  "kind": "ScalarField",
  "name": "id"
},
v23 = {
  "kind": "ScalarField",
  "name": "key"
},
v24 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v25 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v26 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v25/*: any*/),
    (v22/*: any*/)
  ]
},
v27 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v28 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "issueType",
            "variableName": "issueTypeId"
          },
          {
            "kind": "Variable",
            "name": "project",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "projectContext"
      }
    ],
    "kind": "ObjectValue",
    "name": "context"
  },
  {
    "kind": "Variable",
    "name": "filterId",
    "variableName": "filterId"
  },
  (v27/*: any*/),
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v29 = {
  "kind": "ScalarField",
  "name": "text"
},
v30 = {
  "kind": "ScalarField",
  "name": "name"
},
v31 = {
  "kind": "ScalarField",
  "name": "picture"
},
v32 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v33 = {
  "kind": "ScalarField",
  "name": "type"
},
v34 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v35 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v36 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v30/*: any*/),
                (v22/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v37 = {
  "kind": "ScalarField",
  "name": "message"
},
v38 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbMediaClientConfig",
                          "kind": "LinkedField",
                          "name": "mediaClientConfig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "clientId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "fileId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaBaseUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaJwtToken"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v37/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v39 = [
  (v30/*: any*/),
  (v22/*: any*/)
],
v40 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v39/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v41 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v42 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v30/*: any*/),
    (v41/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v44 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v45 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v23/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v26/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v48 = [
  (v30/*: any*/)
],
v49 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v50 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v48/*: any*/)
            }
          ]
        },
        (v49/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v30/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v51 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v52 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v23/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v29/*: any*/),
            (v22/*: any*/)
          ]
        },
        (v22/*: any*/),
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                },
                (v22/*: any*/)
              ]
            },
            (v22/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                (v51/*: any*/),
                (v30/*: any*/),
                (v22/*: any*/)
              ]
            },
            (v22/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v53 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        },
        (v22/*: any*/)
      ]
    },
    (v30/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v23/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v55 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v39/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v56 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v22/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                (v30/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v30/*: any*/),
    (v42/*: any*/)
  ],
  "type": "JiraSprintField"
},
v57 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v58 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    (v30/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v39/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v61 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v51/*: any*/),
        (v22/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v62 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v63 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v62/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v49/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v23/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v21/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v26/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v22/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v22/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v22/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v64 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isStoryPointField"
        },
        {
          "kind": "ScalarField",
          "name": "number"
        },
        (v30/*: any*/),
        (v42/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v65 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v33/*: any*/),
          (v34/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v24/*: any*/),
                      (v33/*: any*/),
                      (v35/*: any*/),
                      (v22/*: any*/),
                      (v36/*: any*/),
                      (v38/*: any*/),
                      (v40/*: any*/),
                      (v43/*: any*/),
                      (v44/*: any*/),
                      (v45/*: any*/),
                      (v46/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v29/*: any*/),
                          (v30/*: any*/),
                          (v42/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v30/*: any*/),
                              (v25/*: any*/),
                              (v22/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      (v47/*: any*/),
                      (v50/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v32/*: any*/),
                              (v30/*: any*/),
                              (v22/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      (v52/*: any*/),
                      (v53/*: any*/),
                      (v54/*: any*/),
                      (v55/*: any*/),
                      (v56/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              (v57/*: any*/),
                              (v31/*: any*/),
                              (v30/*: any*/),
                              (v22/*: any*/),
                              (v58/*: any*/)
                            ]
                          },
                          (v30/*: any*/),
                          (v41/*: any*/),
                          (v42/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v59/*: any*/),
                      (v60/*: any*/),
                      (v61/*: any*/),
                      (v63/*: any*/),
                      (v64/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v66 = {
  "kind": "ScalarField",
  "name": "__id"
},
v67 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v68 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v69 = [
  (v67/*: any*/),
  (v20/*: any*/),
  (v68/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "projectKeys",
            "variableName": "projectKeys"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueSearchData"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v19/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "issueId"
                          },
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueTypeHierarchyLevel",
                                    "kind": "LinkedField",
                                    "name": "hierarchy",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "level"
                                      }
                                    ]
                                  },
                                  (v22/*: any*/)
                                ]
                              },
                              (v22/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "isResolved"
                          },
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "ids",
                                "value": [
                                  "status"
                                ]
                              }
                            ],
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v21/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v24/*: any*/),
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                              (v26/*: any*/),
                                              (v22/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusField"
                                      },
                                      (v22/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": [
                                  {
                                    "kind": "Variable",
                                    "name": "filterByProjectKeys",
                                    "variableName": "projectKeys"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "hasChildren"
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v28/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v21/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v24/*: any*/),
                                                          (v29/*: any*/),
                                                          (v30/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v24/*: any*/),
                                                          (v30/*: any*/),
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                              (v21/*: any*/),
                                                              (v30/*: any*/),
                                                              (v31/*: any*/),
                                                              (v22/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v24/*: any*/),
                                                          {
                                                            "concreteType": "JiraIssueType",
                                                            "kind": "LinkedField",
                                                            "name": "issueType",
                                                            "plural": false,
                                                            "selections": [
                                                              (v30/*: any*/),
                                                              (v32/*: any*/),
                                                              (v22/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraIssueTypeField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v24/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v22/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Variable",
                                        "name": "fieldSetIds",
                                        "variableName": "fieldSetIds"
                                      },
                                      (v27/*: any*/)
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsById",
                                    "plural": false,
                                    "selections": (v65/*: any*/)
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "args": (v28/*: any*/),
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsForIssueSearchView",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSetEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldSet",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v33/*: any*/),
                                              (v34/*: any*/),
                                              {
                                                "concreteType": "JiraIssueFieldConnection",
                                                "kind": "LinkedField",
                                                "name": "fields",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v24/*: any*/),
                                                          (v33/*: any*/),
                                                          (v35/*: any*/),
                                                          (v36/*: any*/),
                                                          (v38/*: any*/),
                                                          (v40/*: any*/),
                                                          (v43/*: any*/),
                                                          (v44/*: any*/),
                                                          (v45/*: any*/),
                                                          (v46/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v33/*: any*/),
                                                              (v42/*: any*/)
                                                            ],
                                                            "type": "JiraSingleLineTextField"
                                                          },
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraStatusCategory",
                                                                "kind": "LinkedField",
                                                                "name": "statusCategory",
                                                                "plural": false,
                                                                "selections": (v48/*: any*/)
                                                              }
                                                            ],
                                                            "type": "JiraStatusCategoryField"
                                                          },
                                                          (v47/*: any*/),
                                                          (v50/*: any*/),
                                                          (v52/*: any*/),
                                                          (v53/*: any*/),
                                                          (v54/*: any*/),
                                                          (v55/*: any*/),
                                                          (v56/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v33/*: any*/),
                                                              {
                                                                "kind": "LinkedField",
                                                                "name": "user",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v57/*: any*/),
                                                                  (v58/*: any*/)
                                                                ]
                                                              },
                                                              (v41/*: any*/),
                                                              (v42/*: any*/)
                                                            ],
                                                            "type": "JiraSingleSelectUserPickerField"
                                                          },
                                                          (v59/*: any*/),
                                                          (v60/*: any*/),
                                                          (v61/*: any*/),
                                                          (v63/*: any*/),
                                                          (v64/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isHighlightedIssueRow"
                              },
                              (v66/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v21/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 500
                              }
                            ],
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": (v65/*: any*/),
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "startCursor"
                      },
                      (v62/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "hasPreviousPage"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "firstIssuePosition"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssuePosition"
                      }
                    ]
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "maxCursors",
                        "value": 7
                      }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": (v69/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "last",
                        "plural": false,
                        "selections": (v69/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "first",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v67/*: any*/),
                          (v68/*: any*/)
                        ]
                      }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "issueSearchError",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "messages"
                          }
                        ],
                        "type": "JiraInvalidJqlError"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v37/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "errorType"
                          }
                        ],
                        "type": "JiraInvalidSyntaxError"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isCappingIssueSearchResult"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      (v66/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "args": (v28/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v21/*: any*/),
                                                      (v35/*: any*/),
                                                      (v24/*: any*/),
                                                      (v22/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v19/*: any*/),
                "filters": [
                  "issueSearchInput"
                ],
                "handle": "connection",
                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                "kind": "LinkedHandle",
                "name": "issueSearch"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cd64c0ee5748593ab7907d0da59a621781d4b6fdcc4323e7498f01cff0614ee0",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
    }
  }
};
})();

(node as any).hash = "0330a02a4104792dd53fa511c1535465";

export default node;
