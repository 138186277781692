/**
 * @generated SignedSource<<bfa68958cffd28f839eb1cc1261ee729>>
 * @relayHash a8b99d4e5148c557424db90f1be8215c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 33abce877d8ddf36bd3fdb295b2e91c467ceff0b718872d73a32845f7130d8d5

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type mainUpdateFilterJqlMutation$variables = {
  id: string;
  jql: string;
};
export type mainUpdateFilterJqlMutation$data = {
  readonly jira: {
    readonly jiraFilterMutation: {
      readonly updateJiraCustomFilterJql: {
        readonly errors: ReadonlyArray<{
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly filter: {
          readonly id: string;
          readonly jql: string;
          readonly name: string;
        } | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type mainUpdateFilterJqlMutation = {
  response: mainUpdateFilterJqlMutation$data;
  variables: mainUpdateFilterJqlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jql"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraFilterMutation",
        "kind": "LinkedField",
        "name": "jiraFilterMutation",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                  },
                  {
                    "kind": "Variable",
                    "name": "jql",
                    "variableName": "jql"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "JiraUpdateCustomFilterJqlPayload",
            "kind": "LinkedField",
            "name": "updateJiraCustomFilterJql",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "success"
              },
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ]
              },
              {
                "concreteType": "JiraCustomFilter",
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainUpdateFilterJqlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainUpdateFilterJqlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "33abce877d8ddf36bd3fdb295b2e91c467ceff0b718872d73a32845f7130d8d5",
    "metadata": {},
    "name": "mainUpdateFilterJqlMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6807efed6a00f60f9cf95df455e467fd";

export default node;
