/**
 * @generated SignedSource<<d68dacf35aabf91f75814fafcca1ada8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_spreadsheetView$data = {
  readonly viewSettings: {
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly groupByConfig: {
      readonly groupByField: {
        readonly fieldId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_ListView_spreadsheetView";
};
export type main_issueNavigator_ListView_spreadsheetView$key = {
  readonly " $data"?: main_issueNavigator_ListView_spreadsheetView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_spreadsheetView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_spreadsheetView",
  "selections": [
    {
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canEnableHierarchy"
        },
        {
          "concreteType": "JiraSpreadsheetGroupByConfig",
          "kind": "LinkedField",
          "name": "groupByConfig",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraField",
              "kind": "LinkedField",
              "name": "groupByField",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldId"
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "isHierarchyEnabled"
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
};

(node as any).hash = "35e451e9148679ade2625d5f1a5a39ae";

export default node;
