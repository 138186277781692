import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	allIssues: {
		id: 'issue-navigator-action-advanced-search-button.all-issues',
		defaultMessage: 'Go to all issues',
		description:
			'Text on the link to global advanced issue search - being renamed from "Go to advanced search".',
	},
	allIssuesIssueTermRefresh: {
		id: 'issue-navigator-action-advanced-search-button.all-issues-issue-term-refresh',
		defaultMessage: 'Go to all work items',
		description:
			'Text on the link to global advanced work item search - being renamed from "Go to advanced search".',
	},
});

export default messages;
