import {
	createContainer,
	createStore,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';

type StoreType = {
	isOpen: boolean;
};
type PropType = StoreType;
type ActionType = {
	open: () => Action<StoreType, PropType>;
	close: () => Action<StoreType, PropType>;
};

export const ColumnPickerContainer = createContainer();

const Store = createStore<StoreType, ActionType>({
	initialState: { isOpen: false },
	actions: {
		open:
			() =>
			({ setState }) => {
				setState({ isOpen: true });
			},
		close:
			() =>
			({ setState }) => {
				setState({ isOpen: false });
			},
	},
	name: 'ColumnPickerOpenState',
	containedBy: ColumnPickerContainer,
});

export const useColumnPickerOpenState = createHook(Store);
