import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupByLabel: {
		id: 'issue-table-group-by-dropdown.group-by-label',
		defaultMessage: 'Group',
		description: 'Label for the dropdown allowing users to select what way to group issues by',
	},
	groupByLabelWithSelection: {
		id: 'issue-table-group-by-dropdown.group-by-label-with-selection',
		defaultMessage: 'Group: {groupByField}',
		description:
			'Label for the dropdown allowing users to select what way to group issues by when a user has selected a grouping',
	},
	cantGroupIssues: {
		id: 'issue-table-group-by-dropdown.cant-group-issues',
		defaultMessage:
			'We can’t group issues when more than one project is filtered. Update your JQL filter and try again.',
		description: 'Label for the dropdown option indicating that issues cannot be grouped',
	},
	entrypointErrorContent: {
		id: 'issue-table-group-by-dropdown.entrypoint-error-content',
		defaultMessage: 'Something went wrong. Try loading the content again.',
		description: 'Content displayed when the group by dropdown fails to load',
	},
});
