/**
 * @generated SignedSource<<6ab7c2a8b5f9d7e53e2eda0626fb3cbe>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topBar_issueNavigator_filter$data = {
  readonly filterId: string;
  readonly isFavourite: boolean | null | undefined;
  readonly jql: string;
  readonly name: string;
  readonly " $fragmentType": "topBar_issueNavigator_filter";
};
export type topBar_issueNavigator_filter$key = {
  readonly " $data"?: topBar_issueNavigator_filter$data;
  readonly " $fragmentSpreads": FragmentRefs<"topBar_issueNavigator_filter">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "topBar_issueNavigator_filter",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    }
  ],
  "type": "JiraFilter",
  "abstractKey": "__isJiraFilter"
};

(node as any).hash = "d1fd34a229f9cb2668ab6793af284885";

export default node;
