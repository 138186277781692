/**
 * @generated SignedSource<<f5558bea4509b675d4b93f377171ac71>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly __id: string;
    readonly node: {
      readonly key: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentType": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults";
};
export type issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults$key = {
  readonly " $data"?: issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        },
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "cursor"
            },
            {
              "kind": "ScalarField",
              "name": "isCurrent"
            },
            {
              "kind": "ScalarField",
              "name": "pageNumber"
            }
          ]
        }
      ],
      "storageKey": "pageCursors(maxCursors:7)"
    },
    (v0/*: any*/)
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "be5101b94f9ee67c52f693de30818ccb";

export default node;
