import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	saveFilter: {
		id: 'issue-navigator-custom-filters.save-filter-dropdown.save-filter',
		defaultMessage: 'Save filter',
		description: 'Text of button that opens the save filter dropdown',
	},
	saveChanges: {
		id: 'issue-navigator-custom-filters.save-filter-dropdown.save-changes',
		defaultMessage: 'Save changes',
		description: 'Text of "Save changes" option inside save filter dropdown',
	},
	saveAsACopy: {
		id: 'issue-navigator-custom-filters.save-filter-dropdown.save-as-a-copy',
		defaultMessage: 'Save as a copy',
		description: 'Text of "Save as a copy" option inside save filter dropdown',
	},
	copyFilterDialogTitle: {
		id: 'issue-navigator-custom-filters.save-filter-dropdown.copy-filter-dialog-title',
		defaultMessage: 'Copy filter',
		description: 'Text for title of copy filter modal.',
	},
});
