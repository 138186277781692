/**
 * @generated SignedSource<<904e0d39da2e4e2100f94fcd59ddca44>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Header_issueResults$data = {
  readonly issueNavigatorPageInfo: {
    readonly firstIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly last: {
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_Header_issueResults";
};
export type main_issueNavigator_Header_issueResults$key = {
  readonly " $data"?: main_issueNavigator_Header_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Header_issueResults">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_Header_issueResults",
  "selections": [
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "last",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ],
      "storageKey": "pageCursors(maxCursors:7)"
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    }
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "a6845643ea9e8dc218ebb8df7988c3f7";

export default node;
