import React, { type ComponentType } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SpotlightTourCardAsync = lazyForPaint<ComponentType<Props>>(
	() =>
		import(
			/* webpackChunkName: "async-issue-navigator-changeboarding-spotlight-tour-card" */ './main'
		),
	{
		ssr: false,
	},
);

const SpotlightTourCard: ComponentType<Props> = (props: Props) => (
	<JSErrorBoundary
		packageName="jiraIssueNavigatorChangeboarding"
		id="async-issue-navigator-changeboarding-spotlight-tour-card"
		sendToPrivacyUnsafeSplunk
		fallback="unmount"
	>
		<Placeholder name="async-issue-navigator-changeboarding-spotlight-tour-card">
			<SpotlightTourCardAsync {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default SpotlightTourCard;
