import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { IssueNavigatorJQLBuilderSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/jql-builder.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { JQLBuilderWithAIProps } from './common/types.tsx';

const JQLBuilderWithAIAsync = lazyForPaint<ComponentType<JQLBuilderWithAIProps>>(
	() => import(/* webpackChunkName: "async-jql-builder-with-ai" */ './index'),
);

const JQLBuilderWithAI: ComponentType<JQLBuilderWithAIProps> = (props: JQLBuilderWithAIProps) => (
	<Placeholder name="async-jql-builder-with-ai" fallback={<IssueNavigatorJQLBuilderSkeleton />}>
		<JQLBuilderWithAIAsync {...props} />
	</Placeholder>
);

export default JQLBuilderWithAI;
