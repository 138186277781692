import React, { type PropsWithChildren, useMemo } from 'react';
import uuid from 'uuid';
import {
	ContextualAnalyticsData,
	SCREEN,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	type Action,
} from '@atlassian/react-sweet-state';

type State = {
	/**
	 * UUID generated when a user initiates a new search journey in NIN. This allows us to measure time taken between
	 * starting a search journey and completing a defined task.
	 */
	issueNavigatorSearchSessionId: string | undefined;
};

const Container = createContainer();

type Actions = {
	/**
	 * Event emitted when the user initiates a search journey in the JQL builder. The specific user interactions we are
	 * targeting are defined by each respective search mode, i.e. basic, advanced and AI.
	 *
	 * @returns {Attributes} Analytics attributes to associate with the interaction that triggered the search journey.
	 */
	onJourneyStart: () => Action<State, void, Attributes>;
};

const Store = createStore<State, Actions>({
	containedBy: Container,
	initialState: {
		issueNavigatorSearchSessionId: undefined,
	},
	actions: {
		onJourneyStart:
			() =>
			({ setState }) => {
				const issueNavigatorSearchSessionId = uuid();
				setState({ issueNavigatorSearchSessionId });
				return { issueNavigatorSearchSessionId };
			},
	},
});

// Exported for testing
export const useSearchSessionId = createHook<State, Actions, string | undefined>(Store, {
	selector: (state) => state.issueNavigatorSearchSessionId,
});
export const useAppContextualAnalyticsActions = createActionsHook<State, Actions>(Store);

const AppContextualAnalyticsData = ({ children }: PropsWithChildren<{}>) => {
	const [issueNavigatorSearchSessionId] = useSearchSessionId();
	const attributes = useMemo(
		() => ({ issueNavigatorSearchSessionId }),
		[issueNavigatorSearchSessionId],
	);
	return (
		<ContextualAnalyticsData
			sourceName="issueNavigator"
			sourceType={SCREEN}
			attributes={attributes}
		>
			{children}
		</ContextualAnalyticsData>
	);
};

export const AppContextualAnalytics = ({ children }: PropsWithChildren<{}>) => (
	<Container>
		<AppContextualAnalyticsData>{children}</AppContextualAnalyticsData>
	</Container>
);
