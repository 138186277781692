import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	JiraIssueNavigatorSearchLayout,
	selectedView_updateUserViewModeMutation as updateUserViewModeMutation,
} from '@atlassian/jira-relay/src/__generated__/selectedView_updateUserViewModeMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';

export const viewModeToSearchLayout = (
	view: IssueNavigatorViewId,
): JiraIssueNavigatorSearchLayout => (view.startsWith('list_') ? 'LIST' : 'DETAIL');
/**
 * Maps the provided issue navigator view mode into the searchLayout required by the JiraUserPreferencesMutation.setIssueNavigatorSearchLayout GraphQL node.
 */
export const useSelectedViewMutation = () => {
	const userCloudId = useCloudId();
	const [commitMutation] = useMutation<updateUserViewModeMutation>(graphql`
		mutation selectedView_updateUserViewModeMutation(
			$cloudId: ID!
			$searchLayout: JiraIssueNavigatorSearchLayout
		) {
			jira {
				userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
					setIssueNavigatorSearchLayout(searchLayout: $searchLayout) {
						success
						errors {
							message
						}
						issueNavigatorSearchLayout
					}
				}
			}
		}
	`);

	return useCallback(
		(view: IssueNavigatorViewId) => {
			commitMutation({
				variables: {
					cloudId: userCloudId,
					searchLayout: viewModeToSearchLayout(view),
				},
				onError: (error) => {
					fireErrorAnalytics({
						sendToPrivacyUnsafeSplunk: true,
						error,
						meta: {
							id: 'mainSelectedViewMutation',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
					});
				},
				optimisticResponse: {
					jira: {
						userPreferences: {
							setIssueNavigatorSearchLayout: {
								success: true,
								errors: [],
								issueNavigatorSearchLayout: viewModeToSearchLayout(view),
							},
						},
					},
				},
			});
		},
		[commitMutation, userCloudId],
	);
};
