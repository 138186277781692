/**
 * @generated SignedSource<<a59decce3d17ede33536b0b36f9eb2f1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Header_filter$data = {
  readonly filterId: string;
  readonly isFavourite: boolean | null | undefined;
  readonly jql: string;
  readonly name: string;
  readonly " $fragmentType": "main_issueNavigator_Header_filter";
};
export type main_issueNavigator_Header_filter$key = {
  readonly " $data"?: main_issueNavigator_Header_filter$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Header_filter">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_Header_filter",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    }
  ],
  "type": "JiraFilter",
  "abstractKey": "__isJiraFilter"
};

(node as any).hash = "49ae72938f954e62114776575c662bd8";

export default node;
