import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pickerTabTextDefault: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-default',
		defaultMessage: 'My defaults',
		description: 'Label for "My defaults" tab inside of column configuration',
	},
	pickerTabTextFilter: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-filter',
		defaultMessage: 'Filter',
		description: 'Label for "Filter" tab inside of column configuration',
	},
	pickerTabTextSystem: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.column-picker-tabs.picker-tab-text-system',
		defaultMessage: 'System',
		description: 'Label for "System" tab inside of column configuration',
	},
});
