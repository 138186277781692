import { useCallback } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useResolvedJql } from '../../services/active-jql/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIssueSearchActions } from '../../services/issue-search/selectors.tsx';

export const useJqlSearch = (changeJql?: (jql: string, clearFilter?: boolean) => void) => {
	const query = useResolvedJql();

	let onRefetch;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onRefetch } = useIssueSearchActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onIssueSearch: onRefetch } = useIssueSearchQuery());
	}

	const onSearch = useCallback(
		(jql: string) => {
			if (jql === query) {
				// This will execute a new search against the network with current JQL input arguments
				onRefetch();
			} else {
				changeJql && changeJql(jql);
			}
		},
		[query, onRefetch, changeJql],
	);

	return { onSearch, query };
};
