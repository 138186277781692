/**
 * @generated SignedSource<<6388756019f802ff4f0f50388aad4587>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_project">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_project";
};
export type main_issueNavigator_IssueNavigatorUI_project$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "81c34d1aab5f8b19d5c03d75b9b44193";

export default node;
