/**
 * @generated SignedSource<<1e0bf02376aa3d2c09b47fc757e49dd2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_viewResult$data = {
  readonly __typename: string;
  readonly extensions?: ReadonlyArray<{
    readonly statusCode: number | null | undefined;
  }> | null | undefined;
  readonly message?: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_FullPageIssueApp_view" | "main_issueNavigator_Header_viewResult" | "main_issueNavigator_IssueResults_viewResult" | "topBar_issueNavigator_viewResult">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_viewResult";
};
export type main_issueNavigator_IssueNavigatorUI_viewResult$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_viewResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_viewResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_viewResult",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_viewResult"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_FullPageIssueApp_view"
        },
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_Header_viewResult"
        },
        {
          "kind": "FragmentSpread",
          "name": "topBar_issueNavigator_viewResult"
        }
      ],
      "type": "JiraIssueSearchView"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "message"
        },
        {
          "kind": "LinkedField",
          "name": "extensions",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "statusCode"
            }
          ]
        }
      ],
      "type": "QueryError"
    }
  ],
  "type": "JiraIssueSearchViewResult",
  "abstractKey": "__isJiraIssueSearchViewResult"
};

(node as any).hash = "734c65301c902c4873569a6d77b57db1";

export default node;
