import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createStore, createContainer } from '@atlassian/react-sweet-state';
import { views } from '../../common/constants.tsx';
import actions, { privateActions } from './actions.tsx';
import type { State, Actions, Props } from './types.tsx';

export const SelectedIssueContainer = createContainer<Props>();

const initialState: State = {
	_rememoize: Date.now(),
	selectedIssueKey: toIssueKey(''),
	isFullPageIssueAppMode: false,
	issueKeys: [],
};

export const Store = createStore<State, Actions, Props>({
	containedBy: SelectedIssueContainer,
	initialState,
	actions,
	handlers: {
		onInit:
			() =>
			({ setState, dispatch }, { selectedIssueKey, view, issueKeys }) => {
				dispatch(privateActions.onContainerUpdate());
				const isFullPageIssueAppMode = Boolean(selectedIssueKey) && view === views.list;
				const initialIssueKey = selectedIssueKey || issueKeys[0] || toIssueKey('');
				setState({
					_rememoize: Date.now(),
					selectedIssueKey: initialIssueKey,
					isFullPageIssueAppMode,
				});
			},
		onContainerUpdate:
			({ searchKey, selectedIssueKey }, prevProps) =>
			({ dispatch }) => {
				dispatch(privateActions.onContainerUpdate());
				if (searchKey !== prevProps.searchKey) {
					dispatch(privateActions.resetSelectedIssue());
				} else if (selectedIssueKey && selectedIssueKey !== prevProps.selectedIssueKey) {
					dispatch(
						Store.actions.setSelectedIssueByKey(selectedIssueKey, {
							shouldDebounce: false,
							shouldNotifyOnChange: false,
							isSelectedByUserInteraction: false,
						}),
					);

					// If an issue key has been specified while in list view then enter full page issue app mode.
					if (dispatch(privateActions.isListViewWithoutIssueApp())) {
						dispatch(Store.actions.enterFullPageIssueAppMode());
					}

					// TODO JSC-185 Fetch next/previous page when last/first issue is selected
				}
				// If nextIssueKey is empty and we're in full page mode with a selected issue then we should exit. This
				// handles the scenario where issue app is opened and the user presses the browser back button while still
				// allowing us to cycle through deleted issues in full page mode.
				else if (!selectedIssueKey && dispatch(privateActions.isListViewWithIssueAppAndKey())) {
					dispatch(Store.actions.exitFullPageIssueAppMode());
				}
			},
	},
});
