import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	maxSelectedColumns: {
		id: 'issue-navigator.issue-results.list-view.column-picker-max-selected-columns.max-selected-columns',
		defaultMessage:
			'The column limit is {maxColumns, number}. Remove and add the most important ones to your view.',
		description:
			'Message shown when the user tries to select more than 500 columns in the column picker.',
	},
});
