import { fg } from '@atlassian/jira-feature-gating';
import {
	type useAnalyticsEvents,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';

import {
	type Action,
	createStore,
	createHook,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { FormView } from '../../common/types.tsx';
import { extraFormDataService } from '../../services/extra-form-data/index.tsx';
import {
	type BulkOperationsDismiss,
	BulkOperationsTypes,
	BulkTransitionsStage,
} from './constants.tsx';

type BulkOperationsState =
	| {
			isSidebarOpen: true;
			operationType: {
				type: 'BULK_EDIT';
			};
	  }
	| {
			isSidebarOpen: false;
			operationType: {
				type: 'BULK_TRANSITION';
				stage: 'LOAD_BULK_TRANSITIONS' | 'START_QUICK_TRANSITIONS';
			};
	  }
	| {
			isSidebarOpen: true;
			operationType: {
				type: 'BULK_TRANSITION';
				stage: 'START_SIDEBAR_TRANSITION' | 'START_TRANSITION_SCREEN';
			};
	  }
	| {
			isSidebarOpen: false;
			operationType: null;
	  };

type CancelDialogTrigger = 'user-triggered' | 'route-change' | undefined;

type State = {
	shouldRenderSpotlight?: boolean;
	cancelDialogTrigger: CancelDialogTrigger | undefined;
	currentView: FormView;
	firstFieldId: string | undefined;
} & BulkOperationsState;

export const initialState: State = {
	operationType: null,
	isSidebarOpen: false,
	cancelDialogTrigger: undefined,
	currentView: 'edit',
	firstFieldId: undefined,
};

const actions = {
	setSummaryView:
		(): Action<State> =>
		({ setState }) => {
			setState({
				currentView: 'summary',
			});
		},
	setFirstFieldId:
		(firstFieldId: string): Action<State> =>
		({ setState }) => {
			setState({
				firstFieldId,
			});
		},
	setEditView:
		(): Action<State> =>
		({ setState }) => {
			setState({
				currentView: 'edit',
			});
		},
	setCancelDialogTrigger:
		(newTrigger: CancelDialogTrigger = 'user-triggered'): Action<State> =>
		({ setState, getState }) => {
			const { cancelDialogTrigger: currentTrigger } = getState();
			/* Sometimes both events can be fired (route change & onclick) in one loop
            We need to prioritise route-change event  */
			if (currentTrigger === 'route-change' && newTrigger === 'user-triggered') {
				return;
			}
			setState({ cancelDialogTrigger: newTrigger });
		},
	clearDialogTrigger:
		(): Action<State> =>
		({ setState }) => {
			setState({ cancelDialogTrigger: undefined });
		},
	startEdit:
		(args?: { shouldRenderSpotlight?: boolean }): Action<State> =>
		({ setState }) => {
			const { shouldRenderSpotlight } = args || { shouldRenderSpotlight: false };
			fg('bulk_edit_discoverability')
				? setState({
						operationType: { type: BulkOperationsTypes.BULK_EDIT },
						shouldRenderSpotlight,
						isSidebarOpen: true,
					})
				: setState({
						operationType: { type: BulkOperationsTypes.BULK_EDIT },
						isSidebarOpen: true,
					});
		},
	loadTransitions:
		(): Action<State> =>
		({ setState }) => {
			setState({
				operationType: {
					type: BulkOperationsTypes.BULK_TRANSITION,
					stage: BulkTransitionsStage.LOAD_BULK_TRANSITIONS,
				},
				isSidebarOpen: false,
			});
		},
	startQuickTransitions:
		(): Action<State> =>
		({ setState }) => {
			setState({
				operationType: {
					type: BulkOperationsTypes.BULK_TRANSITION,
					stage: BulkTransitionsStage.START_QUICK_TRANSITIONS,
				},
				isSidebarOpen: false,
			});
		},
	startSidebarTransition:
		(): Action<State> =>
		({ setState }) => {
			setState({
				operationType: {
					type: BulkOperationsTypes.BULK_TRANSITION,
					stage: BulkTransitionsStage.START_SIDEBAR_TRANSITION,
				},
				isSidebarOpen: true,
			});
		},
	startTransitionScreen:
		(): Action<State> =>
		({ setState }) => {
			setState({
				operationType: {
					type: BulkOperationsTypes.BULK_TRANSITION,
					stage: BulkTransitionsStage.START_TRANSITION_SCREEN,
				},
				isSidebarOpen: true,
			});
		},
	/* @deprecated use dismissBulkOperation instead */
	closeSidebar:
		(): Action<State> =>
		({ setState }) => {
			setState(initialState);
		},
	dismissBulkOperation:
		({
			createAnalyticsEvent,
			dismissType,
		}: {
			createAnalyticsEvent: ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];
			dismissType: BulkOperationsDismiss;
		}): Action<State> =>
		({ setState }) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireTrackAnalytics(analyticsEvent, 'dismissed bulkOperations', { dismissType });
			extraFormDataService.clear();
			setState(initialState);
		},
	dismissBulkOperationOld:
		(): Action<State> =>
		({ setState }) => {
			extraFormDataService.clear();
			setState(initialState);
		},
} as const;

type Actions = typeof actions;

export const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'bulk-operations-store',
});

export const useBulkOperations = createHook(store);

export const useBulkOperationsActions = createActionsHook<State, typeof actions>(store);

export const useIsBulkOperationsInProgress = createStateHook(store, {
	selector: (state: State): boolean => state.operationType !== null,
});

export const useShouldRenderSpotlight = createStateHook(store, {
	selector: (state: State): boolean => !!state.shouldRenderSpotlight,
});

export const useBulkTransitionsStage = createStateHook(store, {
	selector: (state: State): BulkTransitionsStage | null => {
		const { operationType } = state;
		if (operationType?.type === BulkOperationsTypes.BULK_TRANSITION) {
			return operationType.stage;
		}
		return null;
	},
});
export const useBulkOperationInForm = createStateHook(store, {
	selector: (state: State): BulkOperationsTypes | null => {
		if (state.isSidebarOpen && state.operationType) {
			return state.operationType.type;
		}
		return null;
	},
});

export const useBulkOperationType = createStateHook(store, {
	selector: (state: State) => state.operationType,
});

export const useCancelDialogTrigger = createStateHook(store, {
	selector: (state: State) => state.cancelDialogTrigger,
});

export const useCurrentView = createStateHook(store, {
	selector: (state: State) => state.currentView,
});
