import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate, ConnectionInterface, type RecordProxy } from 'relay-runtime';

const getKeyForEdge = (edge: RecordProxy | undefined) => {
	const { NODE } = ConnectionInterface.get();
	return edge?.getLinkedRecord(NODE)?.getValue('key');
};

const getFirstEdgeCursor = (connectionRecord: RecordProxy) => {
	const { CURSOR, EDGES } = ConnectionInterface.get();
	const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
	const cursor = edges[0]?.getValue(CURSOR);
	return cursor ? String(cursor) : null;
};

const getLastEdgeCursor = (connectionRecord: RecordProxy) => {
	const { CURSOR, EDGES } = ConnectionInterface.get();
	const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
	const cursor = edges[edges.length - 1]?.getValue(CURSOR);
	return cursor ? String(cursor) : null;
};

const updatePageInfoCursor = (
	pageInfo: RecordProxy | null,
	pageInfoField: string,
	replacementCursor: string | null,
	edgeToDelete: RecordProxy,
) => {
	if (!pageInfo || !replacementCursor) {
		return;
	}

	const { CURSOR } = ConnectionInterface.get();
	const pageInfoCursor = pageInfo.getValue(pageInfoField);
	if (pageInfoCursor && pageInfoCursor === edgeToDelete.getValue(CURSOR)) {
		pageInfo.setValue(replacementCursor, pageInfoField);
	}
};

const updatePageInfo = (connectionRecord: RecordProxy, edgeToDelete: RecordProxy) => {
	const { START_CURSOR, END_CURSOR, PAGE_INFO } = ConnectionInterface.get();
	const pageInfo = connectionRecord.getLinkedRecord(PAGE_INFO);
	updatePageInfoCursor(pageInfo, START_CURSOR, getFirstEdgeCursor(connectionRecord), edgeToDelete);
	updatePageInfoCursor(pageInfo, END_CURSOR, getLastEdgeCursor(connectionRecord), edgeToDelete);
};

export const useOnDeleteIssue = (connectionId: string | undefined) => {
	const environment = useRelayEnvironment();

	return useCallback(
		(issueKeyToDelete: string) => {
			let previousIssueKey;
			let nextIssueKey;
			commitLocalUpdate(environment, (store) => {
				const connectionRecord = connectionId ? store.get(connectionId) : undefined;
				if (!connectionRecord) {
					return;
				}

				const { EDGES } = ConnectionInterface.get();
				const edges = connectionRecord.getLinkedRecords(EDGES) ?? [];
				const index = edges.findIndex((edge) => getKeyForEdge(edge) === issueKeyToDelete);

				if (index > -1) {
					nextIssueKey = getKeyForEdge(edges[index + 1]);
					previousIssueKey = getKeyForEdge(edges[index - 1]);
					// Filter out deleted record from our connection
					connectionRecord.setLinkedRecords(
						edges.slice(0, index).concat(edges.slice(index + 1)),
						EDGES,
					);

					// Update page start/end cursors if necessary following record delete
					updatePageInfo(connectionRecord, edges[index]);

					// Decrement the total count
					const totalCount = connectionRecord.getValue('totalIssueSearchResultCount');
					if (typeof totalCount === 'number') {
						connectionRecord.setValue(totalCount - 1, 'totalIssueSearchResultCount');
					}
				}
			});

			// Return the next issue key to select. If there is no next issue key then return the previous key.
			return nextIssueKey ?? previousIssueKey ?? '';
		},
		[environment, connectionId],
	);
};
