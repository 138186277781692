/**
 * @generated SignedSource<<a4404a464a5c9c95e0bf1d382233789e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedView_issueNavigator_SelectedViewContainer_issueSearchView$data = {
  readonly viewId: string | null | undefined;
  readonly " $fragmentType": "selectedView_issueNavigator_SelectedViewContainer_issueSearchView";
};
export type selectedView_issueNavigator_SelectedViewContainer_issueSearchView$key = {
  readonly " $data"?: selectedView_issueNavigator_SelectedViewContainer_issueSearchView$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedView_issueNavigator_SelectedViewContainer_issueSearchView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "selectedView_issueNavigator_SelectedViewContainer_issueSearchView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "viewId"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "6373906f98b0da7dd8bd2aaf1f2c68ca";

export default node;
