import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	done: {
		id: 'issue-navigator.view-switcher.done',
		defaultMessage: 'Done',
		description: 'Done button in the spotlight tour card targeting the view switcher component',
	},
	next: {
		id: 'issue-navigator.view-switcher.next',
		defaultMessage: 'Next',
		description: 'Next button in the spotlight tour card targeting the view switcher component',
	},
	skip: {
		id: 'issue-navigator.view-switcher.skip',
		defaultMessage: 'Skip',
		description: 'Skip button in the spotlight tour card targeting the view switcher component',
	},
	tourHeading: {
		id: 'issue-navigator.view-switcher.tour-heading',
		defaultMessage: 'View switcher',
		description: 'Heading of the spotlight tour card targeting the view switcher component',
	},
	tourBody: {
		id: 'issue-navigator.view-switcher.tour-body',
		defaultMessage:
			'Switch between these two views to easily scan through a list or view the details of the issues.',
		description: 'Body of the spotlight tour card targeting the view switcher component',
	},
	detailText: {
		id: 'issue-navigator.refinement-bar.view-switcher.detail-text',
		defaultMessage: 'Switch to detail view',
		description: '',
	},
	listText: {
		id: 'issue-navigator.refinement-bar.view-switcher.list-text',
		defaultMessage: 'Switch to list view',
		description: '',
	},
	toggleButtonsLabel: {
		id: 'issue-navigator.refinement-bar.view-switcher.toggle-buttons-label',
		defaultMessage: 'Select view',
		description:
			'The label which is attached to the view switcher for selecting between list and detail view. This is not visible in the UI, but is read aloud by assistive technology like screen-readers.',
	},
	detailOptionLabel: {
		id: 'issue-navigator.refinement-bar.view-switcher.detail-option-label',
		defaultMessage: 'Detail view',
		description: 'The label which is displayed on the view switcher button for the detail view',
	},
	listOptionLabel: {
		id: 'issue-navigator.refinement-bar.view-switcher.list-option-label',
		defaultMessage: 'List view',
		description: 'The label which is displayed on the view switcher button for the list view',
	},
	tourBodyIssueTermRefresh: {
		id: 'issue-navigator.view-switcher.tour-body-issue-term-refresh',
		defaultMessage:
			'Switch between these two views to easily scan through a list or view the details of the work item.',
		description: 'Body of the spotlight tour card targeting the view switcher component',
	},
});
export default messages;
