class ExtraFormData {
	private data: Record<string, unknown> = {};

	clear() {
		this.data = {};
	}

	addByKey(key: string, value: unknown) {
		this.data[key] = value;
	}

	getByKey(key: string) {
		return this.data[key];
	}

	getAll() {
		return this.data;
	}
}

const extraFormData = new ExtraFormData();

const addDataByKey = (key: string, value: unknown) => {
	extraFormData.addByKey(key, value);
};

const clear = () => {
	extraFormData.clear();
};

const getDataByKey = (key: string) => extraFormData.getByKey(key);

const getData = () => extraFormData.getAll();

export const extraFormDataService = {
	addDataByKey,
	getDataByKey,
	clear,
	getData,
};
