/**
 * @generated SignedSource<<54d5e2050f16aa77b0b95c4ea04b0425>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type footer_issueNavigator_FooterInternal$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly fields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly fieldId?: string;
            readonly issueType?: {
              readonly avatar: {
                readonly small: string | null | undefined;
              } | null | undefined;
              readonly name: string;
            } | null | undefined;
            readonly name?: string;
            readonly statusCategory?: {
              readonly statusCategoryId: string;
            };
            readonly text?: string | null | undefined;
            readonly user?: {
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "footer_issueNavigator_FooterInternal";
};
export type footer_issueNavigator_FooterInternal$key = {
  readonly " $data"?: footer_issueNavigator_FooterInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"footer_issueNavigator_FooterInternal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "footer_issueNavigator_FooterInternal",
  "selections": [
    {
      "concreteType": "JiraIssueFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fields",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "name": "text"
                            }
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "name": "picture"
                                }
                              ]
                            }
                          ],
                          "type": "JiraSingleSelectUserPickerField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "concreteType": "JiraAvatar",
                                  "kind": "LinkedField",
                                  "name": "avatar",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "name": "small"
                                    }
                                  ]
                                }
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            {
                              "concreteType": "JiraStatusCategory",
                              "kind": "LinkedField",
                              "name": "statusCategory",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "statusCategoryId"
                                }
                              ]
                            }
                          ],
                          "type": "JiraStatusCategoryField"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};
})();

(node as any).hash = "80bd71836423cbf18b560dfc0418dc69";

export default node;
