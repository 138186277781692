/**
 * @generated SignedSource<<b028ab22d769af59e1b92eab949e2221>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey";
};
export type main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e9088c921273ca13e8f1d0f476e2f2fb";

export default node;
