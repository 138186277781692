import type { Action } from '@atlassian/react-sweet-state';
import type { SelectedIssuesState, ContainerProps } from './types.tsx';

export const initialState: SelectedIssuesState = {
	selectedIssueIds: new Set([]),
};

export const actions = {
	insertSelectedIssueIds:
		(selectedIssueIds: string[]): Action<SelectedIssuesState, ContainerProps> =>
		({ setState, getState }, { onIssueSelected }) => {
			const currentState = getState();
			onIssueSelected?.(selectedIssueIds);

			setState({
				selectedIssueIds: new Set([...currentState.selectedIssueIds, ...selectedIssueIds]),
			});
		},
	removeSelectedIssueIds:
		(selectedIssueIds: string[]): Action<SelectedIssuesState, ContainerProps> =>
		({ setState, getState }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			selectedIssueIds.forEach((selectedIssueId) => {
				updatedSelectedIssueIds.delete(selectedIssueId);
			});

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	insertSelectedIssueId:
		(selectedIssueId: string): Action<SelectedIssuesState, ContainerProps> =>
		({ setState, getState }, { onIssueSelected }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			updatedSelectedIssueIds.add(selectedIssueId);

			onIssueSelected?.([selectedIssueId]);

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	removeSelectedIssueId:
		(selectedIssueId: string): Action<SelectedIssuesState> =>
		({ setState, getState }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			updatedSelectedIssueIds.delete(selectedIssueId);

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	selectedOnlyIssueId:
		(selectedIssueId: string): Action<SelectedIssuesState, ContainerProps> =>
		({ setState }, { onIssueSelected }) => {
			onIssueSelected?.([selectedIssueId]);
			setState({ selectedIssueIds: new Set([selectedIssueId]) });
		},
	reset:
		(): Action<SelectedIssuesState> =>
		({ setState }) => {
			setState(initialState);
		},
} as const;
export type Actions = typeof actions;
