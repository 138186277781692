import { type UIEventHandler, useCallback, useRef } from 'react';
import { ROW_HEIGHT } from '../../common/constants.tsx';
import { useThrottle } from './utils.tsx';

const DEFAULT_ACTIVATION_THRESHOLD = 10 * ROW_HEIGHT;

type DataProps = {
	isLoadingNext?: boolean;
	isLoadingPrevious?: boolean;
	hasNext?: boolean;
	hasPrevious?: boolean;
	activationThreshold?: number;
};

type CallbackProps = {
	onLoadNext?: () => void;
	onLoadPrevious?: () => void;
};

export type Props = DataProps & CallbackProps;

export const useInfiniteScrollHandler = ({
	onLoadNext: onLoadNextProp,
	onLoadPrevious: onLoadPreviousProp,
	...dataProps
}: Props) => {
	// Keep props as ref so the callback is stable across rerenders.
	const dataPropsRef = useRef<DataProps>(dataProps);
	dataPropsRef.current = dataProps;

	const onLoadNext = useThrottle(onLoadNextProp);
	const onLoadPrevious = useThrottle(onLoadPreviousProp);

	const onLoadingThresholdCheck = useCallback(
		({
			clientHeight,
			scrollHeight,
			scrollTop,
		}: {
			clientHeight: number;
			scrollHeight: number;
			scrollTop: number;
		}) => {
			const {
				isLoadingNext,
				isLoadingPrevious,
				hasNext,
				hasPrevious,
				activationThreshold = DEFAULT_ACTIVATION_THRESHOLD,
			} = dataPropsRef.current;
			const scrollBottom = scrollHeight - clientHeight - scrollTop;
			const isLoading = isLoadingNext || isLoadingPrevious;

			// Prioritising loading next over loading previous to fill results downwards first,
			// to hopefully feel more natural.
			if (hasNext && !isLoading && scrollBottom <= activationThreshold) {
				onLoadNext?.();
			} else if (hasPrevious && !isLoading && scrollTop <= activationThreshold) {
				onLoadPrevious?.();
			}
		},
		// Ensure that the callback is not recreated when props change.
		// onLoadNext & onLoadPrevious should be throttled & stable.
		[onLoadNext, onLoadPrevious],
	);

	const onScroll = useCallback<UIEventHandler>(
		(event) => {
			onLoadingThresholdCheck(event.currentTarget);
		},
		// Ensure that the callback is not recreated when props change.
		// onLoadingThresholdCheck should be stable.
		[onLoadingThresholdCheck],
	);

	return { onLoadingThresholdCheck, onScroll };
};
