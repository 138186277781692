/**
 * @generated SignedSource<<a0c0e4404c60913c98474d024556eeff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueTableGroupByDropdown_GroupByDropdown$data = {
  readonly id: string;
  readonly viewSettings: {
    readonly canEnableGrouping: boolean | null | undefined;
    readonly groupByConfig: {
      readonly groupByField: {
        readonly name: string | null | undefined;
      } | null | undefined;
    };
  };
  readonly " $fragmentType": "ui_issueTableGroupByDropdown_GroupByDropdown";
};
export type ui_issueTableGroupByDropdown_GroupByDropdown$key = {
  readonly " $data"?: ui_issueTableGroupByDropdown_GroupByDropdown$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableGroupByDropdown_GroupByDropdown">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueTableGroupByDropdown_GroupByDropdown",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssueSearchViewConfigSettings",
        "kind": "LinkedField",
        "name": "viewSettings",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "canEnableGrouping"
          },
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraSpreadsheetGroupByConfig",
              "kind": "LinkedField",
              "name": "groupByConfig",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraField",
                  "kind": "LinkedField",
                  "name": "groupByField",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "name"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "viewSettings.groupByConfig"
          }
        ]
      },
      "action": "THROW",
      "path": "viewSettings"
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
};

(node as any).hash = "e23925b3c81f07b81cacd1582e723309";

export default node;
