import React, { type ComponentType, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { FooterProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { components } from '@atlassian/jira-native-issue-table/src/common/ui/components/index.tsx';
import Feedback from '../../../../common/ui/feedback/index.tsx';
import { useIssueSearchQuery } from '../../../../services/issue-search-query/index.tsx';

/**
 * Override default issue table footer to conditionally hide pagination controls when issue data is stale.
 */
// TODO Remove when cleaning up jira_spreadsheet_component_m1_api_updates
export const useFooterOverride = (
	isFeedbackButtonDisabled?: boolean,
): ComponentType<FooterProps> => {
	const { isStalePage } = useIssueSearchQuery();

	return useMemo(
		() =>
			({ end, center, ...props }: FooterProps) => {
				let start;
				if (!isFeedbackButtonDisabled) {
					start = (
						<FeedbackContainer data-testid="issue-navigator.ui.issue-results.list-view.footer.feedback-wrapper">
							<Feedback />
						</FeedbackContainer>
					);
				}
				return (
					<components.Footer
						{...props}
						start={start}
						// Do not show pagination controls while issue data is stale as page cursor information will be inaccurate
						center={isStalePage && props.isNewFooterOrderEnabled ? undefined : center}
						end={isStalePage && !props.isNewFooterOrderEnabled ? undefined : end}
					/>
				);
			},
		[isFeedbackButtonDisabled, isStalePage],
	);
};

/**
 * Override default issue table footer to conditionally hide pagination controls when issue data is stale.
 */
// TODO Remove when cleaning up jira_spreadsheet_component_m1
export const Footer = ({ end, center, ...props }: FooterProps) => {
	const { isStalePage } = useIssueSearchQuery();
	return (
		<components.Footer
			{...props}
			// Do not show pagination controls while issue data is stale as page cursor information will be inaccurate
			center={isStalePage && props.isNewFooterOrderEnabled ? undefined : center}
			end={isStalePage && !props.isNewFooterOrderEnabled ? undefined : end}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackContainer = styled.div({
	display: 'flex',
	flex: 1,
});
