import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noColumnsHeaderText: {
		id: 'native-issue-table.issue-table.no-columns.no-columns-header-text',
		defaultMessage: 'Configure your columns',
		description: 'Message shown when no columns have been selected.',
	},
	noColumnsDescriptionText: {
		id: 'native-issue-table.issue-table.no-columns.no-columns-description-text',
		defaultMessage:
			"You haven't configured any columns yet. Add fields as columns to see search results and their information.",
		description: 'Supplementary message shown when no columns have been selected.',
	},
	configureColumns: {
		id: 'native-issue-table.issue-table.no-columns.configure-columns',
		defaultMessage: 'Configure columns',
		description: 'CTA button copy shown when no columns have been selected.',
	},
});
