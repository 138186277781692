import { useMemo, useCallback } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { EventHandlers } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSelectedIssueStateOldActions } from '../../../../controllers/selected-issue-state-old/index.tsx';
import { useSelectedIssueActions } from '../../../../controllers/selected-issue/hooks.tsx';
import { useIssueSearchQuery } from '../../../../services/issue-search-query/index.tsx';
import { useOnDeleteIssue } from '../../../../services/on-delete-issue/index.tsx';
import { useIssueByFieldsRefetch } from '../../../../services/refetch-issue/index.tsx';

const useEventHandlersOld = (): EventHandlers => {
	const { setSelectedIssueByKey, deselectIssue } = useSelectedIssueStateOldActions();

	const { onDeleteIssue, onIssueByFieldsRefetch } = useIssueSearchQuery();
	const onIssueRefetch = onIssueByFieldsRefetch;
	// While we don't have a mutation setup for inline editing, use issue refetch as a substitute
	const onUpdateCache = onIssueByFieldsRefetch;
	const onIssueDelete = useCallback(
		(deletedIssueKey: string) => {
			const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
			if (!issueKeyToSelect.length) {
				deselectIssue(false, false, false);
				return;
			}
			setSelectedIssueByKey(toIssueKey(issueKeyToSelect), false, false, false);
		},
		[onDeleteIssue, setSelectedIssueByKey, deselectIssue],
	);

	const onIssueLinkSelected = useCallback(
		(issueKey: string): boolean => {
			setSelectedIssueByKey(toIssueKey(issueKey));
			return true;
		},
		[setSelectedIssueByKey],
	);

	return useMemo(
		() => ({
			onIssueRefetch,
			onUpdateCache,
			onIssueDelete,
			onIssueLinkSelected,
		}),
		[onIssueRefetch, onUpdateCache, onIssueDelete, onIssueLinkSelected],
	);
};

const useEventHandlersNew = (
	connectionId: string | undefined,
	fieldSetIds: string[],
): EventHandlers => {
	const { setSelectedIssueByKey, deselectIssue } = useSelectedIssueActions();
	const onDeleteIssue = useOnDeleteIssue(connectionId);
	const onIssueByFieldsRefetch = useIssueByFieldsRefetch(fieldSetIds);

	const onIssueRefetch = onIssueByFieldsRefetch;
	// While we don't have a mutation setup for inline editing, use issue refetch as a substitute
	const onUpdateCache = onIssueByFieldsRefetch;
	const onIssueDelete = useCallback(
		(deletedIssueKey: string) => {
			const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
			if (!issueKeyToSelect.length) {
				deselectIssue({
					shouldDebounce: false,
					shouldNotifyOnChange: false,
					isSelectedByUserInteraction: false,
				});
				return;
			}
			setSelectedIssueByKey(toIssueKey(issueKeyToSelect), {
				shouldDebounce: false,
				shouldNotifyOnChange: false,
				isSelectedByUserInteraction: false,
			});
		},
		[onDeleteIssue, setSelectedIssueByKey, deselectIssue],
	);

	const onIssueLinkSelected = useCallback(
		(issueKey: string): boolean => {
			setSelectedIssueByKey(toIssueKey(issueKey));
			return true;
		},
		[setSelectedIssueByKey],
	);

	return useMemo(
		() => ({
			onIssueRefetch,
			onUpdateCache,
			onIssueDelete,
			onIssueLinkSelected,
		}),
		[onIssueRefetch, onUpdateCache, onIssueDelete, onIssueLinkSelected],
	);
};

export const useEventHandlers = (connectionId: string | undefined, fieldSetIds: string[]) =>
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useEventHandlersNew(connectionId, fieldSetIds)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useEventHandlersOld();
