import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	weCouldntFindThisFilter: {
		id: 'issue-navigator.common.no-filter.we-couldnt-find-this-filter',
		defaultMessage: "We couldn't find this filter",
		description:
			"This message is displayed when a selected filter doesn't exist or you don't have permissions to see it",
	},
	filterNoExistOrNoPermissions: {
		id: 'issue-navigator.common.no-filter.filter-no-exist-or-no-permissions',
		defaultMessage: 'This filter may not exist, or you don’t have permissions to view it.',
		description:
			"This message is displayed when a selected filter doesn't exist or you don't have permissions to see it",
	},
	tryAnotherFilter: {
		id: 'issue-navigator.common.no-filter.try-another-filter',
		defaultMessage: 'Try searching for another filter, or start a new issue search.',
		description:
			'Message that give 2 options, search for another filter that goes to filters main screen' +
			'or do a new issue search that goes to NIN main screen',
	},
	viewFilters: {
		id: 'issue-navigator.common.no-filter.view-filters',
		defaultMessage: 'View filters',
		description: 'Button that redirect to filters page',
	},
	searchIssues: {
		id: 'issue-navigator.common.no-filter.search-issues',
		defaultMessage: 'Search issues',
		description: 'Button that redirect to default jql issue list',
	},
	tryAnotherFilterIssueTermRefresh: {
		id: 'issue-navigator.common.no-filter.try-another-filter-issue-term-refresh',
		defaultMessage: 'Try searching for another filter, or start a new search.',
		description:
			'Message that give 2 options, search for another filter that goes to filters main screen' +
			'or do a new issue search that goes to NIN main screen',
	},
	searchIssuesIssueTermRefresh: {
		id: 'issue-navigator.common.no-filter.search-issues-issue-term-refresh',
		defaultMessage: 'Search work items',
		description: 'Button that redirect to default jql issue list',
	},
});
