import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueCountProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import PageText from '../../../../common/ui/page-text/index.tsx';
import RefreshButton from '../../../../common/ui/refresh-button/index.tsx';
import { useIssueSearchQuery } from '../../../../services/issue-search-query/index.tsx';

/**
 * Override default issue table issue count to support a refresh button and fetching the 'real' total issue count.
 */
export const IssueCount = (issueCountProps: IssueCountProps) => {
	// TODO Remove when cleaning up jira_spreadsheet_component_m1
	let isStalePage = false;
	if (!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isStalePage } = useIssueSearchQuery());
	}

	return (
		<IssueCountContainer
			data-testid="issue-navigator.ui.issue-results.list-view.issue-count"
			isFlexEnd={issueCountProps.isNewFooterOrderEnabled}
		>
			{/* Do not show issue count while issue data is stale as page information will be inaccurate */}
			{!isStalePage &&
				(isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? (
					<Box
						as="span"
						testId="issue-navigator.ui.issue-results.list-view.issue-count.text"
						xcss={textStyles}
					>
						<PageText {...issueCountProps} />
					</Box>
				) : (
					<span data-testid="issue-navigator.ui.issue-results.list-view.issue-count.text">
						<PageText {...issueCountProps} />
					</span>
				))}
			<RefreshButton />
		</IssueCountContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div<{ isFlexEnd: boolean }>({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// cleanup isFlexEnd with visual-refresh FG
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	justifyContent: ({ isFlexEnd }) => (isFlexEnd ? 'flex-end' : 'center'),
	fontWeight: 600,
	gap: token('space.050', '4px'),
});

const textStyles = xcss({
	fontWeight: 'font.weight.medium',
	color: 'color.text.subtle',
});
