import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	issueIndexTotalKnown: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.issue-index-total-known',
		defaultMessage: '{issueIndex} of {totalNumberIssues}',
		description: 'Text indicating the selected issue index and the total number of issues.',
	},
	issueIndexTotalKnownIssueTermRefresh: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.issue-index-total-known-issue-term-refresh',
		defaultMessage: '{issueIndex} of {totalNumberIssues}',
		description: 'Text indicating the selected issue index and the total number of issues.',
	},
});
export default messages;
