/**
 * @generated SignedSource<<e05b0dae7f491c94ab80c47dda71ac9b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_jiraView$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_spreadsheetView">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_jiraView";
};
export type main_issueNavigator_IssueResults_jiraView$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_jiraView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_jiraView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_jiraView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_ListView_spreadsheetView"
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "070d0d75c2fef2b7b557da04658d3767";

export default node;
