import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	reorderColumnsErrorTitle: {
		id: 'issue-navigator.issue-results.list-view.reorder-columns-error-title',
		defaultMessage: "We couldn't reorder your columns",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to reorder columns in a table.",
	},
	resetColumnsErrorTitle: {
		id: 'issue-navigator.issue-results.list-view.reset-columns-error-title',
		defaultMessage: "We couldn't restore your default columns",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to reorder columns in a table.",
	},
	columnConfigMutationErrorDescription: {
		id: 'issue-navigator.issue-results.list-view.column-config-mutation-error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			"Displayed as error flag's description when an unexpected error happens while user is trying to reorder or edit columns in a table.",
	},
});
