/**
 * @generated SignedSource<<e05da6d7f74cfbcf56e89cb84f07afcb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueTableHierarchyToggle_HierarchyToggle$data = {
  readonly id: string;
  readonly viewConfigSettings?: {
    readonly __id: string;
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly isGroupingEnabled: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string | null | undefined;
  readonly viewSettings?: {
    readonly __id: string;
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly isGroupingEnabled: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueTableHierarchyToggle_HierarchyToggle";
};
export type ui_issueTableHierarchyToggle_HierarchyToggle$key = {
  readonly " $data"?: ui_issueTableHierarchyToggle_HierarchyToggle$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHierarchyToggle_HierarchyToggle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "isHierarchyEnabled"
  },
  {
    "kind": "ScalarField",
    "name": "canEnableHierarchy"
  },
  {
    "kind": "ScalarField",
    "name": "isGroupingEnabled"
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "__id"
      }
    ]
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueTableHierarchyToggle_HierarchyToggle",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "viewId"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "staticViewInput",
              "variableName": "staticViewInput"
            }
          ],
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewConfigSettings",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ],
      "type": "JiraIssueSearchView"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};
})();

(node as any).hash = "ea604e1740ed79161c3e63b98ccc7652";

export default node;
