import React, { memo, useMemo } from 'react';
import { ProjectStyle } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { setIsGlobalScopeEnrolmentEnabledInLocalStorage } from '@atlassian/jira-issue-navigator-global-scope-enrolment/src/services/global-scope-enrolment/local-storage/index.tsx';
import type { View } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { getIssueNavigatorFFAndFG } from '@atlassian/jira-issue-navigator/src/common/utils/get-issue-navigator-ff-and-fg.tsx';
import { NINPageLoad } from '@atlassian/jira-issue-navigator/src/common/utils/performance-analytics.tsx';
import {
	getJqlFromParamValues,
	getSearchInput as getInput,
	useUpdateUrlQueryParamCallback,
} from '@atlassian/jira-issue-navigator/src/common/utils/spa-utils.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import ProjectIssueNavigatorApp, {
	type Props as ProjectIssueNavigatorProps,
} from '@atlassian/jira-project-issue-navigator/src/index.tsx';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled/src/index.tsx';
import {
	issueNavigatorFilterRelayResource,
	issueNavigatorRelayResource,
	issueNavigatorHydrationRelayResource,
} from '@atlassian/jira-router-resources-issue-navigator/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import {
	getDefaultJql,
	mapFilterToJql,
	isFilterKey,
} from '@atlassian/jira-spa-apps-project-issue-navigator-utils/src/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useResource } from '@atlassian/react-resource-router';

type CommonProps = Omit<
	ProjectIssueNavigatorProps,
	'defaultJql' | 'queryReference' | 'filterQueryReference' | 'searchInput' | 'issueTableProps'
>;
export type Props = CommonProps & {
	jql: string | undefined;
	filter: string | undefined;
	initialised: boolean;
	view?: View;
	showNav3Header?: boolean;
};

const getSearchInput = (
	projectKey: string,
	jql: string | undefined,
	filter: string | undefined,
) => {
	const filterInput = isFilterId(filter) ? filter : mapFilterToJql(projectKey, filter);
	return getInput(jql, filterInput);
};

const AsyncProjectIssueNavigatorWrapper = ({
	filter,
	jql: jqlParam,
	initialised,
	view,
	projectKey,
	showNav3Header = false,
	...props
}: Props) => {
	const onMutateJqlParam = useUpdateUrlQueryParamCallback('jql');

	const defaultJql =
		projectKey !== null && projectKey !== undefined ? getDefaultJql(projectKey) : '';

	const jql = getJqlFromParamValues(
		defaultJql,
		jqlParam,
		filter,
		mapFilterToJql.bind(undefined, projectKey),
		onMutateJqlParam,
	);

	const issueTableProps = useMemo(
		() =>
			expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)
				? {
						projectContext: projectKey,
						isGroupingSupported: expVal(
							'jira_spreadsheet_component_grouping',
							'isGroupingEnabled',
							false,
						),
					}
				: undefined,
		[projectKey],
	);

	const searchInput = useMemo(
		() => getSearchInput(projectKey, jql, filter),
		[projectKey, jql, filter],
	);

	const { data: projectContext } = useResource(projectContextResource);

	const { data: globalEnrolmentData } = useResource(isNinGlobalScopeEnrolmentEnabledResource);
	if (globalEnrolmentData !== null && globalEnrolmentData !== undefined) {
		setIsGlobalScopeEnrolmentEnabledInLocalStorage(globalEnrolmentData);
	}

	const response = useRelayResource(issueNavigatorRelayResource);
	const { queryReference } = response;

	const filterResponse = useRelayResource(issueNavigatorFilterRelayResource);
	const filterQueryReference = filterResponse.queryReference;

	const hydrationResponse = useRelayResource(issueNavigatorHydrationRelayResource);
	const hydrationQueryReference = hydrationResponse.queryReference;

	// During SSR our issueNavigatorRelayResource must wait until the query finishes before returning the
	// queryReference as SSR does not support Suspense. We return the page skeleton as an intermediary state until
	// the query has resolved (or SSR times out and the query is loaded on the client).
	if (queryReference == null) {
		return <ProjectIssueNavigatorSkeleton />;
	}

	// During SSR our issueNavigatorFilterRelayResource must wait until the query finishes before returning the
	// queryReference as SSR does not support Suspense. We return the page skeleton as an intermediary state until
	// the query has resolved (or SSR times out and the query is loaded on the client).
	if (filterQueryReference == null) {
		return <ProjectIssueNavigatorSkeleton />;
	}

	let resolvedProjectConfig;
	switch (projectContext?.simplified) {
		case true:
			resolvedProjectConfig = ProjectStyle.NEXT_GEN;
			break;
		case false:
			resolvedProjectConfig = ProjectStyle.CLASSIC;
			break;
		default:
			resolvedProjectConfig = '';
	}
	const resolvedProjectType = projectContext?.projectType || '';
	const filterForAnalytics =
		filter && (isFilterId(filter) || isFilterKey(filter)) ? filter : 'default';

	const attributes = {
		navigatorScope: 'project',
		projectConfig: resolvedProjectConfig,
		projectType: resolvedProjectType,
		viewLayout: view || '',
		jql: !!jqlParam,
		filter: filterForAnalytics,
		...getIssueNavigatorFFAndFG(),
	};

	const extraInfo = {
		scope: 'project',
		filterId: filterForAnalytics,
		isJqlParamPresent: !!jqlParam,
		...attributes,
	};

	return (
		<ContextualAnalyticsData
			attributes={attributes}
			{...(filter !== null &&
				filter !== undefined &&
				(isFilterId(filter) || isFilterKey(filter)) && {
					containers: { issueFilter: { id: filter } },
				})}
		>
			<ReportErrors
				id="unhandled"
				packageName="jiraSpaAppsProjectIssueNavigator"
				teamName="empanada"
				sendToPrivacyUnsafeSplunk
				attributes={isLoaderErrorAttributes}
			>
				{/* Render a Suspense boundary to handle the scenario where the resource query times out in SSR and
                content (i.e. nav) is still returned. Ideally we'd use the SPA level suspense boundary but there is some
                internal state logic in RLL that is preventing the fallback being rendered after the async component has
                successfully imported. */}
				<Placeholder
					name="project-issue-navigator-app"
					fallback={<ProjectIssueNavigatorSkeleton />}
				>
					<ProjectIssueNavigatorApp
						{...props}
						showNav3Header={showNav3Header}
						projectKey={projectKey}
						defaultJql={defaultJql}
						queryReference={queryReference || undefined}
						filterQueryReference={filterQueryReference}
						searchInput={searchInput}
						issueTableProps={issueTableProps}
						isIssueHierarchySupportEnabled={
							// eslint-disable-next-line jira/ff/no-preconditioning
							expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
							fg('jsc_m2_hierarchy_fe_changes')
						}
						jqlBuilderProps={{ maybeHydrationQueryReference: hydrationQueryReference || undefined }}
					/>
				</Placeholder>
			</ReportErrors>
			{initialised && (
				<>
					{/* @ts-expect-error - Types for JFE BM3Metric and @atlassian/browser-metrics' PageLoadMetric are not compatible */}
					<SubmitApdex
						appName="project-issue-navigator"
						isExpectedToHaveSsr
						withMarks={[appBundleReadyMark, prefetchApiReadyMark]}
						ttrSlo={3000}
						ttiSlo={4000}
						extra={extraInfo}
						metric={NINPageLoad}
					/>
					<SpaStatePageReady />
				</>
			)}
		</ContextualAnalyticsData>
	);
};

export default memo<Props>(AsyncProjectIssueNavigatorWrapper);
