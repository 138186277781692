import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate } from 'relay-runtime';
import type { issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults$data as IssueResultsData } from '@atlassian/jira-relay/src/__generated__/issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults.graphql';

export const useOnDeleteIssue = (data?: IssueResultsData | null) => {
	const environment = useRelayEnvironment();

	return useCallback(
		(issueKeyToDelete: string) => {
			const issueResultEdges = data?.edges ?? [];
			let previousIssueKey;
			let nextIssueKey;
			commitLocalUpdate(environment, (store) => {
				let record;
				for (let i = 0; i < issueResultEdges.length; i++) {
					const edge = issueResultEdges[i];
					const key = edge?.node?.key;

					// If we have not found the record holding the deleted issue
					if (!record) {
						// Check if we've found the deleted issue
						if (edge && key === issueKeyToDelete) {
							record = store.get(edge.__id);
						}
						// Otherwise, keep a running track of the previous issue key
						else if (key != null) {
							previousIssueKey = key;
						}
					}
					// Keep iterating over our issue edges until we find the next issue key (if it exists)
					else if (key != null) {
						nextIssueKey = key;
						break;
					}
				}

				// Nullify the issue node which we'll handle in our UI as a deleted issue
				record?.setValue(null, 'node');
			});

			// Return the next issue key to select. If there is no next issue key then return the previous key.
			return nextIssueKey ?? previousIssueKey ?? '';
		},
		[environment, data?.edges],
	);
};
