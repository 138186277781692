/* eslint-disable @atlassian/relay/graphql-naming */
import React, { type PropsWithChildren, useCallback, useMemo } from 'react';
import { graphql, type RefetchFn, useFragment, useRelayEnvironment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, toFlagId, type FlagConfiguration } from '@atlassian/jira-flags';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type {
	IssueNavigatorIssueSearchRefetchQuery,
	IssueNavigatorIssueSearchRefetchQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type { issueSearch_issueNavigator$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigator.graphql';
import type { issueSearch_issueNavigatorGrouping$key } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigatorGrouping.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

import { PACKAGE_NAME } from '../../common/constants.tsx';
import type { IssueNavigatorViewId, OverridableIssueTableProps } from '../../common/types.tsx';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils/index.tsx';
import { useActiveJql } from '../../services/active-jql/index.tsx';
import { useExperienceAnalytics } from '../../services/experience-analytics/index.tsx';
import {
	useIssueSearchPaginationShadowRequest,
	useMainIssueNavigatorQueryShadowRequest,
} from '../../services/issue-search-shadow-request/index.tsx';
import { IssueSearchServiceContainer } from '../../services/issue-search/index.tsx';
import type { InfiniteScrollProps } from '../../services/issue-search/types.tsx';
import { useSelectedViewMutation } from '../../services/selected-view/index.tsx';
import messages from './messages.tsx';

type Props = PropsWithChildren<
	InfiniteScrollProps & {
		/**
		 * Event emitted when page data has loaded and the key experience is interactive.
		 */
		onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
		/**
		 * Fragment ref of the issue search view currently rendered.
		 */
		view: ViewFragment | null;
		/**
		 * Fragment ref of the view currently rendered.
		 */
		jiraView: issueSearch_issueNavigatorGrouping$key | null;
		/**
		 * Relay refetch function to perform a new issue search.
		 */
		refetch: RefetchFn<IssueNavigatorIssueSearchRefetchQuery>;
		/**
		 * Flag indicating whether issue hierarchy feature is enabled in the experience
		 */
		isIssueHierarchySupportEnabled: boolean | undefined;
		/**
		 * Props for shadow requests.
		 */
		issueTableProps?: OverridableIssueTableProps;
		beforeCursor?: string | null;
		afterCursor?: string | null;
	}
>;

const searchErrorFlagId = toFlagId('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
const searchErrorFlag: FlagConfiguration = {
	id: searchErrorFlagId,
	type: 'error',
	title: messages.searchFailedTitle,
	description: messages.searchFailedDescription,
};

export const IssueSearch = ({
	children,
	onPageDataLoad,
	view,
	refetch,
	onLoadNext,
	onLoadPrevious,
	isLoadingNext,
	isLoadingPrevious,
	hasNext,
	hasPrevious,
	isIssueHierarchySupportEnabled,
	jiraView,
	issueTableProps,
	afterCursor,
	beforeCursor,
}: Props) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const { jql, filterId } = useActiveJql();
	const issueSearchInput = useMemo(
		() => (jql === undefined && isFilterId(filterId) ? { filterId } : { jql }),
		[filterId, jql],
	);
	const onSelectedViewMutation = useSelectedViewMutation();

	const issueSearchViewData = useFragment<ViewFragment>(
		graphql`
			fragment issueSearch_issueNavigator on JiraIssueSearchView
			@argumentDefinitions(
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
			) {
				viewConfigSettings(staticViewInput: $staticViewInput) @include(if: $isJscM2Enabled) {
					isHierarchyEnabled
				}
				viewId @required(action: THROW)
			}
		`,
		view,
	);

	const jiraViewData = useFragment<issueSearch_issueNavigatorGrouping$key>(
		graphql`
			fragment issueSearch_issueNavigatorGrouping on JiraView {
				__typename
				... on JiraSpreadsheetView {
					viewId
					viewSettings {
						isGroupingEnabled
						groupByConfig {
							groupByField {
								fieldId
							}
						}
						isHierarchyEnabled
					}
				}
			}
		`,
		jiraView,
	);

	let groupedByField: string | null = null;
	let isGroupingEnabled: boolean;
	let isHierarchyEnabled: boolean;
	let maybeViewId: string | null | undefined;

	if (fg('refactor_nin_to_jira_view_schema')) {
		groupedByField = jiraViewData?.viewSettings?.groupByConfig?.groupByField?.fieldId ?? null;
		isGroupingEnabled = Boolean(jiraViewData?.viewSettings?.isGroupingEnabled);
		isHierarchyEnabled = Boolean(
			isIssueHierarchySupportEnabled && jiraViewData?.viewSettings?.isHierarchyEnabled,
		);
		// TODO: simplify logic once JiraDetailedView implements JiraIssueSearchViewMetadata
		// https://jbusiness.atlassian.net/browse/JSC-720
		maybeViewId = jiraViewData?.__typename === 'JiraDetailedView' ? 'detail' : jiraViewData?.viewId;
	} else {
		isGroupingEnabled = false;
		isHierarchyEnabled = Boolean(
			isIssueHierarchySupportEnabled && issueSearchViewData?.viewConfigSettings?.isHierarchyEnabled,
		);
		maybeViewId = issueSearchViewData?.viewId;
	}

	const onRefetchStart = useCallback(() => {
		// Hide any search error flags currently visible
		dismissFlag(searchErrorFlagId);
	}, [dismissFlag]);

	const viewId = parseIssueNavigatorViewIdOrDefault(maybeViewId);
	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, viewId, {
		isGroupingEnabled,
	});
	const onRefetchError = useCallback(
		(error: Error, refetchView: IssueNavigatorViewId) => {
			onIssueSearchFail(`${PACKAGE_NAME}.issue-search-refetch`, error, refetchView);
			showFlag(searchErrorFlag);
		},
		[onIssueSearchFail, showFlag],
	);
	const projectContext = issueTableProps?.projectContext;

	const fetchPaginationShadow = useIssueSearchPaginationShadowRequest();
	const onLoadNextPage = useCallback(
		(
			count: number,
			options:
				| {
						onComplete?: ((arg: Error | null) => void) | undefined;
						UNSTABLE_extraVariables?:
							| Partial<IssueNavigatorIssueSearchRefetchQuery$variables>
							| undefined;
				  }
				| undefined,
		) => {
			onLoadNext(count, options);
			if (projectContext && viewId !== 'detail' && fg('jira_list_-_hierarchy_-_shadow_request')) {
				fetchPaginationShadow({
					first: 50,
					after: afterCursor,
					cloudId,
					issueSearchInput,
					fieldSetsInput: options?.UNSTABLE_extraVariables?.fieldSetsInput,
					fieldSetIds: [],
					filterId: filterId ?? null,
					isRefactorNinToViewSchemaEnabled: false,
					shouldQueryFieldSetsById: false,
					projectKeys: [projectContext],
					viewConfigInput: { staticViewInput: { isHierarchyEnabled: true } },
				});
			}
		},
		[
			onLoadNext,
			projectContext,
			viewId,
			fetchPaginationShadow,
			afterCursor,
			cloudId,
			issueSearchInput,
			filterId,
		],
	);

	const onLoadPrevPage = useCallback(
		(
			count: number,
			options:
				| {
						onComplete?: ((arg: Error | null) => void) | undefined;
						UNSTABLE_extraVariables?:
							| Partial<IssueNavigatorIssueSearchRefetchQuery$variables>
							| undefined;
				  }
				| undefined,
		) => {
			onLoadPrevious(count, options);
			if (projectContext && viewId !== 'detail' && fg('jira_list_-_hierarchy_-_shadow_request')) {
				fetchPaginationShadow({
					last: 50,
					before: beforeCursor,
					cloudId,
					issueSearchInput,
					fieldSetsInput: options?.UNSTABLE_extraVariables?.fieldSetsInput,
					fieldSetIds: [],
					filterId: filterId ?? null,
					isRefactorNinToViewSchemaEnabled: false,
					shouldQueryFieldSetsById: false,
					projectKeys: [projectContext],
					viewConfigInput: { staticViewInput: { isHierarchyEnabled: true } },
				});
			}
		},
		[
			beforeCursor,
			cloudId,
			fetchPaginationShadow,
			filterId,
			issueSearchInput,
			onLoadPrevious,
			projectContext,
			viewId,
		],
	);

	useMainIssueNavigatorQueryShadowRequest(
		{
			first: 50,
			amountOfColumns: 500,
			fieldSetIds: [],
			shouldQueryFieldSetsById: false,
			cloudId,
			issueSearchInput,
			atlassianIntelligenceProductFeature: 'NATURAL_LANGUAGE_TO_JQL',
			projectKey: projectContext ?? '',
			shouldQueryProject: false,
			filterId: filterId ?? null,
			viewConfigInput: {
				viewInput: {
					namespace: 'ISSUE_NAVIGATOR',
				},
			},
			fieldSetsInput: {
				viewInput: {
					namespace: 'ISSUE_NAVIGATOR',
					filterId: filterId ?? null,
				},
			},
		},
		viewId,
	);

	return (
		<IssueSearchServiceContainer
			cloudId={cloudId}
			environment={environment}
			issueSearchInput={issueSearchInput}
			viewId={viewId}
			filterId={filterId}
			onPageDataLoad={onPageDataLoad}
			onRefetchStart={onRefetchStart}
			onRefetchError={onRefetchError}
			onSelectedViewMutation={onSelectedViewMutation}
			refetch={refetch}
			onLoadNext={onLoadNextPage}
			onLoadPrevious={onLoadPrevPage}
			isLoadingNext={isLoadingNext}
			isLoadingPrevious={isLoadingPrevious}
			hasNext={hasNext}
			hasPrevious={hasPrevious}
			projectContext={projectContext}
			isHierarchyEnabled={isHierarchyEnabled}
			groupedByField={groupedByField}
		>
			{children}
		</IssueSearchServiceContainer>
	);
};
