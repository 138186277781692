import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showHierarchy: {
		id: 'issue-table-show-hierarchy',
		defaultMessage: 'Show hierarchy',
		description: 'Text shown in the label for toggle to enable/disable the hierarchy view',
	},
	hierarchyDisabledReasonGroupingAndFiltersNonFinal: {
		id: 'issue-table-hierarchy-disabled-reason-grouping-and-filters-non-final',
		defaultMessage: 'Clear grouping and filters to show hierarchy',
		description:
			'Text shown under hierarchy toggle when hierarchy is disabled because of grouping and filters',
	},
	hierarchyDisabledReasonFiltersNonFinal: {
		id: 'issue-table-hierarchy-disabled-reason-filters-non-final',
		defaultMessage: 'Clear filters to show hierarchy',
		description: 'Text shown under hierarchy toggle when hierarchy is disabled because of filters',
	},
	hierarchyErrorFlagTitleNonFinal: {
		id: 'issue-table-hierarchy-error-flag-title-non-final',
		defaultMessage: 'Hierarchy preference could not be updated.',
		description: 'Text shown in the error flag when hierarchy toggle fails to update',
	},
	hierarchyErrorFlagDescriptionNonFinal: {
		id: 'issue-table-hierarchy-error-flag-description-non-final',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Text shown in the error flag when hierarchy toggle fails to update',
	},
});
