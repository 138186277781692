import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { NoIssuesProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import messages from './messages.tsx';

export const NoIssues = ({ defaultNoIssuesGlyph }: NoIssuesProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlock="space.800">
			<EmptyState
				header={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.noIssuesIssueTermRefresh
						: messages.noIssues,
				)}
				renderImage={() => defaultNoIssuesGlyph}
				testId="issue-navigator.ui.issue-results.list-view.no-issues.empty-state"
			/>
		</Box>
	);
};
