/**
 * @generated SignedSource<<0913b38543a4ac835cf7eeb194420fa3>>
 * @relayHash d4259e050915cf8816f8c8a4884d4afb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fa55db43feb181a2ea2d08440aa1fca705fd8ddbbea5388f6c28457a3933a61e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type FilterRefetchQuery$variables = {
  filterAri: string;
  includeFilter: boolean;
};
export type FilterRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_filterQuery">;
};
export type FilterRefetchQuery = {
  response: FilterRefetchQuery$data;
  variables: FilterRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeFilter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "FilterRefetchQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_filterQuery"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "includeFilter",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "filterAri"
                  }
                ],
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraFilter"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isEditable"
                      }
                    ],
                    "type": "JiraCustomFilter"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "filterId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isFavourite"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fa55db43feb181a2ea2d08440aa1fca705fd8ddbbea5388f6c28457a3933a61e",
    "metadata": {},
    "name": "FilterRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "26da718314f181d4824f62928229af2b";

export default node;
