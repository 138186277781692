/**
 * @generated SignedSource<<03678eaaa27509a9eaff05b3536ed7a3>>
 * @relayHash 411c1701f9d22f072c495ac536a98400
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f11f424abd082a8324c0f881b823fe5b84699dcb133b3f8c6933ee47e9907505

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraIssueNavigatorSearchLayout = "DETAIL" | "LIST" | "%future added value";
export type selectedView_updateUserViewModeMutation$variables = {
  cloudId: string;
  searchLayout?: JiraIssueNavigatorSearchLayout | null | undefined;
};
export type selectedView_updateUserViewModeMutation$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly setIssueNavigatorSearchLayout: {
        readonly errors: ReadonlyArray<{
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly issueNavigatorSearchLayout: JiraIssueNavigatorSearchLayout | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type selectedView_updateUserViewModeMutation = {
  response: selectedView_updateUserViewModeMutation$data;
  variables: selectedView_updateUserViewModeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchLayout"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "JiraUserPreferencesMutation",
        "kind": "LinkedField",
        "name": "userPreferences",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "searchLayout",
                "variableName": "searchLayout"
              }
            ],
            "concreteType": "JiraIssueNavigatorSearchLayoutMutationPayload",
            "kind": "LinkedField",
            "name": "setIssueNavigatorSearchLayout",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "success"
              },
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "issueNavigatorSearchLayout"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "selectedView_updateUserViewModeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectedView_updateUserViewModeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f11f424abd082a8324c0f881b823fe5b84699dcb133b3f8c6933ee47e9907505",
    "metadata": {},
    "name": "selectedView_updateUserViewModeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "57781762cebd4351d1b1402acc6af4c4";

export default node;
