import React from 'react';
import { LazySuspense, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useDebuggerStatus,
	useJqlDebuggerStoreActions,
} from '../controllers/jql-debugger-data/index.tsx';
import { ERROR_BOUNDARY_IDS, PACKAGE_NAME_JQL_DEBUGGER, TEAM_NAME } from '../common/constants.tsx';

export const JQLDebuggerRenderSlotAsync = lazyForPaint(
	() => import(/* webpackChunkName: "jql-debugger-content-modal" */ './index'),
);

export function JQLDebuggerRenderSlot() {
	const [debuggerState] = useDebuggerStatus();
	const debuggerActions = useJqlDebuggerStoreActions();

	if (debuggerState === 'INIT') {
		return null;
	}

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.FIX_WITH_AI_MODAL}
			teamName={TEAM_NAME}
			fallback="flag"
			onError={() => {
				debuggerActions.resetStatus('JS_ERROR');
			}}
		>
			<LazySuspense fallback={null}>
				<JQLDebuggerRenderSlotAsync />
			</LazySuspense>
		</JSErrorBoundary>
	);
}
