import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const onRefresh = () => {
	if (typeof window !== 'undefined') {
		window.location.reload();
	}
};

export const JQLBuilderErrorBoundryFallback = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionMessage
			appearance="error"
			testId="jql-builder-common.ui.error-boundry-fallback.section-message"
		>
			{formatMessage(messages.errorMessage, {
				link: (linkText: ReactNode[]) => (
					<Button appearance="link" spacing="none" onClick={onRefresh}>
						{linkText}
					</Button>
				),
			})}
		</SectionMessage>
	);
};
