import React, { createContext, useContext } from 'react';
import type { IntegrateDotDialogContextProps, ContextProviderProps } from './types.tsx';

const PRESET_VALUE = {
	DEFAULT: {
		isShortcutsDialogIntegrationAvailable: false,
	},
	INTEGRATED: {
		isShortcutsDialogIntegrationAvailable: true,
	},
};

const IntegrateDotDialogContext = createContext<IntegrateDotDialogContextProps>(
	PRESET_VALUE.DEFAULT,
);

export const useIntegrateDotDialogContext = () => useContext(IntegrateDotDialogContext);

export const ShortcutsDialogIntegrationProvider = ({ children }: ContextProviderProps) => (
	<IntegrateDotDialogContext.Provider value={PRESET_VALUE.INTEGRATED}>
		{children}
	</IntegrateDotDialogContext.Provider>
);
