import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys

export default defineMessages({
	nextIssue: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.next-issue',
		defaultMessage: 'Next issue',
		description: '',
	},
	previousIssue: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.previous-issue',
		defaultMessage: 'Previous issue',
		description: '',
	},
	displaySortFields: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.display-sort-fields',
		defaultMessage: 'Detail view order by',
		description: 'Opens the sort field selector dropdown',
	},
	openIssueFullScreen: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.open-issue-full-screen',
		defaultMessage: 'Open issue fullscreen',
		description: 'Opens the issue, taking up the entire screen',
	},
	nextIssueIssueTermRefresh: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.next-issue-issue-term-refresh',
		defaultMessage: 'Next work item',
		description: '',
	},
	previousIssueIssueTermRefresh: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.previous-issue-issue-term-refresh',
		defaultMessage: 'Previous work item',
		description: '',
	},
	openIssueFullScreenIssueTermRefresh: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.open-issue-full-screen-issue-term-refresh',
		defaultMessage: 'Open the work item in fullscreen',
		description: 'Opens the issue, taking up the entire screen',
	},
});
