/**
 * @generated SignedSource<<25d5caf50e68879c6d4de98b651c3a5e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardContainer_issueNavigator_issueResults$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename?: "JiraIssueEdge";
  } | null | undefined> | null | undefined;
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Paginator">;
  readonly " $fragmentType": "cardContainer_issueNavigator_issueResults";
};
export type cardContainer_issueNavigator_issueResults$key = {
  readonly " $data"?: cardContainer_issueNavigator_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "cardContainer_issueNavigator_issueResults",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_Paginator"
        }
      ]
    },
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__typename"
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "f8c6651dc7a2f7ffc8476a5456e66a48";

export default node;
