import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate } from 'relay-runtime';

/**
 * Commits a local update to the Relay store with the updated column width (i.e. field set preferences)
 * @param connectionId ID of the `JiraIssueSearchFieldSetConnection` record
 */
export const useColumnWidthUpdater = (connectionId: string) => {
	const environment = useRelayEnvironment();

	return useCallback(
		(columnId: string, width: number | undefined) => {
			commitLocalUpdate(environment, (store) => {
				const connection = store.get(connectionId);

				const fieldSetEdges = connection?.getLinkedRecords('edges') ?? [];

				const fieldSetEdge = fieldSetEdges.find((edge) => {
					const fieldSetId = edge?.getLinkedRecord('node')?.getValue('fieldSetId');
					return columnId === fieldSetId;
				});

				if (!fieldSetEdge) {
					return;
				}

				const fieldSetNode = fieldSetEdge.getLinkedRecord('node');

				if (!fieldSetNode) {
					return;
				}

				if (width == null) {
					fieldSetNode.setValue(null, 'fieldSetPreferences');
					return;
				}

				const fieldSetPreferences = fieldSetNode.getOrCreateLinkedRecord(
					'fieldSetPreferences',
					'JiraFieldSetPreferences',
				);

				fieldSetPreferences.setValue(width, 'width');
			});
		},
		[connectionId, environment],
	);
};
