import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys

export default defineMessages({
	infoText: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.info-text',
		defaultMessage: "We couldn't load this issue.",
		description: '',
	},
	reloadIssue: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.reload-issue',
		defaultMessage: 'Reload issue',
		description: '',
	},
	infoTextIssueTermRefresh: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.info-text-issue-term-refresh',
		defaultMessage: "We couldn't load this work item.",
		description: '',
	},
	reloadIssueIssueTermRefresh: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.reload-issue-issue-term-refresh',
		defaultMessage: 'Reload work item',
		description: '',
	},
});
