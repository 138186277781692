import React from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const LoadingContent = () => (
	<Flex justifyContent="center" alignItems="center" xcss={loadingStyles}>
		<Spinner size="large" />
	</Flex>
);
const loadingStyles = xcss({
	width: '250px',
	paddingBlock: 'space.500',
});
