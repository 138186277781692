/**
 * @generated SignedSource<<140068a82225ca3e6b3fc9a32209f60e>>
 * @relayHash e7018d1ccf85dde8ada50bb8f93d8d96
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2fc2bdb7e99242c748d59a003f006e50b4a56a963e6ef08e6ffb31bdfa8c511d

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraIssueSearchFieldSetSelectedState = "ALL" | "NON_SELECTED" | "SELECTED" | "%future added value";
export type JiraIssueSearchFieldSetsFilter = {
  fieldSetSelectedState?: JiraIssueSearchFieldSetSelectedState | null | undefined;
  searchString?: string | null | undefined;
};
export type fetchColumnPickerOptionsQuery$variables = {
  after?: string | null | undefined;
  cloudId: string;
  filter?: JiraIssueSearchFieldSetsFilter | null | undefined;
  first?: number | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type fetchColumnPickerOptionsQuery$data = {
  readonly jira: {
    readonly issueSearchViewByNamespaceAndViewId: {
      readonly fieldSets: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly displayName: string;
            readonly fieldSetId: string;
            readonly fieldType: {
              readonly displayName: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          };
        } | null | undefined>;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
        };
        readonly totalCount: number;
      };
    };
  };
};
export type fetchColumnPickerOptionsQuery = {
  response: fetchColumnPickerOptionsQuery$data;
  variables: fetchColumnPickerOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v9 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v11 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v12 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v13 = {
  "kind": "ScalarField",
  "name": "type"
},
v14 = {
  "concreteType": "JiraFieldType",
  "kind": "LinkedField",
  "name": "fieldType",
  "plural": false,
  "selections": [
    (v12/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "fetchColumnPickerOptionsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v6/*: any*/),
                "concreteType": "JiraIssueSearchView",
                "kind": "LinkedField",
                "name": "issueSearchViewByNamespaceAndViewId",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "args": (v7/*: any*/),
                      "concreteType": "JiraIssueSearchFieldSetConnection",
                      "kind": "LinkedField",
                      "name": "fieldSets",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v8/*: any*/),
                          "action": "THROW",
                          "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.totalCount"
                        },
                        (v9/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JiraIssueSearchFieldSet",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v11/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.fieldSetId"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v12/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.displayName"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v13/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.type"
                                    },
                                    (v14/*: any*/)
                                  ]
                                },
                                "action": "THROW",
                                "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.issueSearchViewByNamespaceAndViewId"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "fetchColumnPickerOptionsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssueSearchView",
            "kind": "LinkedField",
            "name": "issueSearchViewByNamespaceAndViewId",
            "plural": false,
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraIssueSearchFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSets",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "concreteType": "JiraIssueSearchFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "concreteType": "JiraIssueSearchFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2fc2bdb7e99242c748d59a003f006e50b4a56a963e6ef08e6ffb31bdfa8c511d",
    "metadata": {},
    "name": "fetchColumnPickerOptionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "374080c39c08d2cf25a28460ad1b6425";

export default node;
