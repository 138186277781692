import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip/src/index.tsx';

const backgroundColorSelected = token('color.background.selected', colors.B50);
const backgroundColor = token('elevation.surface.raised', colors.N0);
const borderColor = token('color.border', colors.N30);
const boxShadowColor = `1px 0px ${token('elevation.shadow.raised', colors.N40)}`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardBaseStyle = styled.a<{ isSelected?: boolean; visualRefresh: boolean }>(
	{
		display: 'block',
		padding: token('space.100', '8px'),
		boxSizing: 'border-box',
		'&:hover': {
			textDecoration: 'none',
		},
		'&:focus': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			boxShadow: `${token('color.border.focused', colors.B200)} 0 0 0 2px inset`,
			textDecoration: 'none',
			outline: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh, isSelected }) =>
		visualRefresh
			? {
					boxShadow: token('elevation.shadow.raised'),
					backgroundColor: isSelected
						? token('color.background.accent.blue.subtlest')
						: token('elevation.surface.raised'),
					borderRadius: token('border.radius.100'),
					margin: token('space.025'), // small margin needed so box shadow is visible.
					padding: token('space.150'),
				}
			: {
					backgroundColor: isSelected ? backgroundColorSelected : backgroundColor,
					borderTop: 0,
					borderBottom: `1px solid ${borderColor}`,
					borderLeft: `1px solid ${borderColor}`,

					'&:first-of-type': {
						borderTop: `1px solid ${borderColor}`,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						borderRadius: `${gridSize * 0.5}px ${gridSize * 0.5}px 0 0`,
					},

					'&:last-child': {
						boxShadow: `1px 1px ${boxShadowColor}`,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						borderRadius: `0 0 ${gridSize * 0.5}px ${gridSize * 0.5}px`,
					},

					'&:first-of-type:last-child': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						borderRadius: `${gridSize * 0.5}px`,
					},
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullHeightDisabledButton = styled(DisabledButtonWithTooltip)({
	height: '100%',
});
