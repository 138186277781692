/**
 * @generated SignedSource<<85decfef606fb5a326caaf5bdd65f908>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_groupResults$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_groups">;
  readonly " $fragmentType": "main_issueNavigator_ListView_groupResults";
};
export type main_issueNavigator_ListView_groupResults$key = {
  readonly " $data"?: main_issueNavigator_ListView_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isPaginating"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_groupResults",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ui_nativeIssueTable_NativeIssueTable_groups"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "dbb3fa28eb08634d840334e00873c14a";

export default node;
