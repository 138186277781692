/**
 * @generated SignedSource<<1da52f0913815b9fe1e6847953ff7bea>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_userPreferences$data = {
  readonly " $fragmentSpreads": FragmentRefs<"spotlight_issueNavigator_IssueNavigatorSpotlight">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_userPreferences";
};
export type main_issueNavigator_IssueNavigatorUI_userPreferences$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_userPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_userPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_userPreferences",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "spotlight_issueNavigator_IssueNavigatorSpotlight"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "aa84b2b1589fd8e695aa782c018fd60c";

export default node;
