/**
 * @generated SignedSource<<f869b29cb2bead806de4a76c098662d6>>
 * @relayHash 49c7fff6a0e7619f946ea6858ac070f4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aa78c7137d4bf2c426f358e5e8e25a3d05afb5062d08ddd15fa808b550e9004e

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraFieldSetPreferencesMutationInput = {
  nodes?: ReadonlyArray<JiraUpdateFieldSetPreferencesInput> | null | undefined;
};
export type JiraUpdateFieldSetPreferencesInput = {
  fieldSetId: string;
  width?: number | null | undefined;
};
export type main_updateFieldSetPreferencesMutation$variables = {
  cloudId: string;
  input: JiraFieldSetPreferencesMutationInput;
};
export type main_updateFieldSetPreferencesMutation$data = {
  readonly jira: {
    readonly updateUserFieldSetPreferences: {
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type main_updateFieldSetPreferencesMutation = {
  response: main_updateFieldSetPreferencesMutation$data;
  variables: main_updateFieldSetPreferencesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "fieldSetPreferencesInput",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraFieldSetPreferencesUpdatePayload",
        "kind": "LinkedField",
        "name": "updateUserFieldSetPreferences",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "main_updateFieldSetPreferencesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "main_updateFieldSetPreferencesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "aa78c7137d4bf2c426f358e5e8e25a3d05afb5062d08ddd15fa808b550e9004e",
    "metadata": {},
    "name": "main_updateFieldSetPreferencesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5e33dd509f20cf3d934020e4f1ce2386";

export default node;
