/**
 * Collection of keys representing individual spotlight cards displayed within the issue navigator.
 */
export const cardKeys = {
	GLOBAL_SCOPE_JQL_BUILDER_NL_TO_JQL: 'global-scope.jql-builder-with-ai.natural-language-to-jql',
	GLOBAL_SCOPE_VIEW_SWITCHER: 'global-scope.view-switcher',
	GLOBAL_SCOPE_COLUMN_PICKER: 'global-scope.column-picker',
	JQL_BUILDER_NL_TOGGLE: 'jql-builder-with-ai.natural-language-toggle',
	JQL_BUILDER_NL_TO_JQL: 'jql-builder-with-ai.natural-language-to-jql',
} as const;
