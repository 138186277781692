import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	screenReaderGroupResultInfo: {
		id: 'issue-navigator.screen-reader-group-result-info',
		defaultMessage: 'Grouping has been applied to the list.',
		description:
			'This message is displayed for screen reader users only to announce the grouping has applied to the list',
	},
	screenReaderSearchResultInfo: {
		id: 'issue-navigator.screen-reader-search-result-info',
		defaultMessage:
			'{total, plural, one {One issue matches your search.} other {{total} issues match your search.}}',
		description:
			'This message is displayed for screen reader users only to announce the exact amount of issues when a new search result is loaded',
	},
	screenReaderSearchResultMoreInfo: {
		id: 'issue-navigator.screen-reader-search-result-more-info',
		defaultMessage:
			'{total, plural, one {{total} or more issues found} other {{total} or more issues found}}.',
		description:
			'This message is displayed for screen reader users only to announce that more than a specific amount of issues are found when a new search result is loaded',
	},
	screenReaderSearchResultInfoIssueTermRefresh: {
		id: 'issue-navigator.screen-reader-search-result-info-issue-term-refresh',
		defaultMessage:
			'{total, plural, one {One work item matches your search.} other {{total} work items match your search.}}',
		description:
			'This message is displayed for screen reader users only to announce the exact amount of issues when a new search result is loaded',
	},
	screenReaderSearchResultMoreInfoIssueTermRefresh: {
		id: 'issue-navigator.screen-reader-search-result-more-info-issue-term-refresh',
		defaultMessage:
			'{total, plural, one {{total} or more work items found} other {{total} or more work items found}}.',
		description:
			'This message is displayed for screen reader users only to announce that more than a specific amount of issues are found when a new search result is loaded',
	},
});
