import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	allIssues: {
		id: 'project-issue-navigator.page-actions.all-issues',
		defaultMessage: 'Go to all issues',
		description:
			'Text on the link to global advanced issue search - being renamed from "Go to advanced search".',
	},
	exportIssuesButtonIconLabel: {
		id: 'project-issue-navigator.page-actions.export-issues-button-icon-label',
		defaultMessage: 'Open export issues dropdown',
		description: 'The label text for the export issues button icon',
	},
	allIssuesIssueTermRefresh: {
		id: 'project-issue-navigator.page-actions.all-issues-issue-term-refresh',
		defaultMessage: 'Go to all work items',
		description:
			'Text on the link to global advanced work item search - being renamed from "Go to advanced search".',
	},
	exportIssuesButtonIconLabelIssueTermRefresh: {
		id: 'project-issue-navigator.page-actions.export-issues-button-icon-label-issue-term-refresh',
		defaultMessage: 'Open export work items dropdown',
		description: 'The label text for the export work items button icon',
	},
});

export default messages;
