import noop from 'lodash/noop';
import uuid from 'uuid';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
import { createStore, createContainer } from '@atlassian/react-sweet-state';
import { actions, onContainerUpdate, onSearchInputUpdate } from './actions.tsx';
import type { State, Actions, Props } from './types.tsx';

const initialState: State = {
	onLoadNext: noop,
	onLoadPrevious: noop,
	isLoadingNext: false,
	isLoadingPrevious: false,
	hasNext: false,
	hasPrevious: false,
	disposables: [],
	isFetching: false,
	isFetchingView: false,
	isRefreshing: false,
	isNetworkError: false,
	searchKey: '',
	totalIssueCount: null,
	totalIssueCountIsFetching: false,
	totalIssueCountIsError: false,
};

export const IssueSearchServiceContainer = createContainer<Props>();

export const Store = createStore<State, Actions, Props>({
	initialState,
	actions,
	containedBy: IssueSearchServiceContainer,
	handlers: {
		onInit:
			() =>
			({ dispatch, setState }) => {
				dispatch(onContainerUpdate());
				setState({
					searchKey: uuid(),
				});
			},
		onContainerUpdate:
			(nextProps, prevProps) =>
			({ dispatch }) => {
				dispatch(onContainerUpdate());

				const hasIssueSearchInputChanged =
					!isShallowEqual(nextProps.issueSearchInput, prevProps.issueSearchInput) ||
					nextProps.isHierarchyEnabled !== prevProps.isHierarchyEnabled ||
					nextProps.groupedByField !== prevProps.groupedByField;

				if (hasIssueSearchInputChanged) {
					dispatch(onSearchInputUpdate());
				}
			},
		onDestroy:
			() =>
			({ getState }) => {
				const { disposables } = getState();
				// Dispose of any manually retained operations when the container is unmounted
				disposables.forEach((disposable) => disposable.dispose());
			},
	},
});
