/** @jsx jsx */
import React, { type ReactElement } from 'react';
import { css, jsx } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, xcss, Stack, Text } from '@atlaskit/primitives';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NoOptionComponentType } from '@atlassian/jira-jql-builder-basic/src/common/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactElement;
};

const IconContainer = ({ children }: Props) => {
	if (isVisualRefreshEnabled()) {
		return children;
	}
	return <div css={iconContainerStyles}>{children}</div>;
};

const MessageContainer = ({ children }: Props) => {
	const { formatMessage } = useIntl();

	if (isVisualRefreshEnabled()) {
		return (
			<Stack space="space.150" xcss={stackStyles} alignInline="center">
				{children}
				<Text color="color.text">{formatMessage(messages.noOptionsPrimaryMessage)}</Text>
				<Text size="small" color="color.text.subtle">
					{formatMessage(messages.noOptionsSecondaryMessage)}
				</Text>
			</Stack>
		);
	}
	return (
		<Box xcss={boxStyles}>
			{children}
			<p css={titleStyles}>{formatMessage(messages.fieldNotFoundMessage)}</p>
		</Box>
	);
};

export const NoOptionsMessage: NoOptionComponentType = () => {
	return (
		<MessageContainer>
			<IconContainer>
				<ErrorIcon
					label=""
					color={token('color.icon')}
					LEGACY_primaryColor={token('color.icon.subtle', N200)}
				/>
			</IconContainer>
		</MessageContainer>
	);
};

const boxStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	paddingBottom: 'space.100',
	color: 'color.text.subtle',
	textAlign: 'center',
});

const stackStyles = xcss({
	paddingBlock: 'space.150',
	paddingInline: 'space.100',
});

const titleStyles = css({
	margin: `0 0 ${token('space.100', '8px')}`,
	overflowWrap: 'anywhere',
});

const iconContainerStyles = css({
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '72px',
		height: '72px',
	},
});
