/**
 * @generated SignedSource<<b1ac4ee09aa06d0c162ef3bdadd8c445>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_filter$data = {
  readonly name: string;
  readonly " $fragmentType": "main_issueNavigator_ListView_filter";
};
export type main_issueNavigator_ListView_filter$key = {
  readonly " $data"?: main_issueNavigator_ListView_filter$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_filter">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_filter",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ],
  "type": "JiraFilter",
  "abstractKey": "__isJiraFilter"
};

(node as any).hash = "42fd583d4b19e59270b0aa45271f2d67";

export default node;
