import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	dialogTitleFilter: {
		id: 'issue-navigator-action-share-button.dialog-title-filter',
		defaultMessage: 'Share current filter',
		description: 'Title of the share dialog when sharing a saved filter',
	},
	dialogTitleSearch: {
		id: 'issue-navigator-action-share-button.dialog-title-search',
		defaultMessage: 'Share search criteria',
		description: 'Title of the share dialog when sharing a JQL search query',
	},
	filter: {
		id: 'issue-navigator-action-share-button.filter',
		defaultMessage: 'Filter',
		description: 'Name of shared entity for Filters (noun)',
	},
	search: {
		id: 'issue-navigator-action-share-button.search',
		defaultMessage: 'Search',
		description: 'Name of shared entity for JQL searches (noun)',
	},
	share: {
		id: 'issue-navigator-action-share-button.share',
		defaultMessage: 'Share',
		description: 'Text for share button',
	},
	shareKeyboardShortcut: {
		id: 'issue-navigator-action-share-button.share-keyboard-shortcut',
		defaultMessage: 'Open share modal',
		description: 'Keyboard shortcut for share button',
	},
});
