import { useEffect } from 'react';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';
import { useExperienceAnalytics } from '../../services/experience-analytics/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIsFetching, useIsNetworkError } from '../../services/issue-search/selectors.tsx';
import { useSelectedViewState } from '../selected-view-state/index.tsx';

type BaseExperienceProps = {
	/**
	 * Analytics attributes to be emitted as part of success/fail experience events.
	 */
	attributes?: ExperienceAttributes;
	/**
	 * Event emitted when page data has loaded and the key experience is interactive.
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
};

/**
 * Component to declaratively mark the issue search as successful when rendering search results to the user.
 */
export const IssueSearchSuccess = ({ attributes, onPageDataLoad }: BaseExperienceProps) => {
	const [{ view }] = useSelectedViewState();
	const { onIssueSearchSuccess } = useExperienceAnalytics(onPageDataLoad, view, attributes);
	let isFetching;
	let isNetworkError;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFetching = useIsFetching();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isNetworkError = useIsNetworkError();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isFetching, isNetworkError } = useIssueSearchQuery());
	}
	const prevIsFetching = usePrevious(isFetching);

	useEffect(() => {
		if (prevIsFetching !== isFetching && !isFetching && !isNetworkError) {
			onIssueSearchSuccess(view);
		}
	}, [isFetching, isNetworkError, onIssueSearchSuccess, prevIsFetching, view]);

	return null;
};

type FailExperienceProps = BaseExperienceProps & {
	/**
	 * Location the failure originated from.
	 */
	location: string;
	/**
	 * Error associated with the failure.
	 */
	error: Error;
};

/**
 * Component to declaratively mark the issue search as failed when rendering the corresponding error UI to the user.
 */
export const IssueSearchFail = ({
	attributes,
	location,
	error,
	onPageDataLoad,
}: FailExperienceProps) => {
	const [{ view }] = useSelectedViewState();
	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, view, attributes);
	let isFetching;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFetching = useIsFetching();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isFetching } = useIssueSearchQuery());
	}
	const prevIsFetching = usePrevious(isFetching);

	useEffect(() => {
		if (prevIsFetching !== isFetching && !isFetching) {
			onIssueSearchFail(location, error, view);
		}
	}, [error, isFetching, location, onIssueSearchFail, prevIsFetching, view]);

	return null;
};
