import React, { type PropsWithChildren, useMemo, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';
import Tabs, { TabList, useTabPanel } from '@atlaskit/tabs';
import type { SelectedType } from '@atlaskit/tabs/types';
import { useIntl } from '@atlassian/jira-intl';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import type { IssueNavigatorViewId } from '../../../../../common/types.tsx';
import { convertFilterIdToIssueNavigatorId } from '../../../../../common/utils/index.tsx';
import { useSelectedViewState } from '../../../../../controllers/selected-view-state/index.tsx';
import { useActiveJql } from '../../../../../services/active-jql/index.tsx';
import messages from './messages.tsx';
import Tab from './tab/index.tsx';

const CustomTabPanel = ({ children }: PropsWithChildren) => {
	/**
	 * We can omit the tab index as our panel always contains a focusable input as the first element https://www.w3.org/WAI/ARIA/apg/example-index/tabs/tabs-automatic#accessibilityfeatures.
	 * > It is recommended that all tabpanel elements in a tab set are focusable if there are any panels in the set that
	 * > contain content where the first element in the panel is not focusable.
	 */
	const { tabIndex, ...tabPanelAttributes } = useTabPanel();
	return <div {...tabPanelAttributes}>{children}</div>;
};

type ViewIdTabMetadata = {
	key: string;
	label: string;
	view: IssueNavigatorViewId;
};

const ColumnPickerTabs = ({ children }: PropsWithChildren) => {
	const { formatMessage } = useIntl();
	const { filterId } = useActiveJql();
	const isAdmin = useIsAdmin();
	const [{ view }, { setView }] = useSelectedViewState();
	const isFilterTabEnabled = filterId != null && isCustomFilter(filterId);

	const tabsMetaData: ViewIdTabMetadata[] = useMemo(() => {
		const resultTabs: ViewIdTabMetadata[] = [
			{
				key: 'Default',
				label: formatMessage(messages.pickerTabTextDefault),
				view: 'list_default',
			},
		];

		if (isFilterTabEnabled) {
			const filterViewId = convertFilterIdToIssueNavigatorId(filterId);
			if (filterViewId != null) {
				resultTabs.push({
					key: 'Filter',
					label: formatMessage(messages.pickerTabTextFilter),
					view: filterViewId,
				});
			}
		}

		if (isAdmin) {
			resultTabs.push({
				key: 'System',
				label: formatMessage(messages.pickerTabTextSystem),
				view: 'list_system',
			});
		}
		return resultTabs;
	}, [formatMessage, isFilterTabEnabled, filterId, isAdmin]);

	const handleUpdate = useCallback(
		(index: SelectedType, event: UIAnalyticsEvent) => {
			if (view !== tabsMetaData[index].view) {
				fireUIAnalytics(event, 'columnPickerTabs', {
					tab: tabsMetaData[index].view,
				});
				setView(tabsMetaData[index].view, toIssueKey(''));
			}
		},
		[setView, tabsMetaData, view],
	);

	return (
		<Tabs
			id="issue-table-column-picker.ui.column-picker.picker-tabs"
			onChange={handleUpdate}
			selected={tabsMetaData.findIndex((tab) => tab.view === view)}
			shouldUnmountTabPanelOnChange
		>
			<Box paddingInline="space.100">
				<TabList>
					{tabsMetaData.map((tab) => (
						<Tab
							key={tab.key}
							label={tab.label}
							maxWidth={`${Math.floor(100 / (tabsMetaData.length || 1))}%`}
						/>
					))}
				</TabList>
			</Box>
			{tabsMetaData.map((tab) => (
				<CustomTabPanel key={tab.key}>{children}</CustomTabPanel>
			))}
		</Tabs>
	);
};

export default ColumnPickerTabs;
