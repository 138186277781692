import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'jql-builder-common.error-boundry-fallback.error-message',
		defaultMessage:
			"We couldn't load your search criteria. <link>Refresh the page</link> to try again.",
		description:
			"This text is displayed in a section message when we are unable to load the user's search criteria. The <link> and </link> tags enclose a link that will trigger a page refresh, and these tags should be kept in the translated message surrounding the corresponding translated text.",
	},
});
