/**
 * @generated SignedSource<<b0f81e67c50a7e9d44fd5052fba5f4fb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type hierarchyToggle_issueNavigator_HierarchyToggleItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHierarchyToggle_HierarchyToggle">;
  readonly " $fragmentType": "hierarchyToggle_issueNavigator_HierarchyToggleItem";
};
export type hierarchyToggle_issueNavigator_HierarchyToggleItem$key = {
  readonly " $data"?: hierarchyToggle_issueNavigator_HierarchyToggleItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "staticViewInput",
              "variableName": "staticViewInput"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ui_issueTableHierarchyToggle_HierarchyToggle"
        }
      ]
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "e0155f59c7e10da0ab42123f84422439";

export default node;
