import React, { type ComponentType, type ErrorInfo, Component } from 'react';

type FallbackErrorProps = {
	error: Error;
	errorInfo: ErrorInfo;
};

type Options<WrappedProps> = {
	recoveryProp: keyof WrappedProps;
	Fallback?: ComponentType<WrappedProps & FallbackErrorProps>;
};

type State = {
	errorProps: FallbackErrorProps | null;
};

/**
 * Error boundary that will show the provided Fallback on error and attempt to re-render the happy path when the
 * provided recoveryProp has changed.
 * A common use case for this is when exporting Relay components that leverage the `@required` directive. If required
 * data is missing then the fallback state can be shown until the reference data has changed.
 */
export function withRecoverableErrorBoundary<WrappedProps>(
	WrappedComponent: ComponentType<WrappedProps>,
	{ recoveryProp, Fallback }: Options<WrappedProps>,
): ComponentType<WrappedProps> {
	class RecoverableErrorBoundary extends Component<WrappedProps, State> {
		state: State = {
			errorProps: null,
		};

		componentDidUpdate(prevProps: WrappedProps) {
			if (this.state.errorProps && prevProps[recoveryProp] !== this.props[recoveryProp]) {
				this.setState({
					errorProps: null,
				});
			}
		}

		componentDidCatch(error: Error, errorInfo: ErrorInfo) {
			this.setState({
				errorProps: {
					error,
					errorInfo,
				},
			});
		}

		render() {
			if (this.state.errorProps) {
				return Fallback ? <Fallback {...this.props} {...this.state.errorProps} /> : null;
			}
			return <WrappedComponent {...this.props} />;
		}
	}

	return RecoverableErrorBoundary;
}
