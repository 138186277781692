import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadStartA11YMessageTop: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.load-start-a-11-y-message-top',
		defaultMessage: 'Loading more issues at the top of the list.',
		description: 'Screen reader announcement for when loading starts at the top of the list',
	},
	loadEndA11YMessageTop: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.load-end-a-11-y-message-top',
		defaultMessage: 'Finished loading more issues at the top of the list.',
		description: 'Screen reader announcement for when loading has finished at the top of the list',
	},
	loadStartA11YMessageBottom: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.load-start-a-11-y-message-bottom',
		defaultMessage: 'Loading more issues at the bottom of the list.',
		description: 'Screen reader announcement for when loading starts at the bottom of the list',
	},
	loadEndA11YMessageBottom: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.load-end-a-11-y-message-bottom',
		defaultMessage: 'Finished loading more issues at the bottom of the list.',
		description:
			'Screen reader announcement for when loading has finished at the bottom of the list',
	},
});
