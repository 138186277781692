import { useCallback, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { IssueNavigatorIssueSearchPaginationQuery$variables } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchPaginationQuery.graphql';
import type { IssueNavigatorIssueSearchRefetchQuery$variables } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type {
	JiraIssueSearchInput,
	main_IssueNavigatorQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';

type ShadowRequestVariables =
	| {
			cloudId: string;
			issueSearchInput: JiraIssueSearchInput;
			viewId: string;
	  }
	| IssueNavigatorIssueSearchRefetchQuery$variables
	| IssueNavigatorIssueSearchPaginationQuery$variables
	| main_IssueNavigatorQuery$variables;

export const fetchShadowRequest = async (
	variables: ShadowRequestVariables,
	query: string,
	operationName: string,
) => {
	const finalVariables = {
		options: {},
		namespace: 'ISSUE_NAVIGATOR',
		amountOfColumns: 500,
		isRefactorNinToViewSchemaEnabled: false,
		__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: true,
		__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: false,
		__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider:
			true,
		__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider:
			false,
		__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: 1,
		__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: true,
		__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: true,
		__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: true,
		__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider:
			true,
		...variables,
	};

	try {
		await fetchJson('/gateway/api/graphql', {
			method: 'POST',
			headers: {
				'X-ExperimentalApi': 'JiraIssueSearch, JiraProject',
			},
			body: JSON.stringify({
				operationName,
				query,
				variables: finalVariables,
			}),
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			attributes: { message: `Shadow request for nin issue search ${operationName} failed` },
			meta: {
				id: 'shadow-request-for-nin-issue-search',
				packageName: 'jiraIssueNavigator',
				teamName: 'empanada',
			},
		});
	}
};

const getIssueNavigatorIssueSearchRefetchQueryShadowOperation = () => {
	const operation = import(
		/* webpackChunkName: "async-shadow-issue-search-refetch-query" */ '@atlassian/jira-relay/./src/common/mocks/IssueNavigatorIssueSearchRefetchQuery.json'
	).then((module) => ({
		operationName: 'IssueNavigatorIssueSearchRefetchQueryShadow',
		operation: module.operation.replace(
			'IssueNavigatorIssueSearchRefetchQuery',
			'IssueNavigatorIssueSearchRefetchQueryShadow',
		),
	}));
	return operation;
};

const getIssueNavigatorIssueSearchPaginationQueryShadowOperation = () => {
	const operation = import(
		/* webpackChunkName: "async-shadow-issue-search-pagination-query" */ '@atlassian/jira-relay/./src/common/mocks/IssueNavigatorIssueSearchPaginationQuery.json'
	).then((module) => ({
		operationName: 'IssueNavigatorIssueSearchPaginationQueryShadow',
		operation: module.operation.replace(
			'IssueNavigatorIssueSearchPaginationQuery',
			'IssueNavigatorIssueSearchPaginationQueryShadow',
		),
	}));
	return operation;
};

const getMainIssueNavigatorQueryShadowOperation = () => {
	const operation = import(
		/* webpackChunkName: "async-shadow-main-issue-navigator-query" */ '@atlassian/jira-relay/./src/common/mocks/main_IssueNavigatorQuery.json'
	).then((module) => ({
		operationName: 'main_IssueNavigatorQueryShadow',
		operation: module.operation.replace(
			'main_IssueNavigatorQuery',
			'main_IssueNavigatorQueryShadow',
		),
	}));
	return operation;
};

export const queueShadowRequest = (
	getOperation: () => Promise<{
		operationName: string;
		operation: string;
	}>,
	variables: ShadowRequestVariables,
) => {
	!__SERVER__
		? requestIdleCallback(() =>
				setTimeout(
					() =>
						requestIdleCallback(async () => {
							const query = await getOperation();
							fetchShadowRequest(variables, query.operation, query.operationName);
						}),
					5000,
				),
			)
		: null;
};

export const fetchIssueNavigatorIssueSearchRefetchQueryShadow = (
	variables: IssueNavigatorIssueSearchRefetchQuery$variables,
) => {
	queueShadowRequest(getIssueNavigatorIssueSearchRefetchQueryShadowOperation, variables);
};

export const useMainIssueNavigatorQueryShadowRequest = (
	variables: main_IssueNavigatorQuery$variables,
	viewId: string,
) => {
	useEffect(() => {
		if (
			variables.projectKey &&
			viewId !== 'detail' &&
			fg('jira_list_-_hierarchy_-_shadow_request')
		) {
			queueShadowRequest(getMainIssueNavigatorQueryShadowOperation, variables);
		}
		// We only want to fetch the main query once, so we don't need to add any dependencies here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const useIssueSearchPaginationShadowRequest = () => {
	return useCallback(
		(variables: IssueNavigatorIssueSearchPaginationQuery$variables) =>
			queueShadowRequest(getIssueNavigatorIssueSearchPaginationQueryShadowOperation, variables),
		[],
	);
};
