/**
 * @generated SignedSource<<f74f5a7a60d6fef1d0f532f3faf89389>>
 * @relayHash f44002b5cd6f1cc5484e3288af21658e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4379af99f13a687b7bfdfa8491903c609d6bb3c5de633c9f68128c913c8fc05d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type refetchIssue_IssueNavigatorQuery$variables = {
  amountOfColumns: number;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  issueSearchInput: JiraIssueSearchInput;
};
export type refetchIssue_IssueNavigatorQuery$data = {
  readonly jira: {
    readonly issueSearchStable: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fieldSetsById: {
            readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets" | "main_issueNavigator_Card_fieldSets">;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type refetchIssue_IssueNavigatorQuery = {
  response: refetchIssue_IssueNavigatorQuery$data;
  variables: refetchIssue_IssueNavigatorQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "amountOfColumns"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "type"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "name"
},
v10 = [
  (v9/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v12 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "name": "key"
},
v14 = {
  "kind": "ScalarField",
  "name": "text"
},
v15 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v16 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v8/*: any*/)
  ]
},
v17 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v18 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "refetchIssue_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v5/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsById",
                        "plural": false,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "isDensityFull",
                                "value": false
                              },
                              {
                                "kind": "Literal",
                                "name": "isInlineEditingEnabled",
                                "value": true
                              }
                            ],
                            "kind": "FragmentSpread",
                            "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "main_issueNavigator_Card_fieldSets"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "refetchIssue_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v5/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsById",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "fieldSetId"
                                  },
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v7/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "fieldId"
                                              },
                                              (v6/*: any*/),
                                              {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isJiraIssueField"
                                              },
                                              (v8/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAffectedServiceConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedAffectedServicesConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAffectedServiceEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraAffectedService",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "serviceId"
                                                              },
                                                              (v9/*: any*/),
                                                              (v8/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraAffectedServicesField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbObjectConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedCmdbObjectsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraCmdbObjectEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbObject",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "objectId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "label"
                                                              },
                                                              {
                                                                "concreteType": "JiraCmdbAvatar",
                                                                "kind": "LinkedField",
                                                                "name": "avatar",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                                                    "kind": "Condition",
                                                                    "passingValue": true,
                                                                    "selections": [
                                                                      {
                                                                        "concreteType": "JiraCmdbMediaClientConfig",
                                                                        "kind": "LinkedField",
                                                                        "name": "mediaClientConfig",
                                                                        "plural": false,
                                                                        "selections": [
                                                                          {
                                                                            "kind": "ScalarField",
                                                                            "name": "clientId"
                                                                          },
                                                                          {
                                                                            "kind": "ScalarField",
                                                                            "name": "fileId"
                                                                          },
                                                                          {
                                                                            "kind": "ScalarField",
                                                                            "name": "mediaBaseUrl"
                                                                          },
                                                                          {
                                                                            "kind": "ScalarField",
                                                                            "name": "mediaJwtToken"
                                                                          }
                                                                        ]
                                                                      }
                                                                    ]
                                                                  },
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "url48"
                                                                  }
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "QueryError",
                                                        "kind": "LinkedField",
                                                        "name": "errors",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "message"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraCMDBField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedComponentsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponentEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraComponent",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v10/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraComponentsField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "date"
                                                  },
                                                  (v9/*: any*/),
                                                  (v11/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraDatePickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "dateTime"
                                                  }
                                                ],
                                                "type": "JiraDateTimePickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraEpic",
                                                    "kind": "LinkedField",
                                                    "name": "epic",
                                                    "plural": false,
                                                    "selections": [
                                                      (v13/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "summary"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "color"
                                                      },
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraEpicLinkField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "renderedFieldHtml"
                                                  }
                                                ],
                                                "type": "JiraFallbackField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v14/*: any*/),
                                                  (v9/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraStatusCategory",
                                                    "kind": "LinkedField",
                                                    "name": "statusCategory",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v15/*: any*/),
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraStatus",
                                                    "kind": "LinkedField",
                                                    "name": "status",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "statusId"
                                                      },
                                                      (v16/*: any*/),
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraStatusField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "args": [
                                                      {
                                                        "kind": "Literal",
                                                        "name": "first",
                                                        "value": 1000
                                                      }
                                                    ],
                                                    "concreteType": "JiraLabelConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedLabelsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraLabelEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraLabel",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v9/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      (v17/*: any*/)
                                                    ],
                                                    "storageKey": "selectedLabelsConnection(first:1000)"
                                                  },
                                                  (v9/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraLabelsField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "small"
                                                          }
                                                        ]
                                                      },
                                                      (v9/*: any*/),
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "parentIssue",
                                                    "plural": false,
                                                    "selections": [
                                                      (v13/*: any*/),
                                                      {
                                                        "concreteType": "JiraSingleLineTextField",
                                                        "kind": "LinkedField",
                                                        "name": "summaryField",
                                                        "plural": false,
                                                        "selections": [
                                                          (v14/*: any*/),
                                                          (v8/*: any*/)
                                                        ]
                                                      },
                                                      (v8/*: any*/),
                                                      {
                                                        "concreteType": "JiraColorField",
                                                        "kind": "LinkedField",
                                                        "name": "issueColorField",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraColor",
                                                            "kind": "LinkedField",
                                                            "name": "color",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "colorKey"
                                                              },
                                                              (v8/*: any*/)
                                                            ]
                                                          },
                                                          (v8/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "JiraIssueTypeField",
                                                        "kind": "LinkedField",
                                                        "name": "issueTypeField",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssueType",
                                                            "kind": "LinkedField",
                                                            "name": "issueType",
                                                            "plural": false,
                                                            "selections": [
                                                              (v18/*: any*/),
                                                              (v9/*: any*/),
                                                              (v8/*: any*/)
                                                            ]
                                                          },
                                                          (v8/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraParentIssueField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraPriority",
                                                    "kind": "LinkedField",
                                                    "name": "priority",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "iconUrl"
                                                      },
                                                      (v8/*: any*/)
                                                    ]
                                                  },
                                                  (v9/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraPriorityField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraProject",
                                                    "kind": "LinkedField",
                                                    "name": "project",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v13/*: any*/),
                                                      {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "large"
                                                          }
                                                        ]
                                                      },
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraProjectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraResolution",
                                                    "kind": "LinkedField",
                                                    "name": "resolution",
                                                    "plural": false,
                                                    "selections": (v10/*: any*/)
                                                  }
                                                ],
                                                "type": "JiraResolutionField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprintConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedSprintsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraSprintEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraSprint",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v8/*: any*/),
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "sprintId"
                                                              },
                                                              (v9/*: any*/),
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "state"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "endDate"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v9/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraSprintField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v7/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "accountId"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "picture"
                                                      },
                                                      (v9/*: any*/),
                                                      (v8/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "accountStatus"
                                                      }
                                                    ]
                                                  },
                                                  (v9/*: any*/),
                                                  (v11/*: any*/),
                                                  (v12/*: any*/)
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v9/*: any*/),
                                                  {
                                                    "concreteType": "JiraVersionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedVersionsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersionEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraVersion",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v10/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraMultipleVersionPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraRichText",
                                                    "kind": "LinkedField",
                                                    "name": "richText",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraADF",
                                                        "kind": "LinkedField",
                                                        "name": "adfValue",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraAdfToConvertedPlainText",
                                                            "kind": "LinkedField",
                                                            "name": "convertedPlainText",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "plainText"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraRichTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraServiceManagementRequestType",
                                                    "kind": "LinkedField",
                                                    "name": "requestType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v18/*: any*/),
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraServiceManagementRequestTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "args": [
                                                      {
                                                        "kind": "Variable",
                                                        "name": "first",
                                                        "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                                                      }
                                                    ],
                                                    "concreteType": "JiraIssueLinkConnection",
                                                    "kind": "LinkedField",
                                                    "name": "issueLinkConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "PageInfo",
                                                            "kind": "LinkedField",
                                                            "name": "pageInfo",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "hasNextPage"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                          (v17/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "JiraIssueLinkEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssueLink",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssue",
                                                                "kind": "LinkedField",
                                                                "name": "issue",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "webUrl"
                                                                  },
                                                                  (v13/*: any*/),
                                                                  {
                                                                    "args": [
                                                                      {
                                                                        "kind": "Literal",
                                                                        "name": "ids",
                                                                        "value": [
                                                                          "statusCategory"
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "concreteType": "JiraIssueFieldConnection",
                                                                    "kind": "LinkedField",
                                                                    "name": "fieldsById",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      {
                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                          {
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                              (v7/*: any*/),
                                                                              {
                                                                                "kind": "InlineFragment",
                                                                                "selections": [
                                                                                  (v16/*: any*/)
                                                                                ],
                                                                                "type": "JiraStatusCategoryField"
                                                                              },
                                                                              (v8/*: any*/)
                                                                            ]
                                                                          }
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                                  },
                                                                  (v8/*: any*/)
                                                                ]
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "relationName"
                                                              },
                                                              (v8/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueLinkField"
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "isStoryPointField"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "number"
                                                      },
                                                      (v9/*: any*/),
                                                      (v12/*: any*/)
                                                    ],
                                                    "type": "JiraNumberField"
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4379af99f13a687b7bfdfa8491903c609d6bb3c5de633c9f68128c913c8fc05d",
    "metadata": {},
    "name": "refetchIssue_IssueNavigatorQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
    }
  }
};
})();

(node as any).hash = "c4758e8f0c69bf48cf3f00e006767f21";

export default node;
