/**
 * @generated SignedSource<<1668fd2749e911aa06a21f56a086cd8d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_project">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_project";
};
export type main_issueNavigator_IssueResults_project$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "933876bf3e3ea405c912812993974510";

export default node;
