import React, { useCallback, type ReactNode, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { NoColumnsProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { components } from '@atlassian/jira-native-issue-table/src/common/ui/components/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useSelectedViewState } from '../../../../controllers/selected-view-state/index.tsx';
import messages from './messages.tsx';

const Bold = ({ children }: { children: ReactNode }) => <b>{children}</b>;

const NoColumnsAnonymous = ({ defaultNoColumnsGlyph }: { defaultNoColumnsGlyph: JSX.Element }) => {
	const { formatMessage } = useIntl();
	const [, { setView }] = useSelectedViewState();

	const onClick = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'button clicked', 'noColumnsButton', {
				isAnon: true,
			});
			setView('detail', toIssueKey(''));
		},
		[setView],
	);

	const component = (
		<EmptyState
			header={formatMessage(messages.noColumnsHeaderAnonymous)}
			description={
				<FormattedI18nMessage
					componentsMapping={{ bold: Bold }}
					message={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.noColumnsDescriptionAnonymousIssueTermRefresh
							: messages.noColumnsDescriptionAnonymous,
						{
							boldStart: '{boldStart}',
							boldEnd: '{boldEnd}',
						},
					)}
				/>
			}
			testId="issue-navigator.ui.issue-results.list-view.no-columns.no-columns-anonymous"
			primaryAction={
				<Button appearance="primary" onClick={onClick}>
					{formatMessage(messages.switchView)}
				</Button>
			}
			renderImage={() => defaultNoColumnsGlyph}
		/>
	);
	return <Box paddingBlock="space.800">{component}</Box>;
};

export const NoColumns = ({ defaultNoColumnsGlyph, hasColumnConfiguration }: NoColumnsProps) => {
	const isAnonymous = useIsAnonymous();
	return isAnonymous ? (
		<NoColumnsAnonymous defaultNoColumnsGlyph={defaultNoColumnsGlyph} />
	) : (
		<components.NoColumns
			defaultNoColumnsGlyph={defaultNoColumnsGlyph}
			hasColumnConfiguration={hasColumnConfiguration}
		/>
	);
};
