/**
 * @generated SignedSource<<deecead4abb573c79ccc20706f28315d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topBar_issueNavigator_viewResult$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem">;
  readonly " $fragmentType": "topBar_issueNavigator_viewResult";
};
export type topBar_issueNavigator_viewResult$key = {
  readonly " $data"?: topBar_issueNavigator_viewResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"topBar_issueNavigator_viewResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "topBar_issueNavigator_viewResult",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "20db0d16b877c5d4e262bbf4f02a1907";

export default node;
