/**
 * @generated SignedSource<<a76e30faae7fd3f790cc395215820217>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$data = {
  readonly isEditable: boolean | null | undefined;
  readonly " $fragmentType": "jqlBuilder_issueNavigator_JQLBuilderWrapper_filter";
};
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$key = {
  readonly " $data"?: jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$data;
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_filter">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_filter",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "46b543f50c73d7423957a8ecbe71b627";

export default node;
