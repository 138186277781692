/**
 * @generated SignedSource<<222e07e55baf5ff3dcc37bb6cbd7a80e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topBar_issueNavigator_issueResults$data = {
  readonly issueNavigatorPageInfo: {
    readonly firstIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly last: {
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentType": "topBar_issueNavigator_issueResults";
};
export type topBar_issueNavigator_issueResults$key = {
  readonly " $data"?: topBar_issueNavigator_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"topBar_issueNavigator_issueResults">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "topBar_issueNavigator_issueResults",
  "selections": [
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "last",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ],
      "storageKey": "pageCursors(maxCursors:7)"
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    }
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "52b4c743e436bc90ea0831559f2c3e48";

export default node;
