/**
 * @generated SignedSource<<0bc51f9494de5d6fa2940f677e3da0a2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerActions_issueNavigator$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issuePagination_issueNavigator">;
  readonly " $fragmentType": "headerActions_issueNavigator";
};
export type headerActions_issueNavigator$key = {
  readonly " $data"?: headerActions_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "headerActions_issueNavigator",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "issuePagination_issueNavigator"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "701bdde8a1a96783e68d573053dd8362";

export default node;
