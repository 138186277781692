import { useCallback, useState, useMemo } from 'react';
import { useRelayEnvironment, fetchQuery, graphql } from 'react-relay';
import type {
	issueSearchTotalCountQuery$variables,
	issueSearchTotalCountQuery as IssueSearchTotalCountQuery,
} from '@atlassian/jira-relay/src/__generated__/issueSearchTotalCountQuery.graphql';

export type UncappedTotalIssueCountFetcher = () => void;
export type UncappedTotalIssueCount = {
	count: number | undefined | null;
	isFetching: boolean;
	isError: boolean;
};
type UseIssueSearchTotalCountHook = {
	resetTotalIssueCount: () => void;
	uncappedTotalIssueCount: UncappedTotalIssueCount;
	onFetchUncappedTotalIssueCount: UncappedTotalIssueCountFetcher;
};

export const uncappedTotalInitialState: UncappedTotalIssueCount = {
	count: undefined,
	isFetching: false,
	isError: false,
};

// TODO Remove service when cleaning up jira_spreadsheet_component_m1
export const useIssueSearchTotalCount = ({
	cloudId,
	issueSearchInput,
}: issueSearchTotalCountQuery$variables): UseIssueSearchTotalCountHook => {
	const environment = useRelayEnvironment();

	const [uncappedTotalIssueCount, setUncappedTotalIssueCount] = useState<UncappedTotalIssueCount>({
		...uncappedTotalInitialState,
	});

	const resetTotalIssueCount = useCallback(() => {
		setUncappedTotalIssueCount({ ...uncappedTotalInitialState });
	}, []);

	const onFetchUncappedTotalIssueCount: UncappedTotalIssueCountFetcher = useCallback(() => {
		setUncappedTotalIssueCount({
			count: undefined,
			isFetching: true,
			isError: false,
		});

		fetchQuery<IssueSearchTotalCountQuery>(
			environment,
			graphql`
				query issueSearchTotalCountQuery($cloudId: ID!, $issueSearchInput: JiraIssueSearchInput!) {
					jira {
						issueSearchTotalCount(cloudId: $cloudId, issueSearchInput: $issueSearchInput)
							@optIn(to: ["JiraTotalIssueCount"])
					}
				}
			`,
			{ cloudId, issueSearchInput },
			{
				fetchPolicy: 'network-only',
				networkCacheConfig: {
					metadata: { META_SLOW_ENDPOINT: true },
				},
			},
		)
			.toPromise()
			.then((data) => {
				const count = data ? data.jira?.issueSearchTotalCount : null;

				let isError = false;

				if (count === null || count === undefined) {
					isError = true;
				}

				setUncappedTotalIssueCount({
					count,
					isFetching: false,
					isError,
				});
			})
			.catch(() => {
				setUncappedTotalIssueCount({
					count: null,
					isFetching: false,
					isError: true,
				});
			});
	}, [cloudId, environment, issueSearchInput]);

	return useMemo(
		() => ({
			uncappedTotalIssueCount,
			resetTotalIssueCount,
			onFetchUncappedTotalIssueCount,
		}),
		[uncappedTotalIssueCount, resetTotalIssueCount, onFetchUncappedTotalIssueCount],
	);
};
