/**
 * @generated SignedSource<<7f25f90a764ed2af10716ab2c2dbf9a1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_view$data = {
  readonly fieldSets: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldSetId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_columns">;
  };
  readonly hasDefaultFieldSets: boolean | null | undefined;
  readonly id: string;
  readonly viewConfigSettings?: {
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string;
  readonly " $fragmentType": "main_issueNavigator_ListView_view";
};
export type main_issueNavigator_ListView_view$key = {
  readonly " $data"?: main_issueNavigator_ListView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldSkipHierarchy"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    },
    {
      "kind": "ScalarField",
      "name": "hasDefaultFieldSets"
    },
    {
      "condition": "shouldSkipHierarchy",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "staticViewInput",
                  "variableName": "staticViewInput"
                }
              ],
              "concreteType": "JiraIssueSearchViewConfigSettings",
              "kind": "LinkedField",
              "name": "viewConfigSettings",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "isHierarchyEnabled"
                },
                {
                  "kind": "ScalarField",
                  "name": "canEnableHierarchy"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "fieldSetSelectedState": "SELECTED"
            }
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          }
        ],
        "concreteType": "JiraIssueSearchFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSets",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "totalCount"
          },
          {
            "concreteType": "JiraIssueSearchFieldSetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "JiraIssueSearchFieldSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "fieldSetId"
                  }
                ]
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "ui_nativeIssueTable_NativeIssueTable_columns"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSets"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "427adad341d1a547f02a2f702e71b825";

export default node;
