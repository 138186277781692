import React, { useEffect, useCallback } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { graphql, useFragment } from 'react-relay';
import { mergeRefs } from 'use-callback-ref';
import Button from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueTableGroupByDropdown_GroupByDropdown$key } from '@atlassian/jira-relay/src/__generated__/ui_issueTableGroupByDropdown_GroupByDropdown.graphql';
import {
	GROUP_BY_DROPDOWN_EXPERIENCE,
	ANALYTICS_SOURCE,
	TEAM_NAME,
	PACKAGE_NAME,
	ONBOARDING_TARGET_NAME,
} from '../common/constants.tsx';

import { LoadingContent } from '../common/ui/loading-content/index.tsx';
import { groupByDropdownEntryPoint } from './dropdown-content/entrypoint.tsx';
import messages from './messages.tsx';

export type GroupByDropdownProps = {
	spreadsheetView: ui_issueTableGroupByDropdown_GroupByDropdown$key;
};

export const GroupByDropdown = ({ spreadsheetView }: GroupByDropdownProps) => {
	const { formatMessage } = useIntl();

	const onExperienceStart = useExperienceStart({
		experience: GROUP_BY_DROPDOWN_EXPERIENCE,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience: GROUP_BY_DROPDOWN_EXPERIENCE,
	});

	useEffect(() => {
		onExperienceStart();
	}, [onExperienceStart]);

	const data = useFragment<ui_issueTableGroupByDropdown_GroupByDropdown$key>(
		graphql`
			fragment ui_issueTableGroupByDropdown_GroupByDropdown on JiraSpreadsheetView {
				id @required(action: THROW)
				viewSettings @required(action: THROW) {
					canEnableGrouping
					groupByConfig @required(action: THROW) {
						groupByField {
							name
						}
					}
				}
			}
		`,
		spreadsheetView,
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		groupByDropdownEntryPoint,
		{ nodeId: data.id },
	);
	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			fireErrorAnalytics({
				meta: {
					id: 'issue-table-group-by-dropdown',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			onExperienceFail(PACKAGE_NAME, error);
			return (
				<Box xcss={errorFallbackStyles}>
					<Text>{formatMessage(messages.entrypointErrorContent)}</Text>
				</Box>
			);
		},
		[formatMessage, onExperienceFail],
	);

	if (!data?.viewSettings?.canEnableGrouping) {
		return (
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, onClick, ...triggerProps }) => (
					<Button
						{...triggerProps}
						ref={triggerRef}
						onClick={(
							event: React.MouseEvent<HTMLButtonElement>,
							analyticsEvent: UIAnalyticsEvent,
						) => {
							fireUIAnalytics(
								analyticsEvent,
								'button clicked',
								'issueTableGroupByDropdownDisabledTrigger',
							);
							onClick && onClick(event);
						}}
					>
						{formatMessage(messages.groupByLabel)}
					</Button>
				)}
				placement="bottom-end"
				shouldRenderToParent
				spacing="compact"
			>
				<Box xcss={cantGroupIssuesStyles}>
					<Text>{formatMessage(messages.cantGroupIssues)}</Text>
				</Box>
			</DropdownMenu>
		);
	}

	const { groupByField } = data?.viewSettings?.groupByConfig || {};

	return (
		<SpotlightManager>
			<SpotlightTarget name={ONBOARDING_TARGET_NAME}>
				<DropdownMenu<HTMLButtonElement>
					trigger={({ triggerRef, onClick, ...triggerProps }) => (
						<Button
							{...triggerProps}
							ref={mergeRefs([triggerRef, buttonTrigger])}
							onClick={(
								event: React.MouseEvent<HTMLButtonElement>,
								analyticsEvent: UIAnalyticsEvent,
							) => {
								fireUIAnalytics(
									analyticsEvent,
									'button clicked',
									'issueTableGroupByDropdownTrigger',
								);
								onClick && onClick(event);
							}}
						>
							{groupByField?.name
								? formatMessage(messages.groupByLabelWithSelection, {
										groupByField: groupByField.name,
									})
								: formatMessage(messages.groupByLabel)}
						</Button>
					)}
					placement="bottom-end"
					shouldRenderToParent
					spacing="compact"
				>
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="async-jira-spreadsheet-group-by-dropdown"
						packageName={PACKAGE_NAME}
						teamName={TEAM_NAME}
						errorFallback={errorFallback}
						runtimeProps={{ nodeId: data.id }}
						fallback={<LoadingContent />}
					/>
				</DropdownMenu>
			</SpotlightTarget>
			<EngagementSpotlight engagementId={ONBOARDING_TARGET_NAME} />
		</SpotlightManager>
	);
};

const cantGroupIssuesStyles = xcss({
	marginInline: 'space.300',
	marginBlock: 'space.200',
	width: '250px',
});

const errorFallbackStyles = xcss({
	padding: 'space.200',
	width: '250px',
});
