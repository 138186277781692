import { useState, useMemo } from 'react';
import { getIssueNavigatorFFAndFG } from '../../../common/utils/get-issue-navigator-ff-and-fg.tsx';
import { markOnce, NINJqlBuilderAsyncLoad } from '../../../common/utils/performance-analytics.tsx';

export const useJqlBuilderInstrumentation = () => {
	const [finished, setFinished] = useState(false);

	return useMemo(
		() => ({
			startJqlBuilderMetric: () => {
				if (finished) return;
				NINJqlBuilderAsyncLoad.start();
			},
			stopJqlBuilderMetric: () => {
				setFinished(true);

				NINJqlBuilderAsyncLoad.stop({
					customData: {
						...getIssueNavigatorFFAndFG(),
					},
				});
			},
			markJqlBuilderMetric: (mark: string) => {
				if (finished) return;

				markOnce(mark);
			},
		}),
		[finished],
	);
};
