import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	feedbackTitle: {
		id: 'issue-navigator.common.feedback.feedback-title',
		defaultMessage: 'Give feedback for issue search',
		description:
			'Title of the modal dialog where users can share feedback about the issue search experience',
	},
	feedbackTitleDetails: {
		id: 'issue-navigator.common.feedback.feedback-title-details',
		defaultMessage: 'Let us know how we can improve this experience.',
		description:
			'Description of a modal dialog where users can leave feedback for the issue search experience',
	},
	feedbackTitleDetailsContactSupport: {
		id: 'issue-navigator.common.feedback.feedback-title-details-contact-support',
		defaultMessage: 'If you’re having technical issues, contact our support team.',
		description:
			'Message hinting the user to contact the support team if they are trying to report a bug from a feedback dialog',
	},
	feedbackTypePlaceholder: {
		id: 'issue-navigator.common.feedback.feedback-type-placeholder',
		defaultMessage: 'Select an option',
		description: 'Placeholder of the dropdown component where users can select a feedback category',
	},
	feedbackTitleIssueTermRefresh: {
		id: 'issue-navigator.common.feedback.feedback-title-issue-term-refresh',
		defaultMessage: 'Give feedback',
		description:
			'Title of the modal dialog where users can share feedback about the issue search experience',
	},
});
export default messages;
