// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';

// Custom icon not from Atlaskit
export const numberGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M7 4h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm.417 10.636h1.15V9.141H7.42L6 10.12v1.07l1.348-.93h.069v4.376zm1.923-3.85v.02h1.067v-.024c0-.506.365-.857.898-.857.503 0 .861.313.861.755 0 .357-.194.643-.967 1.397l-1.794 1.756v.803h3.964v-.955h-2.406v-.069l1.05-1.002c.953-.895 1.277-1.409 1.277-2.007 0-.944-.8-1.603-1.947-1.603-1.188 0-2.003.724-2.003 1.786zm5.811 1.466h.682c.617 0 .994.297.994.777 0 .469-.4.792-.975.792-.59 0-.983-.293-1.017-.757h-1.1c.05 1.035.88 1.713 2.106 1.713 1.253 0 2.159-.697 2.159-1.66 0-.724-.472-1.226-1.226-1.31v-.069c.613-.125 1.013-.62 1.013-1.257 0-.864-.811-1.481-1.939-1.481-1.2 0-1.972.655-2.01 1.695H14.9c.03-.476.385-.777.918-.777.537 0 .88.282.88.723 0 .45-.355.754-.876.754h-.67v.857z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

/*
 Most glyphs in the Atlaskit icon package are 20x20 on a 24px canvas, however there are several icons we use which do
 not match these dimensions. We've copied these icons and standardised them to fit within a 16x16 box on a 24px canvas
 to remain visually consistent. See here before refactoring this logic:
 https://hello.atlassian.net/wiki/spaces/JPLAT/pages/493419534/LDR+Copying+and+inlining+Atlaskit+icons+on+SWIFT+page

 For more information on the general process to add icons to SWIFT (including export and optimization) also see:
 https://hello.atlassian.net/wiki/spaces/~236031707/pages/547591295/Adding+Icons+to+SWIFT
 */

export const checkboxGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M4 5.994C4 4.893 4.895 4 5.994 4h12.012C19.107 4 20 4.895 20 5.994v12.012A1.995 1.995 0 0 1 18.006 20H5.994A1.995 1.995 0 0 1 4 18.006V5.994zm5.707 5.299a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L11 12.586l-1.293-1.293z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const folderGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M20 19V8h-9.154l-.503-1.258-.455-1.136C9.778 5.33 9.291 5 9.003 5H3.997C4.002 5 4 19 4 19h16zM12.2 6h7.809C21.109 6 22 6.893 22 7.992v11.016c0 1.1-.898 1.992-1.991 1.992H3.991C2.891 21 2 20.107 2 19.008V5.006C2 3.898 2.896 3 3.997 3h5.006c1.103 0 2.327.826 2.742 1.862L12.2 6z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const dateGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M8.667 6.167V7A.834.834 0 0 1 7 7V5.333a.834.834 0 0 1 1.667 0v.834zm6.666 0v-.834a.834.834 0 0 1 1.667 0V7a.834.834 0 0 1-1.667 0v-.833zm2.5 0h.005c.918 0 1.662.745 1.662 1.661v10.01c0 .918-.743 1.662-1.662 1.662H6.161A1.663 1.663 0 0 1 4.5 17.838V7.828c0-.917.743-1.661 1.662-1.661h.005V7c0 .927.746 1.667 1.666 1.667C8.76 8.667 9.5 7.92 9.5 7v-.833h5V7c0 .927.746 1.667 1.667 1.667.927 0 1.666-.747 1.666-1.667v-.833zm-10 6.667H9.5v-1.667H7.833v1.667zm0 3.334H9.5V14.5H7.833v1.668zm3.334-3.334h1.666v-1.667h-1.666v1.667zm0 3.334h1.666V14.5h-1.666v1.668zm3.333-3.334h1.667v-1.667H14.5v1.667zm0 3.334h1.667V14.5H14.5v1.668z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const dropdownGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15.708 11.723L12.779 14.678C12.566 14.893 12.288 15 12.009 15C11.73 15 11.449 14.893 11.231 14.678L8.291 11.713C7.903 11.321 7.903 10.686 8.291 10.294C8.679 9.902 9.309 9.902 9.698 10.294L12.005 12.621L14.303 10.304C14.69 9.912 15.32 9.912 15.708 10.304C16.097 10.696 16.097 11.331 15.708 11.723Z"
			fill="currentColor"
		/>
	</svg>
);

export const doubleDropdownGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM10.0543 7.95865C9.72121 7.63948 9.17412 7.63199 8.83238 7.94192C8.49064 8.25184 8.48365 8.76182 8.81678 9.08099L11.3658 11.5232C11.5389 11.689 11.7698 11.7707 11.9997 11.7675C12.2295 11.7707 12.4604 11.689 12.6335 11.5232L15.1825 9.08099C15.5156 8.76182 15.5087 8.25184 15.1669 7.94192C14.8252 7.63199 14.2781 7.63948 13.945 7.95865L11.9997 9.82242L10.0543 7.95865ZM8.83238 12.3333C9.17412 12.0234 9.72121 12.0309 10.0543 12.35L11.9997 14.2138L13.945 12.35C14.2781 12.0309 14.8252 12.0234 15.1669 12.3333C15.5087 12.6432 15.5156 13.1532 15.1825 13.4724L12.6335 15.9146C12.4604 16.0804 12.2295 16.1621 11.9997 16.1588C11.7698 16.1621 11.5389 16.0804 11.3658 15.9146L8.81678 13.4724C8.48365 13.1532 8.49064 12.6432 8.83238 12.3333Z"
			fill="currentColor"
		/>
	</svg>
);

export const labelGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M11.433 5.428l-4.207.6a2 2 0 0 0-1.697 1.698l-.601 4.207a1 1 0 0 0 .283.849l6.894 6.894a1 1 0 0 0 1.414 0l5.657-5.657a1 1 0 0 0 0-1.414L12.282 5.71a1 1 0 0 0-.849-.283zm-.647 5.858a1.667 1.667 0 1 1-2.357-2.357 1.667 1.667 0 0 1 2.357 2.357z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const paragraphGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M7 7h11a1 1 0 0 1 0 2H7a1 1 0 1 1 0-2zm0 4h11a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2zm0 4h6a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const peopleGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12.12 20.249a8.398 8.398 0 0 1-.39-.003A8.25 8.25 0 0 1 3.75 12 8.25 8.25 0 0 1 12 3.75 8.25 8.25 0 0 1 20.25 12a8.25 8.25 0 0 1-8.13 8.25v-.001zm4.463-3.762A6.396 6.396 0 0 0 18.417 12 6.424 6.424 0 0 0 12 5.583 6.424 6.424 0 0 0 5.583 12c0 1.745.7 3.33 1.834 4.487v-1.27a2.291 2.291 0 0 1 2.292-2.292h4.582a2.292 2.292 0 0 1 2.292 2.291v1.27zM12 12a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const shortTextGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M6.72 7.178l-2.93 8.627a.5.5 0 0 0 .473.661h.842a.75.75 0 0 0 .716-.53l.581-1.887h3.425l.58 1.887a.75.75 0 0 0 .717.53h.916a.5.5 0 0 0 .473-.66L9.578 7.177a1 1 0 0 0-.946-.678h-.966a1 1 0 0 0-.947.678zm1.37 1.228h.047l1.25 4.082H6.841l1.25-4.082zm10.187 1.872v-.23a.986.986 0 1 1 1.972 0v5.433a.986.986 0 0 1-1.972 0v-.217h-.08c-.36.802-1.13 1.32-2.217 1.32-1.81 0-2.952-1.479-2.952-3.834 0-2.334 1.149-3.805 2.952-3.805 1.075 0 1.858.546 2.216 1.333h.08zm-.04 2.486c0-1.347-.63-2.203-1.61-2.203-.978 0-1.58.843-1.58 2.203 0 1.368.602 2.196 1.58 2.196.988 0 1.61-.836 1.61-2.196z"
			fill="currentColor"
		/>
	</svg>
);

export const timeStampGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm.787-7.674V8.944c0-.52-.402-.944-.893-.944-.492 0-.894.425-.894.944v3.777c0 .263.104.5.269.672l2.207 2.332a.862.862 0 0 0 1.263 0 .985.985 0 0 0 0-1.336l-1.952-2.063z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const timeTrackingGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M17.476 9.067A6.552 6.552 0 0 1 19 13.263C19 16.983 15.866 20 12 20s-7-3.016-7-6.737c0-1.587.57-3.045 1.524-4.196l-.383-.402a.82.82 0 0 1 .054-1.19.9.9 0 0 1 1.237.052l.345.363a7.116 7.116 0 0 1 3.348-1.312v-.894h-.438a.43.43 0 0 1-.437-.42V4.42a.43.43 0 0 1 .438-.42h2.624a.43.43 0 0 1 .438.421v.842a.43.43 0 0 1-.438.421h-.437v.894a7.116 7.116 0 0 1 3.348 1.312l.345-.363a.9.9 0 0 1 1.237-.052.82.82 0 0 1 .054 1.19l-.383.402zM12 18.316c2.9 0 5.25-2.262 5.25-5.053 0-2.79-2.35-5.052-5.25-5.052s-5.25 2.262-5.25 5.052c0 2.79 2.35 5.053 5.25 5.053zm.645-4.562a.9.9 0 0 1-1.237-.02.82.82 0 0 1 .02-1.19l2.588-2.412a.9.9 0 0 1 1.238.02.82.82 0 0 1-.02 1.19l-2.589 2.412z"
			fill="currentColor"
		/>
	</svg>
);

export const originalTimeEstimateGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12 4c-4.412 0-8 3.588-8 8s3.588 8 8 8 8-3.588 8-8-3.588-8-8-8zm0 14.222A6.23 6.23 0 015.778 12 6.23 6.23 0 0112 5.778 6.23 6.23 0 0118.222 12 6.23 6.23 0 0112 18.222zm.889-6.59V8.448c0-.489-.4-.889-.889-.889s-.889.4-.889.89v3.555c0 .248.103.471.268.633l2.195 2.194a.891.891 0 001.257 0 .891.891 0 000-1.256l-1.942-1.943z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const arrowRightGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12.268 4.934a.919.919 0 0 0 0 1.296l4.852 4.853H4.668A.92.92 0 0 0 3.75 12a.92.92 0 0 0 .917.917H17.12l-4.856 4.855a.917.917 0 0 0 1.296 1.296l6.416-6.415c.002 0 .003-.003.006-.004a.914.914 0 0 0 .267-.65.915.915 0 0 0-.274-.653l-6.412-6.412a.915.915 0 0 0-.648-.267.915.915 0 0 0-.648.267z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const addonGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M5.928 6.917l4.81-2.846c.727-.429 1.8-.427 2.523 0l4.811 2.846c.726.43 1.261 1.382 1.261 2.238v5.69c0 .859-.537 1.81-1.26 2.238L13.26 19.93c-.726.429-1.799.427-2.522 0l-4.811-2.846c-.726-.43-1.261-1.382-1.261-2.238v-5.69c0-.859.537-1.81 1.26-2.238zm6.408-1.204c-.152-.09-.52-.09-.672 0L6.853 8.558c-.152.09-.336.416-.336.597v5.69c0 .18.183.507.336.597l4.81 2.845c.153.09.52.09.673 0l4.811-2.845c.152-.09.336-.416.336-.597v-5.69c0-.18-.183-.507-.336-.597l-4.81-2.845zM12 15.667a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334zm0-1.834a1.833 1.833 0 1 0 0-3.666 1.833 1.833 0 0 0 0 3.666z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const attachmentGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12.672 17.468c-1.402 1.37-3.68 1.37-5.08.004a3.458 3.458 0 0 1 .002-4.962l6.552-6.402a2.123 2.123 0 0 1 2.964-.003c.818.8.82 2.092.003 2.89L10.968 15a.599.599 0 0 1-.838-.008.568.568 0 0 1 0-.827l5.71-5.58a.863.863 0 0 0 0-1.24.912.912 0 0 0-1.268 0l-5.71 5.58a2.294 2.294 0 0 0 0 3.307 2.423 2.423 0 0 0 3.375.008l6.145-6.005a3.735 3.735 0 0 0-.003-5.37 3.947 3.947 0 0 0-5.502.003L6.325 11.27a5.184 5.184 0 0 0-.001 7.442c2.1 2.052 5.514 2.051 7.617-.004l6.13-5.99a.863.863 0 0 0 0-1.24.912.912 0 0 0-1.27 0l-6.129 5.99z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const bulletListGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M5.5 7.59a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.008v.152A1 1 0 0 1 6.659 8.75h-.152A1 1 0 0 1 5.5 7.743V7.59zm4.333 8.667a.999.999 0 0 1 .995-1.007h6.678c.549 0 .994.438.994 1.007v.152a.999.999 0 0 1-.994 1.008h-6.678a.993.993 0 0 1-.995-1.008v-.152zm-4.333 0a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.007v.152a1 1 0 0 1-1.008 1.008h-.152A1 1 0 0 1 5.5 16.409v-.152zm4.333-4.333a.999.999 0 0 1 .995-1.007h6.678c.549 0 .994.438.994 1.007v.152a.999.999 0 0 1-.994 1.007h-6.678a.993.993 0 0 1-.995-1.007v-.152zm-4.333 0a1 1 0 0 1 1.007-1.007h.152a1 1 0 0 1 1.008 1.007v.152a1 1 0 0 1-1.008 1.007h-.152A1 1 0 0 1 5.5 12.076v-.152zM9.833 7.59a.999.999 0 0 1 .995-1.008h6.678c.549 0 .994.439.994 1.008v.152a.999.999 0 0 1-.994 1.007h-6.678a.993.993 0 0 1-.995-1.007V7.59z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const commentGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M4.998 11.513c0-3.038 3.141-5.51 7.002-5.51 3.861 0 7.002 2.472 7.002 5.51 0 3.039-3.141 5.51-7.002 5.51-3.861 0-7.002-2.471-7.002-5.51zm14.84 7.771v-.002s-1.564-2.26-.767-3.116l-.037.02C20.261 14.902 21 13.279 21 11.513 21 7.371 16.963 4 12 4s-9 3.37-9 7.513 4.037 7.514 9 7.514c1.42 0 2.76-.285 3.957-.776 1.003 1.022 2.287 1.572 3.24 1.719l.002-.003a.524.524 0 0 0 .164.033.515.515 0 0 0 .474-.716zM7 10c0-.552.456-1 .995-1h8.01c.55 0 .995.444.995 1 0 .552-.456 1-.995 1h-8.01A.995.995 0 0 1 7 10zm0 3c0-.552.444-1 1-1h3c.552 0 1 .444 1 1 0 .552-.444 1-1 1H8c-.552 0-1-.444-1-1z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const officeBuildingGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M8.444 14.667V20H5.785C4.8 20 4 19.146 4 18.09V8.577c0-1.055.802-1.91 1.785-1.91h2.66v3.556H6.666V12h1.777v.889H6.667v1.778h1.777zm.89-8.894A1.78 1.78 0 0 1 11.119 4h7.096A1.78 1.78 0 0 1 20 5.773v12.454c0 .979-.802 1.773-1.785 1.773H9.333V5.773zM12 7.556v1.777h1.778V7.556H12zm0 2.666V12h1.778v-1.778H12zm0 2.667v1.778h1.778v-1.778H12zm1.778 2.667v2.666h1.778v-2.666h-1.778zm1.778-8v1.777h1.777V7.556h-1.777zm0 2.666V12h1.777v-1.778h-1.777zm0 2.667v1.778h1.777v-1.778h-1.777z"
			fill="currentColor"
		/>
	</svg>
);

export const peopleGroupGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12 12a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-3.228-1.667A3.333 3.333 0 0 0 9.795 12H9.5A2.501 2.501 0 0 0 7 14.497v2.469c-1.771-.147-3.333-.766-3.333-1.858V12c0-.92.74-1.667 1.666-1.667h3.439zm6.456 0h3.439c.92 0 1.666.738 1.666 1.667v3.108c0 1.092-1.562 1.711-3.333 1.858v-2.47A2.5 2.5 0 0 0 14.5 12h-.295a3.333 3.333 0 0 0 1.023-1.667zM10.306 6.63A3.332 3.332 0 0 0 8.67 9.357a2.5 2.5 0 1 1 1.636-2.728zm5.024 2.728a3.328 3.328 0 0 0-1.636-2.73 2.5 2.5 0 1 1 1.636 2.73zm-7.497 5.456c0-1.093.892-1.98 1.996-1.98h4.342a2 2 0 0 1 1.996 1.994v2.781c0 2.523-8.334 2.523-8.334 0v-2.795z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const radioGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export const shipGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M6.5 12h7.333v-1.833H6.5V12zM4.667 9.242c0-.502.417-.909.919-.909h9.162c.507 0 .919.414.919.909v4.591h-11V9.242zm.458 7.341h12.828l.919-2.75H4.795l.33 2.75zm-2.287-3.677A.79.79 0 0 1 3.643 12h16.86c.504 0 .786.382.625.865l-1.565 4.686c-.16.478-.695.866-1.205.866H4.415c-.506 0-.964-.402-1.025-.906l-.552-4.605zM6.5 7.42c0-1.015.823-1.838 1.831-1.838h1.838c1.011 0 1.831.82 1.831 1.838v2.746H6.5V7.42zm3.667 0v-.004H8.331c.005 0 .002.002.002.004v.912h1.834V7.42z"
			fill="currentColor"
		/>
	</svg>
);

export const priorityGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M7.959 15.833V8.474l-2.868 2.868a.541.541 0 1 1-.766-.766l3.79-3.789.001-.004a.544.544 0 0 1 .77.003l3.791 3.792a.543.543 0 0 1 0 .766.543.543 0 0 1-.765 0l-2.87-2.87v7.36a.543.543 0 0 1-.541.541.543.543 0 0 1-.542-.542zm7-6.666v7.359l-2.868-2.868a.541.541 0 1 0-.766.766l3.79 3.789.001.004a.544.544 0 0 0 .77-.003l3.791-3.792a.543.543 0 0 0 0-.766.543.543 0 0 0-.765 0l-2.87 2.87v-7.36a.543.543 0 0 0-.541-.541.543.543 0 0 0-.542.542z"
			fill="currentColor"
			stroke="currentColor"
			strokeWidth=".5"
			fillRule="evenodd"
		/>
	</svg>
);

export const screenGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M4 6.772C4 5.793 4.793 5 5.773 5h12.454C19.206 5 20 5.796 20 6.772v7.044c0 .979-.793 1.772-1.773 1.772H5.773A1.774 1.774 0 0 1 4 13.816V6.772zm1.778 7.052h12.444v-7.06H5.778v7.06zm8 5.294h.452c.241 0 .437.204.437.44V20H9.333v-.441c0-.244.203-.441.437-.441h.452V16.47h3.556v2.647z"
			fill="currentColor"
		/>
	</svg>
);

export const closeGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M16.244 6.244a.833.833 0 1 1 1.179 1.179l-4.412 4.41 4.412 4.411a.834.834 0 0 1 .074 1.093l-.074.086a.833.833 0 0 1-1.179 0l-4.411-4.412-4.41 4.412a.834.834 0 0 1-1.094.074l-.085-.074a.833.833 0 0 1 0-1.179l4.411-4.411-4.41-4.41a.834.834 0 0 1-.076-1.094l.075-.085a.833.833 0 0 1 1.179 0l4.41 4.411z"
			fill="currentColor"
		/>
	</svg>
);

export const appAccessGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<g fillRule="evenodd">
			<path
				d="M12.682 12H5.01A2.006 2.006 0 0 0 3 14v4.446c0 2.162 4.836 2.951 8.368 2.368A5.975 5.975 0 0 1 10 17a5.994 5.994 0 0 1 2.682-5z"
				fill="currentColor"
			/>
			<circle fill="currentColor" cx="9" cy="7" r="4" />
			<circle fill="currentColor" cx="16" cy="17" r="5" />
			<path
				d="M14.674 19.331c.36.36.941.36 1.3 0l2.758-2.763a.92.92 0 0 0-1.301-1.298l-2.108 2.11-.755-.754a.92.92 0 0 0-1.3 1.3l1.406 1.405z"
				fill="inherit"
			/>
		</g>
	</svg>
);

export const jwmCategoryGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" focusable={false}>
		<path
			d="M6 12c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 12zm0 4c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 16zm0-8c0-.552.456-1 1.002-1h9.996a.999.999 0 110 2H7.002A.999.999 0 016 8z"
			fill="currentColor"
		/>
	</svg>
);

export const customFieldGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		width="24"
		height="24"
		role="presentation"
		focusable="false"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fillRule="evenodd">
			<path
				d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H3ZM4 16V12H20V16H4ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V12Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);
