import {
	createStore,
	createActionsHook,
	createStateHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { actions, initialState, type Actions } from './actions.tsx';
import type { SelectedIssuesState, ContainerProps } from './types.tsx';

export const IssueTableSelectedIssuesContainer = createContainer<ContainerProps>();

const Store = createStore<SelectedIssuesState, Actions, ContainerProps>({
	initialState,
	actions,
	name: 'jira-native-issue-table-selected-issues-state',
	containedBy: IssueTableSelectedIssuesContainer,
});

export const useIssueTableSelectedIssuesActions = createActionsHook<
	SelectedIssuesState,
	typeof actions
>(Store);

const getSelectedIssueIdsSelector = (state: SelectedIssuesState): string[] => {
	return Array.from(state.selectedIssueIds);
};

export const useIssueTableSelectedIssues = createStateHook(Store, {
	selector: getSelectedIssueIdsSelector,
});

const getIsIssueIdSelectedSelector = (state: SelectedIssuesState, issueId: string): boolean => {
	return state.selectedIssueIds.has(issueId);
};

export const useIsIssueSelected = createStateHook(Store, {
	selector: getIsIssueIdSelectedSelector,
});

const getAreAllIssuesSelectedSelector = (
	state: SelectedIssuesState,
	issueIds: string[],
): boolean => {
	const selectedIssueIds = getSelectedIssueIdsSelector(state);

	return issueIds.length > 0 && issueIds.every((issueId) => selectedIssueIds.includes(issueId));
};

export const useAreAllIssuesSelected = createStateHook(Store, {
	selector: getAreAllIssuesSelectedSelector,
});
