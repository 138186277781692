import React, { useCallback, useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Popup, { type TriggerProps, type ContentProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AsyncUpdateFilterDialog as UpdateFilterDialog } from '@atlassian/jira-save-filter-dialog/src/ui/update-filter-dialog/async.tsx';
import messages from './messages.tsx';
import { PopupContent, preloadPopupContentComponent } from './popup-content/async.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DetailsButtonHeaderWrapper } from './styled';

export type Props = {
	filterId: string;
	defaultOpen?: boolean;
};

export const DetailsPopup = ({ filterId, defaultOpen = false }: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(defaultOpen);
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	const onTriggerClick = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen(!isOpen);
			fireUIAnalytics(analyticsEvent, 'filterDetailsButton');
		},
		[isOpen, setIsOpen],
	);

	const [isEditFilterModalOpen, setIsEditFilterModalOpen] = useState(false);

	const onOpenEditFilterModal = useCallback(() => {
		setIsEditFilterModalOpen(true);
		onClose();
	}, [onClose]);
	const onCloseEditFilterModal = useCallback(() => {
		setIsEditFilterModalOpen(false);
	}, []);

	const Content = useCallback(
		(props: ContentProps) => (
			<PopupContent
				{...props}
				onOpenEditFilterModal={onOpenEditFilterModal}
				onOpenNewSubscriptionModal={onClose}
				filterId={filterId}
			/>
		),
		[filterId, onClose, onOpenEditFilterModal],
	);
	const Trigger = useCallback(
		(props: TriggerProps) => (
			<Button
				{...props}
				appearance="link"
				onClick={onTriggerClick}
				isSelected={isOpen}
				onMouseOver={preloadPopupContentComponent}
				testId="issue-navigator-custom-filters.ui.details-popup.button"
			>
				{formatMessage(messages.filterDetails)}
			</Button>
		),
		[isOpen, onTriggerClick, formatMessage],
	);

	return (
		<>
			<UpdateFilterDialog
				filterId={filterId}
				onClose={onCloseEditFilterModal}
				isOpen={isEditFilterModalOpen}
			/>
			<Popup
				isOpen={isOpen}
				onClose={onClose}
				placement="bottom-start"
				content={Content}
				trigger={Trigger}
				testId="issue-navigator-custom-filters.ui.details-popup.popup"
			/>
		</>
	);
};
