import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noColumnsHeaderAnonymous: {
		id: 'issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-header-anonymous',
		defaultMessage: "The admin hasn't configured any columns",
		description: 'Message shown when no columns have been selected while access is anonymous',
	},
	noColumnsDescriptionAnonymous: {
		id: 'issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-description-anonymous',
		defaultMessage:
			'Switch to {boldStart}Detail view{boldEnd} to see issues and their information.',
		description:
			'Supplementary message shown when no columns have been selected while access is anonymous.',
	},
	switchView: {
		id: 'issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.switch-view',
		defaultMessage: 'Switch to detail view',
		description:
			'CTA button copy shown when no columns have been selected while access is anonymous.',
	},
	noColumnsDescriptionAnonymousIssueTermRefresh: {
		id: 'issue-navigator.issue-results.list-view.issue-table-no-columns-anonymous.no-columns-description-anonymous-issue-term-refresh',
		defaultMessage:
			'Switch to {boldStart}Detail view{boldEnd} to see work items and their information.',
		description:
			'Supplementary message shown when no columns have been selected while access is anonymous.',
	},
});
