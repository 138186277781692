import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorMessage: {
		id: 'issue-navigator-actions-common.error.error-message',
		defaultMessage: "Couldn't retrieve data",
		description: 'The text for when an error occurs when loading options',
	},
	retryMessage: {
		id: 'issue-navigator-actions-common.error.retry-message',
		defaultMessage: 'Click to retry',
		description: 'The text for button to reload options',
	},
});

export default messages;
