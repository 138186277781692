/**
 * @generated SignedSource<<8e309774501b3339b02ae0050d025df0>>
 * @relayHash 611015ebaa01055df66c1ecbeb1a67cf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0fb631b50dc895a9ef3b13273858a2ee58a5321bad4ca6c6300ff6ab4ffe4f36

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcFullCoreQuery } from './srcFullCoreQuery.graphql';

const node: PreloadableConcreteRequest<srcFullCoreQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "0fb631b50dc895a9ef3b13273858a2ee58a5321bad4ca6c6300ff6ab4ffe4f36",
    "metadata": {},
    "name": "srcFullCoreQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
