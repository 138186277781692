import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useScrollState as useFastVirtualScrollState } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';
import { useElementSizeSelector } from '../../../../controllers/element-size/index.tsx';
import { useScrollStateSelector } from '../../../../controllers/scroll-state/index.tsx';
import { useTableSize } from '../../../../controllers/table-size/index.tsx';
import { BORDER_WIDTH } from '../../../constants.tsx';
import type { FooterProps } from '../../../types.tsx';

const MaybeComputedFooter = componentWithFG(
	'jira_spreadsheet_component_m1_api_updates',
	FooterNew,
	FooterOld,
);

/**
 * Default footer element to display beneath the issue table.
 */
export const Footer = ({
	start = SpacerElement,
	center = SpacerElement,
	end = SpacerElement,
}: FooterProps) => {
	return (
		<MaybeComputedFooter>
			{start}
			{center}
			{end}
		</MaybeComputedFooter>
	);
};

function FooterNew({ children }: { children: ReactNode }) {
	const tableHeight = useElementSizeSelector((size) => size.height);

	const hasShadow = useScrollStateSelector((scrollState) => {
		const { height: containerHeight, scrollTop } = scrollState;
		// Scroll measurements are fractional, while height measurements are integer, so we need to round before comparing
		return containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;
	});

	return <FooterBase hasShadow={hasShadow}>{children}</FooterBase>;
}

function FooterOld({ children }: { children: ReactNode }) {
	const { height: tableHeight } = useTableSize();
	const { height: containerHeight, scrollTop } = useFastVirtualScrollState();
	// Scroll measurements are fractional, while height measurements are integer, so we need to round before comparing
	const hasShadow =
		containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;

	return <FooterBase hasShadow={hasShadow}>{children}</FooterBase>;
}

function FooterBase({ children, hasShadow }: { children: ReactNode; hasShadow: boolean }) {
	return (
		<FooterContainer
			data-testid="native-issue-table.common.ui.components.footer.footer-container"
			hasShadow={hasShadow}
		>
			{children}
		</FooterContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div<{ hasShadow: boolean }>(
	{
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		position: 'sticky',

		left: 0,
		bottom: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderTop: `${BORDER_WIDTH} solid ${token('color.border', '#091e4221')}`,
		/* adding 4px left and right padding for focus border of child elements */
		padding: token('space.050', '4px'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		hasShadow && {
			boxShadow: `
                0px -8px 8px -8px ${token('elevation.shadow.overflow.spread', '#091e4229')},
                0px -1px 1px -1px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}
            `,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Spacer = styled.div({
	display: 'flex',
	flex: 1,
});

const SpacerElement = <Spacer />;
