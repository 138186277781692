import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIssueSearchQuery } from '../../../services/issue-search-query/index.tsx';
import {
	useIsRefreshing,
	useIssueSearchActions,
} from '../../../services/issue-search/selectors.tsx';
import messages from './messages.tsx';

const RefreshButton = () => {
	let isRefreshing;
	let onRefresh;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isRefreshing = useIsRefreshing();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onRefresh } = useIssueSearchActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isRefreshing, onIssueSearchRefresh: onRefresh } = useIssueSearchQuery());
	}

	const handleClick = useCallback(
		(_: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'reloadIssueListButton');
			onRefresh();
		},
		[onRefresh],
	);
	const { formatMessage } = useIntl();

	return (
		<IconButton
			appearance="subtle"
			onClick={handleClick}
			testId="issue-navigator.common.ui.refresh-button.refresh-button"
			label={formatMessage(messages.tooltip)}
			icon={(iconProps) => (
				<RefreshIcon
					{...iconProps}
					color={token('color.icon.subtle')}
					LEGACY_size="small"
					LEGACY_primaryColor="color.icon.subtle"
				/>
			)}
			isLoading={isRefreshing}
		/>
	);
};

export default RefreshButton;
