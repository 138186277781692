import { metrics } from '@atlassian/browser-metrics';
import { JQL_BUILDER_PERFORMANCE_MARKS } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/constants.tsx';

export const marks = {
	ISSUE_NAVIGATOR_START: 'ISSUE_NAVIGATOR_START',
	ISSUE_NAVIGATOR_END: 'ISSUE_NAVIGATOR_END',
	ISSUE_NAVIGATOR_UI_START: 'ISSUE_NAVIGATOR_UI_START',
	ISSUE_NAVIGATOR_UI_END: 'ISSUE_NAVIGATOR_UI_END',
	PAGE_ACTIONS_START: 'PAGE_ACTIONS_START',
	PAGE_ACTIONS_END: 'PAGE_ACTIONS_END',
	PAGE_ACTIONS_VIEW_SWITCHER_START: 'PAGE_ACTIONS_VIEW_SWITCHER_START',
	PAGE_ACTIONS_VIEW_SWITCHER_END: 'PAGE_ACTIONS_VIEW_SWITCHER_END',
	REFINEMENT_START: 'REFINEMENT_START',
	REFINEMENT_END: 'REFINEMENT_END',
	ISSUE_RESULTS_START: 'ISSUE_RESULTS_START',
	ISSUE_RESULTS_END: 'ISSUE_RESULTS_END',
	ISSUE_RESULTS_DETAIL_VIEW_START: 'ISSUE_RESULTS_DETAIL_VIEW_START',
	ISSUE_RESULTS_DETAIL_VIEW_END: 'ISSUE_RESULTS_DETAIL_VIEW_END',
	ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START: 'ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START',
	ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END: 'ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END',
	ISSUE_RESULTS_LIST_VIEW_START: 'ISSUE_RESULTS_LIST_VIEW_START',
	ISSUE_RESULTS_LIST_VIEW_END: 'ISSUE_RESULTS_LIST_VIEW_END',
	ISSUE_RESULTS_LIST_VIEW_FOOTER_START: 'ISSUE_RESULTS_LIST_VIEW_FOOTER_START',
	ISSUE_RESULTS_LIST_VIEW_FOOTER_END: 'ISSUE_RESULTS_LIST_VIEW_FOOTER_END',
	ISSUE_APP_START: 'ISSUE_APP_START',
	ISSUE_APP_END: 'ISSUE_APP_END',
	...JQL_BUILDER_PERFORMANCE_MARKS,
} as const;

export const NINJqlBuilderAsyncLoad = metrics.pageSegmentLoad({
	key: 'new-issue-navigator-jql-builder',
	timings: [
		{
			key: 'product/issue-navigator/ui/jql-builder',
			startMark: marks.JQL_BUILDER_START,
			endMark: marks.JQL_BUILDER_END,
		},
		{
			key: 'product/issue-navigator/ui/jql-builder/hydrate',
			startMark: marks.JQL_BUILDER_HYDRATE_START,
			endMark: marks.JQL_BUILDER_HYDRATE_END,
		},
		{
			key: 'product/issue-navigator/ui/jql-builder/jql-builder-basic',
			startMark: marks.JQL_BUILDER_BASIC_START,
			endMark: marks.JQL_BUILDER_BASIC_END,
		},
		{
			key: 'product/issue-navigator/ui/jql-builder/jql-builder-basic/js',
			startMark: marks.JQL_BUILDER_BASIC_JS_START,
			endMark: marks.JQL_BUILDER_BASIC_JS_END,
		},
		{
			key: 'product/issue-navigator/ui/jql-builder/jql-builder-advanced',
			startMark: marks.JQL_BUILDER_ADVANCED_START,
			endMark: marks.JQL_BUILDER_ADVANCED_END,
		},
		{
			key: 'product/issue-navigator/ui/jql-builder/jql-builder-advanced/js',
			startMark: marks.JQL_BUILDER_ADVANCED_JS_START,
			endMark: marks.JQL_BUILDER_ADVANCED_JS_END,
		},
	],
	histogram: {
		initial: {
			fmp: '100_200_500_1000_2500_3000_4000_5000_10000',
			tti: '100_200_500_1000_2500_3000_4000_5000_10000',
		},
		transition: {
			fmp: '100_200_500_1000_2500_3000_4000_5000_10000',
			tti: '100_200_500_1000_2500_3000_4000_5000_10000',
		},
	},
	featureFlags: [],
});

export const NINPageLoad = metrics.pageLoad({
	key: 'new-issue-navigator',
	slo: {
		fmp: {
			initial: { threshold: 3000 },
			transition: { threshold: 3000 },
		},
		tti: {
			initial: { threshold: 4000 },
			transition: { threshold: 4000 },
		},
	},
	ssr: {
		doneAsFmp: true,
	},
	timings: [
		{
			key: 'product/issue-navigator',
			startMark: marks.ISSUE_NAVIGATOR_START,
			endMark: marks.ISSUE_NAVIGATOR_END,
		},
		{
			key: 'product/issue-navigator/ui',
			startMark: marks.ISSUE_NAVIGATOR_UI_START,
			endMark: marks.ISSUE_NAVIGATOR_UI_END,
		},
		{
			key: 'product/issue-navigator/ui/page-actions',
			startMark: marks.PAGE_ACTIONS_START,
			endMark: marks.PAGE_ACTIONS_END,
		},
		{
			key: 'product/issue-navigator/ui/page-actions/view-switcher',
			startMark: marks.PAGE_ACTIONS_VIEW_SWITCHER_START,
			endMark: marks.PAGE_ACTIONS_VIEW_SWITCHER_END,
		},
		{
			key: 'product/issue-navigator/ui/refinement',
			startMark: marks.REFINEMENT_START,
			endMark: marks.REFINEMENT_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-results',
			startMark: marks.ISSUE_RESULTS_START,
			endMark: marks.ISSUE_RESULTS_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-results/detail-view',
			startMark: marks.ISSUE_RESULTS_DETAIL_VIEW_START,
			endMark: marks.ISSUE_RESULTS_DETAIL_VIEW_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-results/detail-view/card-list',
			startMark: marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START,
			endMark: marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-results/list-view',
			startMark: marks.ISSUE_RESULTS_LIST_VIEW_START,
			endMark: marks.ISSUE_RESULTS_LIST_VIEW_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-results/list-view/footer',
			startMark: marks.ISSUE_RESULTS_LIST_VIEW_FOOTER_START,
			endMark: marks.ISSUE_RESULTS_LIST_VIEW_FOOTER_END,
		},
		{
			key: 'product/issue-navigator/ui/issue-app',
			startMark: marks.ISSUE_APP_START,
			endMark: marks.ISSUE_APP_END,
		},
	],
	featureFlags: [],
});

const consumedMarks: Array<string> = [];
export const markOnce = (mark: string) => {
	if (!consumedMarks.includes(mark)) {
		consumedMarks.push(mark);
		NINPageLoad.mark(mark);
		NINJqlBuilderAsyncLoad.mark(mark);
	}
};
