import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	saveFilterButton: {
		id: 'issue-navigator.jql-builder.save-filter-button',
		defaultMessage: 'Save filter',
		description: 'Text for the button to open save filter modal.',
	},
	saveFilterDialogTitle: {
		id: 'issue-navigator.jql-builder.save-filter-dialog-title',
		defaultMessage: 'Save filter',
		description: 'Text for title of save filter modal.',
	},
	copyFilterButton: {
		id: 'issue-navigator.jql-builder.copy-filter-button',
		defaultMessage: 'Copy filter',
		description: 'Text for the button to open copy filter modal.',
	},
	copyFilterDialogTitle: {
		id: 'issue-navigator.jql-builder.copy-filter-dialog-title',
		defaultMessage: 'Copy filter',
		description: 'Text for title of copy filter modal.',
	},
});
