import findLast from 'lodash/findLast';
import type {
	SelectOption,
	SelectValue,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { isSingleValueOption } from '@atlassian/jira-jql-builder-basic-picker/src/common/utils/is-single-value-option.tsx';
import { COLUMN_TYPE_EPIC_LINK, COLUMN_TYPE_PARENT_LINK } from '../../common/constants.tsx';

const isNotinSelectedList = (selectedOptions: SelectValue, option: SelectOption) => {
	const optionFound = selectedOptions.find(
		(select) =>
			isSingleValueOption(select) && isSingleValueOption(option) && select.cursor === option.cursor,
	);
	return optionFound === undefined;
};

export const getCursorOfLastUnselectedOption = (
	options: SelectValue,
	selectedOptions: SelectValue,
	search?: string,
	endCursor?: string,
): string | undefined => {
	const flatten = options.flatMap((option) =>
		option.optionType === 'group' ? option.options : option,
	);

	const lastUnselectedOption = findLast(
		flatten,
		(option) =>
			isSingleValueOption(option) &&
			!!option.cursor &&
			isNotinSelectedList(selectedOptions, option),
	);

	let cursor =
		lastUnselectedOption && isSingleValueOption(lastUnselectedOption)
			? lastUnselectedOption?.cursor
			: undefined;

	// with a given search input, don't set cursor to undefined when there are only selected options in the list, because we query with 'ALL' options
	if (search && cursor === undefined && endCursor) {
		cursor = endCursor;
	}
	return cursor;
};

export const isOnlyUnselectableField = (fieldType: string, canEditColumns: boolean) => {
	if (fieldType === COLUMN_TYPE_EPIC_LINK) {
		return canEditColumns;
	}

	if (fieldType === COLUMN_TYPE_PARENT_LINK) {
		return canEditColumns;
	}
	return false;
};
