import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { selectedView_issueNavigator_SelectedViewContainer_issueSearchView$key as IssueSearchViewFragment } from '@atlassian/jira-relay/src/__generated__/selectedView_issueNavigator_SelectedViewContainer_issueSearchView.graphql';
import type { selectedView_issueNavigator_SelectedViewContainer_jiraView$key as JiraViewFragment } from '@atlassian/jira-relay/src/__generated__/selectedView_issueNavigator_SelectedViewContainer_jiraView.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_VIEW_ID } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils/index.tsx';
import { Container as SelectedViewStateContainer } from '../../controllers/selected-view-state/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIssueSearchActions } from '../../services/issue-search/selectors.tsx';

export type Props = {
	children: ReactNode;
	issueSearchView: IssueSearchViewFragment | null;
	jiraView: JiraViewFragment | null;
	/**
	 * Event emitted when the selected view is updated. The caller may want to specify the issue which should be
	 * selected by default when the view is changed, in which case they can provide an `issueKey` argument.
	 *
	 * @param view View that was updated
	 * @param issueKey Optional issue key that should be selected
	 */
	onSetView: (view: IssueNavigatorViewId, issueKey?: IssueKey) => void;
};

export const SelectedViewContainer = ({
	children,
	issueSearchView,
	jiraView,
	onSetView,
}: Props) => {
	let onIssueSearchForView;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onChangeView: onIssueSearchForView } = useIssueSearchActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onIssueSearchForView } = useIssueSearchQuery());
	}

	const issueSearchViewData = useFragment(
		graphql`
			fragment selectedView_issueNavigator_SelectedViewContainer_issueSearchView on JiraIssueSearchView {
				viewId
			}
		`,
		issueSearchView,
	);

	const jiraViewData = useFragment(
		graphql`
			fragment selectedView_issueNavigator_SelectedViewContainer_jiraView on JiraView {
				__typename
				... on JiraSpreadsheetView {
					viewId
				}
			}
		`,
		jiraView,
	);

	let maybeViewId: string | null | undefined;

	if (fg('refactor_nin_to_jira_view_schema')) {
		// TODO: simplify logic once JiraDetailedView implements JiraIssueSearchViewMetadata
		// https://jbusiness.atlassian.net/browse/JSC-720
		maybeViewId = jiraViewData?.__typename === 'JiraDetailedView' ? 'detail' : jiraViewData?.viewId;
	} else {
		maybeViewId = issueSearchViewData?.viewId;
	}

	const viewId = parseIssueNavigatorViewIdOrDefault(maybeViewId, DEFAULT_VIEW_ID);

	return (
		<SelectedViewStateContainer
			view={viewId}
			onSetView={onSetView}
			onIssueSearchForView={onIssueSearchForView}
		>
			{children}
		</SelectedViewStateContainer>
	);
};
