/**
 * @generated SignedSource<<a3182e66785e68d12090ebb9bf08695e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraJqlSyntaxError = "BAD_FIELD_ID" | "BAD_FUNCTION_ARGUMENT" | "BAD_OPERATOR" | "BAD_PROPERTY_ID" | "EMPTY_FIELD" | "EMPTY_FUNCTION" | "EMPTY_FUNCTION_ARGUMENT" | "ILLEGAL_CHARACTER" | "ILLEGAL_ESCAPE" | "ILLEGAL_NUMBER" | "MISSING_FIELD_NAME" | "MISSING_LOGICAL_OPERATOR" | "NO_OPERATOR" | "NO_ORDER" | "OPERAND_UNSUPPORTED" | "PREDICATE_UNSUPPORTED" | "RESERVED_CHARACTER" | "RESERVED_WORD" | "UNEXPECTED_TEXT" | "UNFINISHED_STRING" | "UNKNOWN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults$data = {
  readonly issueSearchError: {
    readonly __typename: "JiraInvalidJqlError";
    readonly messages: ReadonlyArray<string | null | undefined> | null | undefined;
  } | {
    readonly __typename: "JiraInvalidSyntaxError";
    readonly errorType: JiraJqlSyntaxError | null | undefined;
    readonly message: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults";
};
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults$key = {
  readonly " $data"?: jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "issueSearchError",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "messages"
            }
          ],
          "type": "JiraInvalidJqlError"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "message"
            },
            {
              "kind": "ScalarField",
              "name": "errorType"
            }
          ],
          "type": "JiraInvalidSyntaxError"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "eeb59c3a307dc75263f7e39b4f314c7c";

export default node;
