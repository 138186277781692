/**
 * @generated SignedSource<<96f197ee49bffef91f88aabc89058cb4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_jqlBuilderWithAi_JQLBuilderWithAI">;
  readonly " $fragmentType": "jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey";
};
export type jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey$key = {
  readonly " $data"?: jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_jqlBuilderWithAi_JQLBuilderWithAI"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "dd0aae667d7d92a8112c28d2dcff0ac8";

export default node;
