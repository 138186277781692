import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { thirdPartyNudgeEntryPoint } from './entrypoint.tsx';

const runtimeProps = {};
const entryPointParams = {};

const AsyncThirdPartyNudge = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		thirdPartyNudgeEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="third-party-nudge-spotlight"
			packageName="third-party-nudge"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			errorFallback="unmount"
		/>
	);
};

export default AsyncThirdPartyNudge;
