import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import { createRememoizeSelector } from '../../common/utils/index.tsx';
import { Store } from './index.tsx';
import { getNextIssueKey, getPreviousIssueKey, getSelectedIssueIndex } from './selectors.tsx';
import type { State, Actions } from './types.tsx';

export const useSelectedIssueIndex = createStateHook<State, Actions, number>(Store, {
	selector: getSelectedIssueIndex,
});

export const useNextIssueKey = createStateHook<State, Actions, IssueKey | null>(Store, {
	selector: getNextIssueKey,
});

export const usePreviousIssueKey = createStateHook<State, Actions, IssueKey | null>(Store, {
	selector: getPreviousIssueKey,
});

export const useSelectedIssueKey = createStateHook(Store, {
	selector: ({ selectedIssueKey }) => selectedIssueKey,
});

export const useIsSelectedIssue = createStateHook<State, Actions, boolean, IssueKey>(Store, {
	selector: ({ selectedIssueKey }, issueKey) => selectedIssueKey === issueKey,
});

export const useSelectedIssueDebouncedState = createStateHook(Store, {
	selector: createRememoizeSelector(
		(state) => state,
		({ selectedIssueKey }) => selectedIssueKey,
	),
});

export const useIsFullPageIssueAppMode = createStateHook(Store, {
	selector: ({ isFullPageIssueAppMode }) => isFullPageIssueAppMode,
});

export const useSelectedIssueActions = createActionsHook(Store);
