import React, {
	useCallback,
	useMemo,
	useLayoutEffect,
	type MouseEvent,
	type ReactNode,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { withRecoverableErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/recoverable-error-boundary/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIssueSearchQuery } from '../../../services/issue-search-query/index.tsx';
import { useIssueSearchActions } from '../../../services/issue-search/selectors.tsx';
import messages from './messages.tsx';

type Props = {
	error?: Error;
	onCaughtIssueSearchError?: (error?: Error) => void;
};

const ErrorView = ({ onCaughtIssueSearchError, error: errorProp }: Props) => {
	const { formatMessage } = useIntl();
	let onRefresh;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onRefresh } = useIssueSearchActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onIssueSearchRefresh: onRefresh } = useIssueSearchQuery());
	}

	const onClick = useCallback(
		(e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | null' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireUIAnalytics(analyticsEvent.clone(), 'refreshFromError');
			onRefresh();
		},
		[onRefresh],
	);

	const error = useMemo(
		() => errorProp || new Error('There was an error searching for issues'),
		[errorProp],
	);

	useLayoutEffect(() => {
		onCaughtIssueSearchError && onCaughtIssueSearchError(error);
	}, [onCaughtIssueSearchError, error]);

	return (
		<Container>
			<EmptyState
				header={formatMessage(messages.emptyStateErrorHeader)}
				description={formatMessage(messages.emptyStateErrorDescription)}
				testId="issue-navigator.ui.issue-results.error-view.error-message"
				primaryAction={
					<Button appearance="primary" onClick={onClick}>
						{formatMessage(messages.emptyStateErrorAction)}
					</Button>
				}
				renderImage={() => (
					<Box xcss={ImageWrapperStyles}>
						<SearchNoResultsImage alt="" />
					</Box>
				)}
			/>
			<FireUiAnalytics action="viewed" actionSubject="screen" actionSubjectId="issueSearchError" />
		</Container>
	);
};

export default ErrorView;

export const RecoverableErrorViewBoundary = withRecoverableErrorBoundary(
	({ children }: Props & { children: ReactNode; recoveryProp: unknown }) => <>{children}</>,
	{
		recoveryProp: 'recoveryProp',
		Fallback: ErrorView,
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexGrow: 1,
	alignItems: 'center',
});

const ImageWrapperStyles = xcss({
	maxHeight: '146px',
	maxWidth: '160px',
	margin: 'auto',
	marginBottom: 'space.300',
});
