import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type Keys = 'heading' | 'windowTitleMigration' | 'headingIssueTermRefresh';

type Messages = Record<Keys, MessageDescriptor>;

const messages: Messages = defineMessages({
	heading: {
		id: 'project-issue-navigator.heading',
		defaultMessage: 'Issues',
		description: 'Page heading title',
	},
	windowTitleMigration: {
		id: 'project-issue-navigator.window-title-migration',
		defaultMessage: '{projectName} - Issues',
		description: 'Window title of the project issue navigator page',
	},
	headingIssueTermRefresh: {
		id: 'project-issue-navigator.heading-issue-term-refresh',
		defaultMessage: 'Work items',
		description: 'Page heading title',
	},
});

export default messages;
