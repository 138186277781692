/**
 * @generated SignedSource<<37e89084567e94210389c7de20410ab0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_groupResults$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_groupResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_groupResults";
};
export type main_issueNavigator_IssueResults_groupResults$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isPaginating"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_groupResults",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_groupResults"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "918b4174a3e5d671d45a5e3f6e47aec7";

export default node;
