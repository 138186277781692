import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys

export default defineMessages({
	tooltipAsc: {
		id: 'issue-navigator.card-container.sort-button.tooltip-asc',
		defaultMessage: 'Sort ascending',
		description: '',
	},
	tooltipDesc: {
		id: 'issue-navigator.card-container.sort-button.tooltip-desc',
		defaultMessage: 'Sort descending',
		description: '',
	},
});
