/**
 * @generated SignedSource<<50c8e9868b3e511de3ea4f420144c717>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_filterQuery$data = {
  readonly jira: {
    readonly filter?: {
      readonly isEditable?: boolean | null | undefined;
      readonly jql: string;
      readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_filter" | "main_issueNavigator_Header_filter" | "main_issueNavigator_ListView_filter" | "topBar_issueNavigator_filter">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_filterQuery";
};
export type main_issueNavigator_IssueNavigator_filterQuery$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_filterQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_filterQuery">;
};

import FilterRefetchQuery_graphql from './FilterRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterAri"
    },
    {
      "kind": "RootArgument",
      "name": "includeFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": FilterRefetchQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_filterQuery",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "includeFilter",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "filterAri"
                }
              ],
              "kind": "LinkedField",
              "name": "filter",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "jql"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "isEditable"
                    }
                  ],
                  "type": "JiraCustomFilter"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_Header_filter"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "topBar_issueNavigator_filter"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_filter"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_ListView_filter"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "26da718314f181d4824f62928229af2b";

export default node;
