import React, { forwardRef, useContext } from 'react';
import { styled } from '@compiled/react';
import Link from '@atlaskit/link';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { borderRadius, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { ComponentProps } from '@atlassian/jira-jql-builder-basic-picker/src/ui/main.tsx';
import {
	type ColumnPickerMessageContextProps,
	ColumnPickerMessageContext,
} from '../../../../controllers/column-picker-message-context/index.tsx';
import { useSelectedViewState } from '../../../../controllers/selected-view-state/index.tsx';
import { useActiveJql } from '../../../../services/active-jql/index.tsx';
import { ColumnPickerBannerMessage } from './column-picker-banner-message/index.tsx';
import { ColumnPickerMaxSelectedColumns } from './column-picker-max-selected-columns/index.tsx';
import ColumnPickerTabs from './column-picker-tabs/index.tsx';
import messages from './messages.tsx';

const ColumnPickerPopupComponent = forwardRef<HTMLDivElement, ComponentProps>(
	({ children, ...props }: ComponentProps, ref) => {
		const { filterId, isFilterEditable } = useActiveJql();
		const [{ view }] = useSelectedViewState();
		const { hasMaxSelectedColumns, filterName }: ColumnPickerMessageContextProps = useContext(
			ColumnPickerMessageContext,
		);

		let bannerMessage: JSX.Element | null = null;
		if (hasMaxSelectedColumns) {
			bannerMessage = <ColumnPickerMaxSelectedColumns />;
		} else if (view === 'list_system') {
			bannerMessage = (
				<ColumnPickerBannerMessage
					bannerMessage={<FormattedMessage {...messages.listSystemWarningMessage} />}
					type="warning"
				/>
			);
		} else if (view === `list_filter_${filterId}`) {
			if (isFilterEditable === false) {
				bannerMessage = (
					<ColumnPickerBannerMessage
						type="info"
						bannerMessage={
							<FormattedMessage
								{...messages.listNonEditableFilterInfoMessage}
								values={{
									// To remove @ts-expect-error below we need to upgrade intl to v6
									// https://stackoverflow.com/questions/67884173/formattedmessage-values-chunks-using-typescript
									// @ts-expect-error TS2322: Type '(chunks: ReactNode[]) => JSX.Element' is not assignable to type 'ReactNode'.
									link: (chunks: ReactNode[]) => (
										<Link
											href={`/jira/filters${filterName ? `?name="${encodeURIComponent(filterName)}"` : ''}`}
											target="_blank"
										>
											{chunks}
										</Link>
									),
								}}
							/>
						}
					/>
				);
			} else {
				bannerMessage = (
					<ColumnPickerBannerMessage
						bannerMessage={<FormattedMessage {...messages.listFilterWarningMessage} />}
						type="warning"
					/>
				);
			}
		}
		return (
			<PopupComponentContainer {...props} ref={ref}>
				<ColumnPickerTabs>
					<TabsContentContainer>
						{bannerMessage}
						{children}
					</TabsContentContainer>
				</ColumnPickerTabs>
			</PopupComponentContainer>
		);
	},
);

export default ColumnPickerPopupComponent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupComponentContainer = styled.div({
	width: '300px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.overlay', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.layer,
	paddingTop: token('space.050', '4px'),
	'&:focus': {
		outline: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabsContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
});
