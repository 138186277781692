import React, { type ComponentType, type FC } from 'react';
import Button from '@atlaskit/button';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SaveFilterDialog: ComponentType<Props> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-save-filter-dialog-relay" */ './main'),
	{ ssr: false },
);

export const AsyncSaveFilterDialog: FC<Props> = (props: Props) => (
	<Placeholder
		name="save-filters-dialog"
		fallback={
			<Button appearance="link" isDisabled>
				{props.buttonText}
			</Button>
		}
	>
		<SaveFilterDialog {...props} />
	</Placeholder>
);
