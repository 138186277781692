/**
 * @generated SignedSource<<f48be2f2ab3c8bc6fa498ad3ac354ed5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedView_issueNavigator_SelectedViewContainer_jiraView$data = {
  readonly __typename: string;
  readonly viewId?: string | null | undefined;
  readonly " $fragmentType": "selectedView_issueNavigator_SelectedViewContainer_jiraView";
};
export type selectedView_issueNavigator_SelectedViewContainer_jiraView$key = {
  readonly " $data"?: selectedView_issueNavigator_SelectedViewContainer_jiraView$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedView_issueNavigator_SelectedViewContainer_jiraView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "selectedView_issueNavigator_SelectedViewContainer_jiraView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "viewId"
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "97dfa8fe6160ce7d12f4804154b833c6";

export default node;
