import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	listSystemWarningMessage: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.list-system-warning-message',
		defaultMessage: 'Any changes here will modify columns for all users.',
		description:
			'Message shown when the user is trying to configure list_system column configuration.',
	},
	listFilterWarningMessage: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.list-filter-warning-message',
		defaultMessage: 'Any changes here will affect all users of this filter.',
		description: 'Message shown when the user is trying to configure filter column configuration.',
	},
	listNonEditableFilterInfoMessage: {
		id: 'issue-navigator.issue-results.list-view.column-picker-popup-component.list-non-editable-filter-info-message',
		defaultMessage:
			'You can’t modify this filter. Contact the owner using the <link>Filters directory</link> for edit permission.',
		description:
			'Message shown when the user is trying to configure list_system column configuration.',
	},
});
