/**
 * @generated SignedSource<<03538bbca92c1091b83aaf8fd6096e59>>
 * @relayHash 908963875bfde9403dd51e5dccd79b94
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ddae107c980792c2317ac60d5a83fabc3f5d7f72748a92cc640b58fb7eb42f16

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type IssueNavigatorGroupedListViewPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  groupBy?: string | null | undefined;
  id: string;
  isPaginating: boolean;
  issueSearchInput: JiraIssueSearchInput;
  issueTypeId?: string | null | undefined;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  projectId?: string | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type IssueNavigatorGroupedListViewPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_groupedListViewData">;
  } | null | undefined;
};
export type IssueNavigatorGroupedListViewPaginationQuery = {
  response: IssueNavigatorGroupedListViewPaginationQuery$data;
  variables: IssueNavigatorGroupedListViewPaginationQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupBy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPaginating"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeId"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v17 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v18 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v19 = {
  "kind": "ScalarField",
  "name": "id"
},
v20 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "groupBy",
    "variableName": "groupBy"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v21 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v22 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v23 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v24 = {
  "kind": "ScalarField",
  "name": "picture"
},
v25 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v26 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v25/*: any*/),
    (v19/*: any*/)
  ]
},
v27 = {
  "kind": "ScalarField",
  "name": "number"
},
v28 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v19/*: any*/)
  ]
},
v29 = {
  "kind": "ScalarField",
  "name": "state"
},
v30 = {
  "kind": "LinkedField",
  "name": "fieldValue",
  "plural": false,
  "selections": [
    (v18/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            (v19/*: any*/),
            (v22/*: any*/)
          ]
        },
        (v23/*: any*/)
      ],
      "type": "JiraJqlPriorityFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v18/*: any*/),
            (v19/*: any*/),
            (v24/*: any*/)
          ]
        },
        (v23/*: any*/)
      ],
      "type": "JiraJqlUserFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            (v19/*: any*/)
          ]
        },
        (v23/*: any*/),
        (v26/*: any*/)
      ],
      "type": "JiraJqlStatusFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v27/*: any*/)
      ],
      "type": "JiraJqlNumberFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "option",
          "plural": false,
          "selections": [
            (v19/*: any*/),
            (v28/*: any*/)
          ]
        },
        (v23/*: any*/)
      ],
      "type": "JiraJqlOptionFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "sprint",
          "plural": false,
          "selections": [
            (v19/*: any*/),
            (v29/*: any*/)
          ]
        },
        (v23/*: any*/)
      ],
      "type": "JiraJqlSprintFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraGoal",
          "kind": "LinkedField",
          "name": "goal",
          "plural": false,
          "selections": [
            (v19/*: any*/),
            {
              "kind": "ScalarField",
              "name": "status"
            },
            {
              "kind": "ScalarField",
              "name": "score"
            }
          ]
        },
        (v23/*: any*/)
      ],
      "type": "JiraJqlGoalsFieldValue"
    }
  ]
},
v31 = {
  "kind": "ScalarField",
  "name": "jql"
},
v32 = {
  "kind": "ScalarField",
  "name": "fieldType"
},
v33 = {
  "kind": "ScalarField",
  "name": "issueCount"
},
v34 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v35 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v36 = {
  "kind": "ScalarField",
  "name": "message"
},
v37 = [
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v38 = {
  "kind": "ScalarField",
  "name": "key"
},
v39 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v40 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v41 = {
  "kind": "ScalarField",
  "name": "type"
},
v42 = {
  "kind": "ScalarField",
  "name": "name"
},
v43 = [
  (v42/*: any*/),
  (v19/*: any*/)
],
v44 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v45 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v46 = {
  "kind": "ScalarField",
  "name": "text"
},
v47 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v48 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v49 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v41/*: any*/),
          {
            "kind": "ScalarField",
            "name": "fieldSetId"
          },
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v39/*: any*/),
                      (v41/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraIssueField"
                      },
                      (v19/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraAffectedServiceConnection",
                            "kind": "LinkedField",
                            "name": "selectedAffectedServicesConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAffectedServiceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "serviceId"
                                      },
                                      (v42/*: any*/),
                                      (v19/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraAffectedServicesField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraCmdbObjectConnection",
                            "kind": "LinkedField",
                            "name": "selectedCmdbObjectsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraCmdbObjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "objectId"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "label"
                                      },
                                      {
                                        "concreteType": "JiraCmdbAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbMediaClientConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaClientConfig",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "clientId"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "fileId"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "mediaBaseUrl"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "mediaJwtToken"
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "url48"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "QueryError",
                                "kind": "LinkedField",
                                "name": "errors",
                                "plural": true,
                                "selections": [
                                  (v36/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraCMDBField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraComponentConnection",
                            "kind": "LinkedField",
                            "name": "selectedComponentsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraComponentEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v43/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraComponentsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "date"
                          },
                          (v42/*: any*/),
                          (v44/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraDatePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "dateTime"
                          }
                        ],
                        "type": "JiraDateTimePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraEpic",
                            "kind": "LinkedField",
                            "name": "epic",
                            "plural": false,
                            "selections": [
                              (v38/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "summary"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "color"
                              },
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraEpicLinkField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "renderedFieldHtml"
                          }
                        ],
                        "type": "JiraFallbackField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v46/*: any*/),
                          (v42/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v42/*: any*/),
                              (v25/*: any*/),
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              (v42/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "statusId"
                              },
                              (v26/*: any*/),
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1000
                              }
                            ],
                            "concreteType": "JiraLabelConnection",
                            "kind": "LinkedField",
                            "name": "selectedLabelsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraLabelEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraLabel",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v42/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v47/*: any*/)
                            ],
                            "storageKey": "selectedLabelsConnection(first:1000)"
                          },
                          (v42/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraLabelsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "small"
                                  }
                                ]
                              },
                              (v42/*: any*/),
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "parentIssue",
                            "plural": false,
                            "selections": [
                              (v38/*: any*/),
                              {
                                "concreteType": "JiraSingleLineTextField",
                                "kind": "LinkedField",
                                "name": "summaryField",
                                "plural": false,
                                "selections": [
                                  (v46/*: any*/),
                                  (v19/*: any*/)
                                ]
                              },
                              (v19/*: any*/),
                              {
                                "concreteType": "JiraColorField",
                                "kind": "LinkedField",
                                "name": "issueColorField",
                                "plural": false,
                                "selections": [
                                  (v28/*: any*/),
                                  (v19/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraIssueTypeField",
                                "kind": "LinkedField",
                                "name": "issueTypeField",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "issueType",
                                    "plural": false,
                                    "selections": [
                                      (v48/*: any*/),
                                      (v42/*: any*/),
                                      (v19/*: any*/)
                                    ]
                                  },
                                  (v19/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraParentIssueField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraPriority",
                            "kind": "LinkedField",
                            "name": "priority",
                            "plural": false,
                            "selections": [
                              (v42/*: any*/),
                              (v22/*: any*/),
                              (v19/*: any*/)
                            ]
                          },
                          (v42/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraPriorityField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v42/*: any*/),
                              (v38/*: any*/),
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "large"
                                  }
                                ]
                              },
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraProjectField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraResolution",
                            "kind": "LinkedField",
                            "name": "resolution",
                            "plural": false,
                            "selections": (v43/*: any*/)
                          }
                        ],
                        "type": "JiraResolutionField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraSprintConnection",
                            "kind": "LinkedField",
                            "name": "selectedSprintsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraSprintEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraSprint",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "sprintId"
                                      },
                                      (v42/*: any*/),
                                      (v29/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "endDate"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v42/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraSprintField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              (v24/*: any*/),
                              (v42/*: any*/),
                              (v19/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountStatus"
                              }
                            ]
                          },
                          (v42/*: any*/),
                          (v44/*: any*/),
                          (v45/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v42/*: any*/),
                          {
                            "concreteType": "JiraVersionConnection",
                            "kind": "LinkedField",
                            "name": "selectedVersionsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraVersionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v43/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraMultipleVersionPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraRichText",
                            "kind": "LinkedField",
                            "name": "richText",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraADF",
                                "kind": "LinkedField",
                                "name": "adfValue",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraAdfToConvertedPlainText",
                                    "kind": "LinkedField",
                                    "name": "convertedPlainText",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "plainText"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraRichTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraServiceManagementRequestType",
                            "kind": "LinkedField",
                            "name": "requestType",
                            "plural": false,
                            "selections": [
                              (v42/*: any*/),
                              (v48/*: any*/),
                              (v19/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraServiceManagementRequestTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "first",
                                "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                              }
                            ],
                            "concreteType": "JiraIssueLinkConnection",
                            "kind": "LinkedField",
                            "name": "issueLinkConnection",
                            "plural": false,
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      (v35/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v47/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraIssueLinkEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "webUrl"
                                          },
                                          (v38/*: any*/),
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "ids",
                                                "value": [
                                                  "statusCategory"
                                                ]
                                              }
                                            ],
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fieldsById",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v18/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v19/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                          },
                                          (v19/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "relationName"
                                      },
                                      (v19/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraIssueLinkField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isStoryPointField"
                              },
                              (v27/*: any*/),
                              (v42/*: any*/),
                              (v45/*: any*/)
                            ],
                            "type": "JiraNumberField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v50 = {
  "kind": "ScalarField",
  "name": "__id"
},
v51 = {
  "kind": "ClientExtension",
  "selections": [
    (v50/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorGroupedListViewPaginationQuery",
    "selections": [
      {
        "args": (v17/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_IssueNavigator_groupedListViewData"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v8/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorGroupedListViewPaginationQuery",
    "selections": [
      {
        "args": (v17/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v20/*: any*/),
                "concreteType": "JiraSpreadsheetGroupConnection",
                "kind": "LinkedField",
                "name": "groups",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSpreadsheetGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v30/*: any*/),
                          (v31/*: any*/),
                          (v32/*: any*/),
                          (v33/*: any*/)
                        ]
                      },
                      (v18/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      (v34/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "startCursor"
                      },
                      (v35/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "hasPreviousPage"
                      }
                    ]
                  },
                  (v31/*: any*/),
                  {
                    "concreteType": "QueryError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v18/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "statusCode"
                          }
                        ]
                      },
                      (v36/*: any*/)
                    ]
                  },
                  {
                    "condition": "isPaginating",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "firstGroup",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "args": (v37/*: any*/),
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssue",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "issueId"
                                      },
                                      (v19/*: any*/),
                                      (v18/*: any*/),
                                      (v38/*: any*/),
                                      {
                                        "concreteType": "JiraIssueTypeField",
                                        "kind": "LinkedField",
                                        "name": "issueTypeField",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                                "kind": "LinkedField",
                                                "name": "hierarchy",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "level"
                                                  }
                                                ]
                                              },
                                              (v19/*: any*/)
                                            ]
                                          },
                                          (v19/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "isResolved"
                                      },
                                      {
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "ids",
                                            "value": [
                                              "status"
                                            ]
                                          }
                                        ],
                                        "concreteType": "JiraIssueFieldConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldsById",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      (v39/*: any*/),
                                                      {
                                                        "concreteType": "JiraStatus",
                                                        "kind": "LinkedField",
                                                        "name": "status",
                                                        "plural": false,
                                                        "selections": [
                                                          (v26/*: any*/),
                                                          (v19/*: any*/)
                                                        ]
                                                      }
                                                    ],
                                                    "type": "JiraStatusField"
                                                  },
                                                  (v19/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "storageKey": "fieldsById(ids:[\"status\"])"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "hasChildren"
                                          }
                                        ]
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": false,
                                        "selections": [
                                          {
                                            "condition": "shouldQueryFieldSetsById",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "args": [
                                                  {
                                                    "kind": "Variable",
                                                    "name": "fieldSetIds",
                                                    "variableName": "fieldSetIds"
                                                  },
                                                  (v40/*: any*/)
                                                ],
                                                "concreteType": "JiraIssueFieldSetConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldSetsById",
                                                "plural": false,
                                                "selections": (v49/*: any*/)
                                              }
                                            ]
                                          },
                                          {
                                            "condition": "shouldQueryFieldSetsById",
                                            "kind": "Condition",
                                            "passingValue": false,
                                            "selections": [
                                              {
                                                "args": [
                                                  {
                                                    "fields": [
                                                      {
                                                        "fields": [
                                                          {
                                                            "kind": "Variable",
                                                            "name": "issueType",
                                                            "variableName": "issueTypeId"
                                                          },
                                                          {
                                                            "kind": "Variable",
                                                            "name": "project",
                                                            "variableName": "projectId"
                                                          }
                                                        ],
                                                        "kind": "ObjectValue",
                                                        "name": "projectContext"
                                                      }
                                                    ],
                                                    "kind": "ObjectValue",
                                                    "name": "context"
                                                  },
                                                  {
                                                    "kind": "Variable",
                                                    "name": "filterId",
                                                    "variableName": "filterId"
                                                  },
                                                  (v40/*: any*/),
                                                  {
                                                    "kind": "Variable",
                                                    "name": "namespace",
                                                    "variableName": "namespace"
                                                  },
                                                  {
                                                    "kind": "Variable",
                                                    "name": "viewId",
                                                    "variableName": "viewId"
                                                  }
                                                ],
                                                "concreteType": "JiraIssueFieldSetConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldSetsForIssueSearchView",
                                                "plural": false,
                                                "selections": (v49/*: any*/)
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "ClientExtension",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "isHighlightedIssueRow"
                                          },
                                          (v50/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v18/*: any*/),
                                  (v21/*: any*/),
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 500
                                          }
                                        ],
                                        "concreteType": "JiraIssueFieldSetConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldSets",
                                        "plural": false,
                                        "selections": (v49/*: any*/),
                                        "storageKey": "fieldSets(first:500)"
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v35/*: any*/)
                                ]
                              },
                              (v51/*: any*/)
                            ]
                          },
                          {
                            "args": (v37/*: any*/),
                            "filters": [
                              "fieldSetsInput"
                            ],
                            "handle": "connection",
                            "key": "firstGroupSection_nativeIssueTable__issues",
                            "kind": "LinkedHandle",
                            "name": "issues"
                          },
                          (v31/*: any*/),
                          (v30/*: any*/),
                          (v32/*: any*/),
                          (v33/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v51/*: any*/)
                ]
              },
              {
                "args": (v20/*: any*/),
                "filters": [
                  "issueSearchInput"
                ],
                "handle": "connection",
                "key": "IssueNavigatorGroupedListViewPagination__groups",
                "kind": "LinkedHandle",
                "name": "groups"
              }
            ],
            "type": "JiraGroupedListView"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ddae107c980792c2317ac60d5a83fabc3f5d7f72748a92cc640b58fb7eb42f16",
    "metadata": {},
    "name": "IssueNavigatorGroupedListViewPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
    }
  }
};
})();

(node as any).hash = "f961c76498c2a789d2a4aad00f87241d";

export default node;
