/**
 * @generated SignedSource<<624714bc7d64cade4aaede35e606ffae>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_CardWithSelectedIssue_fieldSets$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fieldSets">;
  readonly " $fragmentType": "main_issueNavigator_CardWithSelectedIssue_fieldSets";
};
export type main_issueNavigator_CardWithSelectedIssue_fieldSets$key = {
  readonly " $data"?: main_issueNavigator_CardWithSelectedIssue_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardWithSelectedIssue_fieldSets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_CardWithSelectedIssue_fieldSets",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Card_fieldSets"
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};

(node as any).hash = "5d80b931b560f2c418fca0d9f032c9a0";

export default node;
