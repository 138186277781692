import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import TotalIssueCount from '../total-issue-count/index.tsx';
import messages from './messages.tsx';

type Props = {
	startIssueRange: number;
	endIssueRange: number;
	loadedIssuesCount: number;
	total: number;
	countExceedsMaxResults: boolean;
	isInfiniteScrollEnabled: boolean;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	startIssueRange,
	endIssueRange,
	loadedIssuesCount,
	total,
	countExceedsMaxResults,
	isInfiniteScrollEnabled,
}: Props) => {
	const { formatMessage } = useIntl();
	const isTotalUnknown = total <= 0;

	if (isInfiniteScrollEnabled) {
		if (isTotalUnknown) {
			return <>{loadedIssuesCount}</>;
		}

		return (
			<TotalIssueCount
				prependMessage={messages.loadedIssuesTotalKnown}
				prependMessageValues={{ loadedIssuesCount }}
				stableCount={total}
				isCapped={countExceedsMaxResults}
			/>
		);
	}

	if (isTotalUnknown) {
		return <>{formatMessage(messages.issueRangeUnknown, { startIssueRange, endIssueRange })}</>;
	}

	return (
		<TotalIssueCount
			prependMessage={messages.issueRangeKnown}
			prependMessageValues={{
				startIssueRange,
				endIssueRange,
			}}
			stableCount={total}
			isCapped={countExceedsMaxResults}
		/>
	);
};
