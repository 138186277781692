import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx'; // TODO - Port i18n string translations to new module ID keys

const messages = defineMessages({
	loading: {
		id: 'issue-navigator.card-container.order-dropdown.loading',
		defaultMessage: 'Loading',
		description: '',
	},
	fieldGroup: {
		id: 'issue-navigator.card-container.order-dropdown.field-group',
		defaultMessage: 'Order issues by',
		description: '',
	},
	created: {
		id: 'issue-navigator.card-container.order-dropdown.created',
		defaultMessage: 'Created',
		description: '',
	},
	updated: {
		id: 'issue-navigator.card-container.order-dropdown.updated',
		defaultMessage: 'Updated',
		description: '',
	},
	lastviewed: {
		id: 'issue-navigator.card-container.order-dropdown.lastviewed',
		defaultMessage: 'Last viewed',
		description: '',
	},
	status: {
		id: 'issue-navigator.card-container.order-dropdown.status',
		defaultMessage: 'Status',
		description: '',
	},
	priority: {
		id: 'issue-navigator.card-container.order-dropdown.priority',
		defaultMessage: 'Priority',
		description: '',
	},
	assignee: {
		id: 'issue-navigator.card-container.order-dropdown.assignee',
		defaultMessage: 'Assignee',
		description: '',
	},
	resolved: {
		id: 'issue-navigator.card-container.order-dropdown.resolved',
		defaultMessage: 'Resolved',
		description: 'The sort option in the drop down to sort by resolved field',
	},
	issuekey: {
		id: 'issue-navigator.card-container.order-dropdown.issuekey',
		defaultMessage: 'Key',
		description: 'The sort option in the drop down to sort by issue key',
	},
	key: {
		id: 'issue-navigator.card-container.order-dropdown.key',
		defaultMessage: 'Key',
		description: 'The sort option in the drop down to sort by issue key',
	},
	issuetype: {
		id: 'issue-navigator.card-container.order-dropdown.issuetype',
		defaultMessage: 'Issue Type',
		description: 'The sort option in the drop down to sort by issue type',
	},
	duedate: {
		id: 'issue-navigator.card-container.order-dropdown.duedate',
		defaultMessage: 'Due date',
		description: 'The sort option in the drop down to sort by due date',
	},
	summary: {
		id: 'issue-navigator.card-container.order-dropdown.summary',
		defaultMessage: 'Summary',
		description: 'The sort option in the drop down to sort by summary',
	},
	reporter: {
		id: 'issue-navigator.card-container.order-dropdown.reporter',
		defaultMessage: 'Reporter',
		description: 'The sort option in the drop down to sort by reporter',
	},
	customField: {
		id: 'issue-navigator.card-container.order-dropdown.custom-field',
		defaultMessage: 'Custom field',
		description: 'The drop down trigger label shown when sorting by a custom field',
	},
	defaultLabel: {
		id: 'issue-navigator.card-container.order-dropdown.default-label',
		defaultMessage: 'Order by',
		description: 'The default drop down trigger label shown when there is no field to sort by',
	},
	fieldGroupIssueTermRefresh: {
		id: 'issue-navigator.card-container.order-dropdown.field-group-issue-term-refresh',
		defaultMessage: 'Order work items by',
		description: '',
	},
	issuetypeIssueTermRefresh: {
		id: 'issue-navigator.card-container.order-dropdown.issuetype-issue-term-refresh',
		defaultMessage: 'Work type',
		description: 'The sort option in the drop down to sort by issue type',
	},
});
export default messages;
// Used to dynamically lookup message descriptors while keeping TS happy.
export const messageLookup: { [key: string]: MessageDescriptorV2 | undefined } = messages;
