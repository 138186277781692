import { fg } from '@atlassian/jira-feature-gating';

/**
 * Get feature flags and feature gates for the issue navigator performance metrics
 */
// eslint-disable-next-line jira/ff/inline-usage
export const getIssueNavigatorFFAndFG = () => ({
	isJscM1ApiUpdatesEnabled: fg('jira_spreadsheet_component_m1_api_updates'),
	isJscM2HierarchyFeChangesEnabled: fg('jsc_m2_hierarchy_fe_changes'),
});
