import React, { Fragment, type ReactNode } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { IssueNavigatorViewId } from '../../../../common/types.tsx';
import { useColumnPickerLoadingContext } from '../../../../controllers/column-picker-loading-context/index.tsx';
import { useSelectedViewState } from '../../../../controllers/selected-view-state/index.tsx';

export type ColumnPickerRemountManagerProps = {
	children: ReactNode;
	viewIdFromResponse: IssueNavigatorViewId;
	/**
	 * @deprecated no longer needed when jira_spreadsheet_component_m1 is enabled
	 * cleanup with jira_spreadsheet_component_m1
	 */
	isRestoringDefaults: boolean;
};
/**
 * The ColumnPickerRemountManager serves as a wrapper for the column picker within the platform issue table.
 * Issue: The platform's column picker maintains an internal state which doesn't reset when the viewId changes, causing
 *   it to display outdated data.
 * Solution: To address this, we use this container to wrap the platform's column picker. It's passed into the platform
 *   table column picker customisation props. When the viewId changes, we re-mount the container utilizing the key prop.
 *   This action forces a re-mount of the platform column picker too, ensuring data displayed are up-to-date.
 */
export const ColumnPickerRemountManager = ({
	children,
	viewIdFromResponse,
	isRestoringDefaults,
}: ColumnPickerRemountManagerProps) => {
	const [{ view: optimisticViewId }] = useSelectedViewState();

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { isLoading } = useColumnPickerLoadingContext();

		return (
			<Fragment key={isLoading ? 'loading' : `${optimisticViewId}${viewIdFromResponse}`}>
				{children}
			</Fragment>
		);
	}

	return (
		<div
			key={isRestoringDefaults ? 'loading' : optimisticViewId + viewIdFromResponse}
			data-vc={`column-picker-container${__SERVER__ ? '-ssr' : ''}`}
		>
			{children}
		</div>
	);
};
