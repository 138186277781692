/**
 * @generated SignedSource<<e98530859aebcbee9c3d9c3201c0b025>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSearch_issueNavigatorGrouping$data = {
  readonly __typename: string;
  readonly viewId?: string | null | undefined;
  readonly viewSettings?: {
    readonly groupByConfig: {
      readonly groupByField: {
        readonly fieldId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly isGroupingEnabled: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "issueSearch_issueNavigatorGrouping";
};
export type issueSearch_issueNavigatorGrouping$key = {
  readonly " $data"?: issueSearch_issueNavigatorGrouping$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigatorGrouping">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueSearch_issueNavigatorGrouping",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "viewId"
        },
        {
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isGroupingEnabled"
            },
            {
              "concreteType": "JiraSpreadsheetGroupByConfig",
              "kind": "LinkedField",
              "name": "groupByConfig",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraField",
                  "kind": "LinkedField",
                  "name": "groupByField",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "fieldId"
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "isHierarchyEnabled"
            }
          ]
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "c1e4be2d58231878f6e1623bc3342dcd";

export default node;
