/**
 * @generated SignedSource<<9b196cb4f346aa45550ec4df0e015618>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_DetailView_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator_view" | "main_issueNavigator_IssueAppWithData_view">;
  readonly " $fragmentType": "main_issueNavigator_DetailView_view";
};
export type main_issueNavigator_DetailView_view$key = {
  readonly " $data"?: main_issueNavigator_DetailView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_DetailView_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardContainer_issueNavigator_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_view"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "2ceaeb21d2d5e1f5dda3e43d62ebd5ef";

export default node;
