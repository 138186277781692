/**
 * @generated SignedSource<<eac20d88dcaa529e2cd27fff9885a4df>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Paginator$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly issueNavigatorPageInfo: {
    readonly firstIssuePosition: number | null | undefined;
    readonly lastIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly first: {
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
    readonly last: {
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_Paginator";
};
export type main_issueNavigator_Paginator$key = {
  readonly " $data"?: main_issueNavigator_Paginator$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Paginator">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "cursor"
  },
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "isCurrent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_Paginator",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        },
        {
          "kind": "ScalarField",
          "name": "lastIssuePosition"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "first",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "last",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ],
      "storageKey": "pageCursors(maxCursors:7)"
    }
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "a148b81aa698fb69850df944c78ece55";

export default node;
