export const isOnlyJqlAndDifferentToDefault = (
	jqlParam: string | undefined,
	defaultJqlTerm: string,
	filterParam: string | undefined,
) =>
	filterParam === undefined &&
	jqlParam !== undefined &&
	jqlParam.toLowerCase() !== defaultJqlTerm.toLowerCase();

export const isOnlyFilterAndDifferentToDefault = (
	jqlParam: string | undefined,
	defaultJqlTerm: string,
	filterJqlTerm: string | undefined,
) =>
	filterJqlTerm !== undefined &&
	jqlParam === undefined &&
	filterJqlTerm.toLowerCase() !== defaultJqlTerm.toLowerCase();

export const filterAndJqlIsTheSameAndDifferentToDefault = (
	jqlParam: string | undefined,
	defaultJqlTerm: string,
	filterJqlTerm: string | undefined,
) =>
	jqlParam !== undefined &&
	filterJqlTerm !== undefined &&
	jqlParam.toLowerCase() === filterJqlTerm.toLowerCase() &&
	filterJqlTerm.toLowerCase() !== defaultJqlTerm.toLowerCase();

export const jqlIsDifferentToFilter = (
	jqlParam: string | undefined,
	filterJqlTerm: string | undefined,
) =>
	jqlParam !== undefined &&
	filterJqlTerm !== undefined &&
	filterJqlTerm.toLowerCase() !== jqlParam.toLowerCase();

export const isFilterJqlModified = ({ query, filterJql }: { query: string; filterJql?: string }) =>
	filterJql !== undefined && query.toLowerCase().trim() !== filterJql.toLowerCase().trim();
