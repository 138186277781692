/**
 * @generated SignedSource<<8c6b6cbcc0b9be3dd14476f835f8a4bf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_jiraView$data = {
  readonly viewSettings?: {
    readonly isGroupingEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Header_jiraViewResult" | "main_issueNavigator_IssueResults_jiraView" | "topBar_issueNavigator_jiraViewResult">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_jiraView";
};
export type main_issueNavigator_IssueNavigatorUI_jiraView$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_jiraView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_jiraView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_jiraView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Header_jiraViewResult"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_jiraView"
    },
    {
      "kind": "FragmentSpread",
      "name": "topBar_issueNavigator_jiraViewResult"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isGroupingEnabled"
            }
          ]
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "ee6976361e3343ff61c071cd5b9a5175";

export default node;
