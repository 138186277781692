import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	singleSlowForgeFunction: {
		id: 'issue-navigator.controllers.jql-search-status.single-slow-forge-function',
		defaultMessage: '{appName} is still executing the {functionName} JQL function.',
		description:
			'Info message under JQL issue searchbar shown when an issue search is taking long time.',
	},
	multipleSlowForgeFunctionsFromTheSameApp: {
		id: 'issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-from-the-same-app',
		defaultMessage: '{appName} is still executing multiple JQL functions.',
		description:
			'Info message under JQL issue searchbar shown when an issue search is taking long time.',
	},
	multipleSlowForgeFunctionsTwoApps: {
		id: 'issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-two-apps',
		defaultMessage: '{appName1} and {appName2} are still executing multiple JQL functions.',
		description:
			'Info message under JQL issue searchbar shown when an issue search is taking long time.',
	},
	multipleSlowForgeFunctionsThreeApps: {
		id: 'issue-navigator.controllers.jql-search-status.multiple-slow-forge-functions-three-apps',
		defaultMessage:
			'{appName1}, {appName2}, and {appName3} are still executing multiple JQL functions.',
		description:
			'Info message under JQL issue searchbar shown when an issue search is taking long time.',
	},
});
