/**
 * @generated SignedSource<<1ecec124bc87c6002f2c45127804b4c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_userPreferences$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_userPreferences">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_userPreferences";
};
export type main_issueNavigator_IssueNavigator_userPreferences$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_userPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_userPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigator_userPreferences",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueNavigatorUI_userPreferences"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "1143e00ad7112d3bc319370b0e0a4b27";

export default node;
