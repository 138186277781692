import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu/types';
import FilterIcon from '@atlaskit/icon/core/filter';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = {
	isOpen?: boolean;
};

const QuickFiltersDropdown = ({ isOpen = false }: Props) => {
	const { formatMessage } = useIntl();
	const [{ match }] = useRouter();

	const urlPrefix = match.url.endsWith('/') ? `${match.url}?filter=` : `${match.url}/?filter=`;

	const isAnonymous = useIsAnonymous();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnOpenChange = useCallback(
		({ isOpen: newIsOpen }: OnOpenChangeArgs) => {
			if (newIsOpen) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'opened',
						actionSubject: 'dropdown',
					}),
					'issueNavigatorQuickFiltersDropdown',
				);
			} else {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'closed',
						actionSubject: 'dropdown',
					}),
					'issueNavigatorQuickFiltersDropdown',
				);
			}
		},
		[createAnalyticsEvent],
	);

	const handleOnClick = useCallback(
		(filter: string) => () => {
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: 'filter',
					action: 'selected',
				}),
				'issueNavigatorQuickFiltersDropdown',
				{
					filter,
				},
			);
		},
		[createAnalyticsEvent],
	);

	return (
		<DropdownMenu
			trigger={
				isVisualRefreshEnabled() && fg('visual-refresh_drop_2')
					? ({ triggerRef, ...props }) => (
							<IconButton
								{...props}
								icon={FilterIcon}
								label={formatMessage(messages.quickFilterDropdownTrigger)}
								ref={triggerRef}
								isTooltipDisabled={false}
							/>
						)
					: formatMessage(messages.quickFilterDropdownTrigger)
			}
			shouldRenderToParent
			defaultOpen={isOpen}
			testId="issue-navigator-action-quick-filters"
			onOpenChange={handleOnOpenChange}
		>
			<DropdownItemGroup>
				{!isAnonymous && (
					<DropdownItem href={`${urlPrefix}myopenissues`} onClick={handleOnClick('myopenissues')}>
						{formatMessage(messages.myOpenIssues)}
					</DropdownItem>
				)}
				{!isAnonymous && (
					<DropdownItem href={`${urlPrefix}reportedbyme`} onClick={handleOnClick('reportedbyme')}>
						{formatMessage(messages.reportedByMe)}
					</DropdownItem>
				)}
				<DropdownItem href={`${urlPrefix}allopenissues`} onClick={handleOnClick('allopenissues')}>
					{formatMessage(messages.openIssues)}
				</DropdownItem>
				<DropdownItem href={`${urlPrefix}doneissues`} onClick={handleOnClick('doneissues')}>
					{formatMessage(messages.doneIssues)}
				</DropdownItem>
				{!isAnonymous && (
					<DropdownItem
						href={`${urlPrefix}recentlyviewed`}
						onClick={handleOnClick('recentlyviewed')}
					>
						{formatMessage(messages.viewedRecently)}
					</DropdownItem>
				)}
				<DropdownItem
					href={`${urlPrefix}resolvedrecently`}
					onClick={handleOnClick('resolvedrecently')}
				>
					{formatMessage(messages.resolvedRecently)}
				</DropdownItem>
				<DropdownItem
					href={`${urlPrefix}updatedrecently`}
					onClick={handleOnClick('updatedrecently')}
				>
					{formatMessage(messages.updatedRecently)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default QuickFiltersDropdown;
