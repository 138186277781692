/** @jsx jsx */
import React, { type ComponentType, useMemo, type ReactNode } from 'react';
import { jsx, cssMap } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { PageTopBar } from '@atlassian/jira-horizontal-nav-page-topbar/src/ui/index.tsx';
import { GroupByDropdown as GroupByDropdownItem } from '@atlassian/jira-issue-table-group-by-dropdown/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { topBar_issueNavigator_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_filter.graphql';
import type {
	topBar_issueNavigator_issueResults$key as IssueResultsFragment,
	topBar_issueNavigator_issueResults$data as IssueResultsData,
} from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_issueResults.graphql';
import type { topBar_issueNavigator_jiraViewResult$key as JiraViewResultFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_jiraViewResult.graphql';
import type { topBar_issueNavigator_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_viewResult.graphql';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, TEAM_NAME, isJiraSpreadsheetView } from '../../common/constants.tsx';
import type { CustomHeaderProps } from '../../common/types.tsx';
import { HierarchyToggleItem } from '../../common/ui/hierarchy-toggle/index.tsx';
import { withReportErrors } from '../../common/ui/with-report-errors/index.tsx';
import { useSelectedViewState } from '../../controllers/selected-view-state/index.tsx';
import { useActiveJql, useResolvedJql } from '../../services/active-jql/index.tsx';
import { useFilterQuery } from '../../services/filter-query/index.tsx';

export type TopBarProps = {
	ActionMenu: ComponentType<CustomHeaderProps>;
	filter: FilterFragment | null;
	issueResults: IssueResultsFragment | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	jqlBuilder: ReactNode;
	viewResult: ViewResultFragment | null;
	jiraViewResult: JiraViewResultFragment | null;
};

const getLastPage = (
	pageCursors: IssueResultsData['pageCursors'] | undefined,
): number | null | undefined => {
	if (pageCursors?.last) {
		return pageCursors.last.pageNumber;
	}

	if (pageCursors?.around?.length) {
		return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
	}

	return undefined;
};

const styles = cssMap({
	jqlWrapper: {
		'--jql-wrapper-grow': '1',
	},
});

const TopBar = ({
	ActionMenu,
	filter,
	issueResults,
	HeaderSkeletonImage,
	jqlBuilder,
	viewResult,
	jiraViewResult,
}: TopBarProps) => {
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment topBar_issueNavigator_issueResults on JiraIssueConnection {
				issueNavigatorPageInfo {
					firstIssuePosition
				}
				pageCursors(maxCursors: 7) {
					around {
						pageNumber
					}
					last {
						pageNumber
					}
				}
				totalIssueSearchResultCount
			}
		`,
		issueResults,
	);

	// Compute page data for our search results which is needed when requesting items for the legacy meatball menu
	const offset = (issueResultsData?.issueNavigatorPageInfo?.firstIssuePosition ?? 1) - 1; // offset is 0 based so subtract 1
	const pages = getLastPage(issueResultsData?.pageCursors) ?? 0;
	const total = issueResultsData?.totalIssueSearchResultCount ?? 0;

	const searchResultPageData = useMemo(
		() => ({
			offset,
			pages,
			total,
		}),
		[offset, pages, total],
	);

	const { filterId } = useActiveJql();
	const jql = useResolvedJql();
	const { filterQueryIsFetching } = useFilterQuery();

	const filterData = useFragment(
		graphql`
			fragment topBar_issueNavigator_filter on JiraFilter {
				filterId
				name
				isFavourite
				jql
			}
		`,
		filter,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment topBar_issueNavigator_viewResult on JiraIssueSearchViewMetadata {
				...hierarchyToggle_issueNavigator_HierarchyToggleItem
			}
		`,
		viewResult,
	);

	const jiraViewData = useFragment<JiraViewResultFragment>(
		graphql`
			fragment topBar_issueNavigator_jiraViewResult on JiraView {
				__typename
				... on JiraSpreadsheetView {
					...hierarchyToggle_issueNavigator_HierarchyToggleItem
					...ui_issueTableGroupByDropdown_GroupByDropdown
				}
			}
		`,
		jiraViewResult,
	);

	const filterDetails = useMemo(
		() =>
			filterData
				? {
						filterId: filterData.filterId,
						name: filterData.name,
						jql: filterData.jql,
						isFavourite: Boolean(filterData.isFavourite),
					}
				: undefined,
		[filterData],
	);

	const [{ view }] = useSelectedViewState();

	let hierarchyToggleViewData: typeof jiraViewData | typeof viewResultData = null;

	if (fg('refactor_nin_to_jira_view_schema')) {
		hierarchyToggleViewData =
			jiraViewData && isJiraSpreadsheetView(jiraViewData) ? jiraViewData : null;
	} else {
		hierarchyToggleViewData = viewResultData;
	}

	const HierarchyToggle =
		hierarchyToggleViewData && view !== 'detail' ? (
			<HierarchyToggleItem viewResult={hierarchyToggleViewData} />
		) : null;

	const GroupByDropdown =
		jiraViewData && isJiraSpreadsheetView(jiraViewData) && view !== 'detail' ? (
			<GroupByDropdownItem spreadsheetView={jiraViewData} />
		) : null;

	if (filterQueryIsFetching) {
		return <HeaderSkeletonImage />;
	}

	const pageTopBar = (
		<PageTopBar
			actions={
				<ActionMenu
					jql={jql}
					filterId={filterId}
					filterDetails={filterDetails}
					searchResultPageData={searchResultPageData}
					HierarchyToggle={HierarchyToggle}
					GroupByDropdown={GroupByDropdown}
				/>
			}
			bottomBar={jqlBuilder}
		/>
	);

	if (getWillShowNav4()) {
		return (
			<UFOLabel name="top-bar">
				<div css={styles.jqlWrapper}>{pageTopBar}</div>
			</UFOLabel>
		);
	}
	return <UFOLabel name="top-bar">{pageTopBar}</UFOLabel>;
};

export default withReportErrors<TopBarProps>(TopBar, {
	id: 'ui.topbar.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});
