import React, { forwardRef, type MouseEvent, type KeyboardEvent } from 'react';
import { IconButton } from '@atlaskit/button/new';
import type { IconProps } from '@atlaskit/icon';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';

export type FilterIconButtonProps = {
	isSelected?: boolean;
	isDisabled?: boolean;
	icon: React.ComponentType<IconProps>;
	label: string;
	testId?: string;
	customTriggerProps?: TriggerProps; // when using as a custom trigger for some popups (like Share),
	onClick?: (e: MouseEvent | KeyboardEvent) => void;
};

export const FilterIconButton = forwardRef<HTMLElement, FilterIconButtonProps>(
	(
		{
			isSelected,
			onClick,
			testId,
			isDisabled,
			icon,
			label,
			customTriggerProps,
		}: FilterIconButtonProps,
		ref,
	) => (
		<Box xcss={iconButtonWrapperStyles}>
			<IconButton
				isSelected={isSelected}
				onClick={onClick}
				ref={ref}
				testId={testId}
				isDisabled={isDisabled}
				icon={icon}
				label={label}
				{...customTriggerProps}
			/>
		</Box>
	),
);

const iconButtonWrapperStyles = xcss({
	position: 'relative',
});
