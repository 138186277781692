/**
 * @generated SignedSource<<ef67c1b29917e04b29ae418c9bcc3e9d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_CardWithSelectedIssue_issue$data = {
  readonly key: string;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_issue">;
  readonly " $fragmentType": "main_issueNavigator_CardWithSelectedIssue_issue";
};
export type main_issueNavigator_CardWithSelectedIssue_issue$key = {
  readonly " $data"?: main_issueNavigator_CardWithSelectedIssue_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardWithSelectedIssue_issue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_CardWithSelectedIssue_issue",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Card_issue"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "8eb4cdf2fd848d8fc193cb41d7f7d67f";

export default node;
