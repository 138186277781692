import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ANALYTICS_SOURCE, EXPERIENCE_NAME } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';

/**
 * This debouncer is shared between both the success and fail calls so that the last one called in the current event
 * loop tick is the one that gets logged. This helps us overcome weaknesses in the Relay-based rendering where an error
 * being thrown taks an additional render cycle to reach the component managing the analytics.
 */
const sharedDebouncer = debounce((action) => action(), 0);

/**
 * Define the experience tracker analytics events for the issue navigator. These are used to mark the experience of
 * showing issue search results as a success or failure and is our key reliability SLO.
 */
export const useExperienceAnalytics = (
	/**
	 * Event emitted when page data has loaded and the key experience is interactive.
	 */
	onPageDataLoad: ((selectedView: IssueNavigatorViewId) => void) | null | undefined,
	/**
	 * Currently selected view mode.
	 *
	 * @deprecated This argument is now passed in directly to the onIssueSearchSuccess on onIssueSearchFail callbacks.
	 * This makes it easier to correctly attribute failed refetch requests which have modified the viewId.
	 */
	// TODO Remove this argument when cleaning up jira_spreadsheet_component_m1
	viewOld: IssueNavigatorViewId,
	/**
	 * Analytics attributes to be emitted as part of success/fail experience events.
	 */
	attributes?: ExperienceAttributes,
) => {
	const onExperienceStart = useExperienceStart({
		experience: EXPERIENCE_NAME,
		experienceId: undefined,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAME,
		attributes,
	});

	const onExperienceFail = useExperienceFail({
		experience: EXPERIENCE_NAME,
		attributes,
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFireUIAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'viewed',
			actionSubject: 'screen',
		});
		fireUIAnalytics(event, 'issueSearchResults', attributes ?? {});
	}, [attributes, createAnalyticsEvent]);

	const onIssueSearchSuccess = useCallback(
		(view: IssueNavigatorViewId) => {
			sharedDebouncer(() => {
				onExperienceStart();
				onExperienceSuccess();
				onFireUIAnalyticsEvent();
			});
			onPageDataLoad &&
				onPageDataLoad(
					expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
						? view
						: viewOld,
				);
		},
		[onExperienceStart, onExperienceSuccess, onFireUIAnalyticsEvent, onPageDataLoad, viewOld],
	);

	const onIssueSearchFail = useCallback(
		(location: string, error: Error, view: IssueNavigatorViewId) => {
			sharedDebouncer(() => {
				onExperienceStart();
				onExperienceFail(location, error);
			});
			onPageDataLoad &&
				onPageDataLoad(
					expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
						? view
						: viewOld,
				);
		},
		[onExperienceFail, onExperienceStart, onPageDataLoad, viewOld],
	);

	return {
		onIssueSearchSuccess,
		onIssueSearchFail,
	};
};
