/**
 * @generated SignedSource<<c6d20b50d30d33e809ff675bbd13cffa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_DetailView_issueResults$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator_issueResults" | "main_issueNavigator_CardList_fragment" | "main_issueNavigator_IssueAppWithData_issueResults">;
  readonly " $fragmentType": "main_issueNavigator_DetailView_issueResults";
};
export type main_issueNavigator_DetailView_issueResults$key = {
  readonly " $data"?: main_issueNavigator_DetailView_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_DetailView_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_CardList_fragment"
    },
    {
      "kind": "FragmentSpread",
      "name": "cardContainer_issueNavigator_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_issueResults"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "6b64dddb99a963cc9fea8872d644dae0";

export default node;
