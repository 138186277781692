/**
 * @generated SignedSource<<861c6a2b5276ec2a8b6ba119835e232f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedIssue_issueNavigator$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly key: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "selectedIssue_issueNavigator";
};
export type selectedIssue_issueNavigator$key = {
  readonly " $data"?: selectedIssue_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedIssue_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "selectedIssue_issueNavigator",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "966aa39a272d43746556adcb1b54c0d8";

export default node;
