import React, { createContext, useContext, useMemo, useState, type PropsWithChildren } from 'react';
import noop from 'lodash/noop';
import { useIsFetchingView, useIsRefreshing } from '../../services/issue-search/selectors.tsx';

type ColumnPickerLoadingState = {
	isLoading: boolean;
	setIsRestoringDefaultColumns: (newVal: boolean) => void;
};

const defaultState = {
	isLoading: false,
	setIsRestoringDefaultColumns: noop,
};

const ColumnPickerLoadingContext = createContext<ColumnPickerLoadingState>(defaultState);

export const useColumnPickerLoadingContext = () => useContext(ColumnPickerLoadingContext);

export const ColumnPickerLoadingContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [isRestoringDefaultColumns, setIsRestoringDefaultColumns] = useState(false);
	const isFetchingView = useIsFetchingView();
	const isRefreshing = useIsRefreshing();

	const isLoading = isRestoringDefaultColumns || isFetchingView || isRefreshing;

	const contextValue = useMemo(
		() => ({
			isLoading,
			setIsRestoringDefaultColumns,
		}),
		[isLoading],
	);

	return (
		<ColumnPickerLoadingContext.Provider value={contextValue}>
			{children}
		</ColumnPickerLoadingContext.Provider>
	);
};
