/**
 * @generated SignedSource<<9b73427f9f6b234bdde1cdd9521f0bbd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_CardList_fragment$data = {
  readonly edges: ReadonlyArray<{
    readonly fieldSets?: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardWithSelectedIssue_fieldSets" | "main_issueNavigator_Card_fieldSets">;
    } | null | undefined;
    readonly node: {
      readonly key: string;
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardWithSelectedIssue_issue" | "main_issueNavigator_Card_issue">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_CardList_fragment";
};
export type main_issueNavigator_CardList_fragment$key = {
  readonly " $data"?: main_issueNavigator_CardList_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardList_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_CardList_fragment",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_Card_issue"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_CardWithSelectedIssue_issue"
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 500
                }
              ],
              "concreteType": "JiraIssueFieldSetConnection",
              "kind": "LinkedField",
              "name": "fieldSets",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_Card_fieldSets"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_CardWithSelectedIssue_fieldSets"
                }
              ],
              "storageKey": "fieldSets(first:500)"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "1bb6eb92de80a9f68083519d7a9f3194";

export default node;
