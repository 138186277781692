import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	quickFilterDropdownTrigger: {
		id: 'issue-navigator-action-quick-filters.quick-filter-dropdown-trigger',
		defaultMessage: 'Quick filters',
		description: 'Text on the dropdown trigger button for quick filters.',
	},
	myOpenIssues: {
		id: 'issue-navigator-action-quick-filters.my-open-issues',
		defaultMessage: 'My open issues',
		description: 'Dropdown item which changes the JQL so users see their open issues in a project',
	},
	reportedByMe: {
		id: 'issue-navigator-action-quick-filters.reported-by-me',
		defaultMessage: 'Reported by me',
		description:
			'Dropdown item which changes the JQL so users see issues they reported in a project',
	},
	openIssues: {
		id: 'issue-navigator-action-quick-filters.open-issues',
		defaultMessage: 'Open issues',
		description: 'Dropdown item which changes the JQL so users see all open issues in a project',
	},
	doneIssues: {
		id: 'issue-navigator-action-quick-filters.done-issues',
		defaultMessage: 'Done issues',
		description: 'Dropdown item which changes the JQL so users see all done issues in a project',
	},
	viewedRecently: {
		id: 'issue-navigator-action-quick-filters.viewed-recently',
		defaultMessage: 'Viewed recently',
		description:
			'Dropdown item which changes the JQL so users see issues recently viewed in a project',
	},
	createdRecently: {
		id: 'issue-navigator-action-quick-filters.created-recently',
		defaultMessage: 'Created recently',
		description:
			'Dropdown item which changes the JQL so users see issues recently created in a project',
	},
	resolvedRecently: {
		id: 'issue-navigator-action-quick-filters.resolved-recently',
		defaultMessage: 'Resolved recently',
		description:
			'Dropdown item which changes the JQL so users see issues recently resolved in a project',
	},
	updatedRecently: {
		id: 'issue-navigator-action-quick-filters.updated-recently',
		defaultMessage: 'Updated recently',
		description:
			'Dropdown item which changes the JQL so users see issues recently updated in a project',
	},
});

export default messages;
