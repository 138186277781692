import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateErrorHeader: {
		id: 'issue-navigator.issue-results.error-view.empty-state-error-header',
		defaultMessage: 'We couldn’t load your search results',
		description: 'Header message shown when there was an error searching for issues.',
	},
	emptyStateErrorDescription: {
		id: 'issue-navigator.issue-results.error-view.empty-state-error-description',
		defaultMessage: 'Refresh to try again.',
		description: 'Description shown when there was an error searching for issues.',
	},
	emptyStateErrorAction: {
		id: 'issue-navigator.issue-results.error-view.empty-state-error-action',
		defaultMessage: 'Refresh',
		description: 'CTA button copy shown when there was an error searching for issues.',
	},
});
