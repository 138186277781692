import { components as columnPicker } from '@atlassian/jira-issue-table-column-picker/src/common/ui/index.tsx';
import { Footer } from './footer/index.tsx';
import { NoColumns } from './no-columns/index.tsx';

// Default components that can be overridden and composed by the consumer.
export const components = {
	columnPicker,
	Footer,
	NoColumns,
};
