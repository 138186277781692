/**
 * @generated SignedSource<<46f81950992ed670a545cbca8fe20325>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_refetchQueryNew$data = {
  readonly jira: {
    readonly issueSearchViewResult: {
      readonly __typename: "JiraIssueSearchView";
      readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigator" | "main_issueNavigator_IssueNavigatorUI_viewResult" | "selectedView_issueNavigator_SelectedViewContainer_issueSearchView">;
    } | null | undefined;
    readonly jiraIssueSearchView?: {
      readonly __typename: string;
      readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigatorGrouping" | "main_issueNavigator_IssueNavigatorUI_jiraView" | "main_issueNavigator_IssueNavigator_detailedViewData" | "main_issueNavigator_IssueNavigator_groupedListViewData" | "main_issueNavigator_IssueNavigator_listViewData" | "selectedView_issueNavigator_SelectedViewContainer_jiraView">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_refetchQueryNew";
};
export type main_issueNavigator_IssueNavigator_refetchQueryNew$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_refetchQueryNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew">;
};

import IssueNavigatorIssueSearchRefetchQuery_graphql from './IssueNavigatorIssueSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "Variable",
  "name": "issueSearchInput",
  "variableName": "issueSearchInput"
},
v2 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v3 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "groupBy"
    },
    {
      "kind": "RootArgument",
      "name": "isPaginating"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "issueTypeId"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "kind": "RootArgument",
      "name": "projectId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    },
    {
      "kind": "RootArgument",
      "name": "viewConfigInput"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": IssueNavigatorIssueSearchRefetchQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_refetchQueryNew",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueNavigator_issueSearchData"
    },
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "Variable",
                  "name": "viewConfigInput",
                  "variableName": "staticViewInput"
                },
                (v3/*: any*/)
              ],
              "kind": "LinkedField",
              "name": "jiraIssueSearchView",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "issueSearch_issueNavigatorGrouping"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_IssueNavigatorUI_jiraView"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "selectedView_issueNavigator_SelectedViewContainer_jiraView"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "main_issueNavigator_IssueNavigator_detailedViewData"
                    }
                  ],
                  "type": "JiraDetailedView"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "main_issueNavigator_IssueNavigator_groupedListViewData"
                    }
                  ],
                  "type": "JiraGroupedListView"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "main_issueNavigator_IssueNavigator_listViewData"
                    }
                  ],
                  "type": "JiraListView"
                }
              ]
            }
          ]
        },
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "filterId",
              "variableName": "filterId"
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "kind": "LinkedField",
          "name": "issueSearchViewResult",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "issueSearch_issueNavigator"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "selectedView_issueNavigator_SelectedViewContainer_issueSearchView"
                }
              ],
              "type": "JiraIssueSearchView"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_IssueNavigatorUI_viewResult"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "7fdf52745e38445860e41a11b3855d60";

export default node;
