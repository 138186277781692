import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import ChevronUp from '@atlaskit/icon/glyph/chevron-up';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useSelectedIssueStateOldActions } from '../../../../../../controllers/selected-issue-state-old/index.tsx';
import {
	usePreviousIssueKey,
	useSelectedIssueActions,
} from '../../../../../../controllers/selected-issue/hooks.tsx';
import messages from './messages.tsx';

export type Props = {
	/**
	 * Position of the currently selected issue in the search results.
	 */
	// TODO Remove prop when cleaning up jira_spreadsheet_component_m1
	selectedIssuePosition?: number;
};

const IssuePaginationPreviousButton = ({ selectedIssuePosition }: Props) => {
	const { formatMessage } = useIntl();

	let selectPreviousIssue;
	let issueKey;
	let isDisabled;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const actions = useSelectedIssueActions();
		selectPreviousIssue = actions.selectPreviousIssue;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issueKey = usePreviousIssueKey();
		isDisabled = !issueKey;
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const actions = useSelectedIssueStateOldActions();
		selectPreviousIssue = actions.selectPreviousIssue;
		issueKey = actions.getPreviousIssue().issueKey;
		isDisabled = selectedIssuePosition === 1;
	}
	const previousIssueMessage = issueKey
		? formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.previousIssueTooltipIssueTermRefresh
					: messages.previousIssueTooltip,
				{ issueKey },
			)
		: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.previousIssueWithoutKeyTooltipIssueTermRefresh
					: messages.previousIssueWithoutKeyTooltip,
			);

	const onClick = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (selectPreviousIssue()) {
				fireUIAnalytics(analyticsEvent, 'previousIssue');
			}
		},
		[selectPreviousIssue],
	);

	return (
		<IconButton
			icon={ChevronUp}
			label={isDisabled ? formatMessage(messages.disabledButtonLabel) : previousIssueMessage}
			onClick={onClick}
			appearance="subtle"
			testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.previous-button"
			isDisabled={isDisabled}
			spacing="compact"
			isTooltipDisabled={isDisabled}
		/>
	);
};

export default IssuePaginationPreviousButton;
