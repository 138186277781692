/**
 * @generated SignedSource<<cc976390fd8b29859278e8cbe5af37b2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view$data = {
  readonly fieldSets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldSetId: string;
      };
    } | null | undefined>;
  };
  readonly viewId: string;
  readonly " $fragmentType": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view";
};
export type issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view$key = {
  readonly " $data"?: issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    }
  ],
  "kind": "Fragment",
  "name": "issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "fieldSetSelectedState": "SELECTED"
            }
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          }
        ],
        "concreteType": "JiraIssueSearchFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSets",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraIssueSearchFieldSetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "JiraIssueSearchFieldSet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "kind": "ScalarField",
                          "name": "fieldSetId"
                        },
                        "action": "THROW",
                        "path": "fieldSets.edges.node.fieldSetId"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "fieldSets.edges.node"
                }
              ]
            },
            "action": "THROW",
            "path": "fieldSets.edges"
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSets"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "73c821f489985ab33d96817dee8a6600";

export default node;
