import React from 'react';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

const RegisterDotShortcut = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { open } = useActionsCommandPalette();

	return (
		<Shortcuts
			keyMap={{
				'.': {
					callback: () => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'pressed',
								actionSubject: 'keyboardShortcut',
							}),
							'dotDialogKeyboardShortcut',
							{
								keyPressed: '.',
								keyboardShortcut: true,
							},
						);

						open();
					},
				},
			}}
		/>
	);
};

export default RegisterDotShortcut;
