import React, {
	useLayoutEffect,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type PropsWithChildren,
} from 'react';
import { stringify } from 'querystring';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { type CustomTriggerProps, DropdownItem } from '@atlaskit/dropdown-menu';
import ExportIcon from '@atlaskit/icon/core/migration/upload--export';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import AdvancedSearchButton from '@atlassian/jira-issue-navigator-action-advanced-search-button/src/index.tsx';
import AsyncExportIssues from '@atlassian/jira-issue-navigator-action-export-issues/src/ui/async.tsx';
import MeatballMenu from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/index.tsx';
import AsyncMeatballMenu from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/async.tsx';
import {
	GO_TO_ALL_ISSUES,
	HIERARCHY_TOGGLE,
} from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/constants.tsx';
import QuickFiltersDropdown from '@atlassian/jira-issue-navigator-action-quick-filters/src/index.tsx';
import { ShareButton } from '@atlassian/jira-issue-navigator-action-share-button/src/ui/index.tsx';
import { FilterIconButton } from '@atlassian/jira-issue-navigator-actions-common/src/ui/filter-icon-button/index.tsx';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import {
	markOnce,
	marks,
} from '@atlassian/jira-issue-navigator/src/common/utils/performance-analytics.tsx';
import ViewSwitcher from '@atlassian/jira-issue-navigator/src/ui/view-switcher/index.tsx';
import { useNaturalLanguageMode } from '@atlassian/jira-jql-builder-with-ai/src/controllers/natural-language-mode/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import messages from './messages.tsx';

type Props = {
	jql: string;
	filterId?: string;
	isModifiedFilter?: boolean;
	searchResultPageData: SearchResultPageData;
	isQuickFiltersDisabled?: boolean;
	HierarchyToggle: React.ReactNode;
	GroupByDropdown: React.ReactNode;
};

const HierarchyToggleWrapper = ({ children }: { children: React.ReactNode }) => (
	<Box
		paddingBlock="space.0"
		paddingInline="space.150"
		onClick={(e: React.MouseEvent | React.KeyboardEvent) => e.stopPropagation()}
	>
		{children}
	</Box>
);

const AdditionalItemsWrapper = componentWithCondition(
	() =>
		// eslint-disable-next-line jira/ff/no-preconditioning
		expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
		fg('jsc_m2_hierarchy_fe_changes'),
	({ children }: PropsWithChildren<{}>) => <Box xcss={menuItemStyles}>{children}</Box>,
	({ children }: PropsWithChildren<{}>) => <>{children}</>,
);
const PageActions = ({
	jql,
	filterId,
	isModifiedFilter,
	searchResultPageData,
	isQuickFiltersDisabled = false,
	HierarchyToggle,
	GroupByDropdown,
}: Props): ReactElement<ComponentProps<typeof ActionsContainer>> => {
	markOnce(marks.PAGE_ACTIONS_START);
	useLayoutEffect(() => {
		markOnce(marks.PAGE_ACTIONS_END);
	}, []);

	const isAnonymous = useIsAnonymous();

	const savedFilter = isFilterId(filterId) ? filterId : undefined; // filter out non-standard PIN named filters
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAdvanceSearchClickAnalytics = (
		e: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
	) => {
		const leftClick = e.type === 'contextmenu';
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		const withCmd = e.ctrlKey || e.metaKey;
		fireUIAnalytics(analyticsEvent, 'advancedSearchButton', {
			openedIn: leftClick && !withCmd ? 'sameTab' : 'newTab',
		});
	};

	const jqlString = isModifiedFilter ? jql : undefined;
	const advancedSearchHref = `/issues/?${stringify({ jql: jqlString, filter: filterId })}`;

	const viewOptions =
		// eslint-disable-next-line jira/ff/no-preconditioning
		HierarchyToggle !== null &&
		HierarchyToggle !== undefined &&
		expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
		fg('jsc_m2_hierarchy_fe_changes') ? (
			<DropdownItem key={HIERARCHY_TOGGLE} component={HierarchyToggleWrapper}>
				{HierarchyToggle}
			</DropdownItem>
		) : null;

	const additionalMenuItems = (
		<DropdownItem
			key={GO_TO_ALL_ISSUES}
			onClick={(e) => fireAdvanceSearchClickAnalytics(e)}
			href={advancedSearchHref}
		>
			<AdditionalItemsWrapper>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.allIssuesIssueTermRefresh
						: messages.allIssues,
				)}
			</AdditionalItemsWrapper>
		</DropdownItem>
	);

	const customExportTrigger = (
		isOpen: boolean,
		triggerButtonProps: CustomTriggerProps<HTMLElement>,
	) => {
		const { triggerRef, ...props } = triggerButtonProps;
		return (
			<FilterIconButton
				customTriggerProps={{
					...props,
					ref: triggerRef,
				}}
				icon={ExportIcon}
				label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.exportIssuesButtonIconLabelIssueTermRefresh
						: messages.exportIssuesButtonIconLabel,
				)}
				isSelected={isOpen}
			/>
		);
	};

	const customExportDisabled = (
		<FilterIconButton
			isDisabled
			icon={ExportIcon}
			label={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.exportIssuesButtonIconLabelIssueTermRefresh
					: messages.exportIssuesButtonIconLabel,
			)}
		/>
	);

	let enableQuickFilters = false;
	if (getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isNaturalLanguageMode] = useNaturalLanguageMode();
		enableQuickFilters = !isNaturalLanguageMode && !isQuickFiltersDisabled;
	}

	return (
		<ActionsContainer>
			{!isAnonymous && getWillShowNav3() && (
				<StyledPageAction>
					<ShareButton jql={jql} filterId={savedFilter} isModifiedFilter={isModifiedFilter} />
				</StyledPageAction>
			)}
			{getWillShowNav4() && enableQuickFilters && (
				<StyledPageAction>
					<QuickFiltersDropdown />
				</StyledPageAction>
			)}
			{GroupByDropdown &&
				expVal('jira_spreadsheet_component_grouping', 'isGroupingEnabled', false) && (
					<StyledPageAction>{GroupByDropdown}</StyledPageAction>
				)}
			<StyledPageAction>
				<AsyncExportIssues
					jql={jql}
					filterId={savedFilter}
					isModifiedFilter={isModifiedFilter}
					showEcosystemApps={false}
					{...(getWillShowNav4() && {
						customTriggerButton: customExportTrigger,
						customDisabledButton: customExportDisabled,
					})}
				/>
			</StyledPageAction>
			{getWillShowNav3() && (
				<StyledPageAction>
					<AdvancedSearchButton
						jql={jql}
						filterId={savedFilter}
						isModifiedFilter={isModifiedFilter}
					/>
				</StyledPageAction>
			)}
			<StyledPageAction>
				<ViewSwitcher filterId={filterId} />
			</StyledPageAction>
			<StyledPageAction>
				{fg('jira-nin-meatball-menu-entrypoint') ? (
					<MeatballMenu
						jql={jql}
						filterId={savedFilter}
						searchResultPageData={searchResultPageData}
						additionalMenuItems={getWillShowNav4() && additionalMenuItems}
						viewOptions={viewOptions}
					/>
				) : (
					<AsyncMeatballMenu
						jql={jql}
						filterId={savedFilter}
						searchResultPageData={searchResultPageData}
						additionalMenuItems={getWillShowNav4() && additionalMenuItems}
						viewOptions={viewOptions}
					/>
				)}
			</StyledPageAction>
		</ActionsContainer>
	);
};

export default PageActions;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPageAction = styled.div({
	margin: `0 ${token('space.050', '4px')}`,
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
});

const menuItemStyles = xcss({
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});
