import React, { forwardRef, type MouseEvent, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export type FilterButtonProps = {
	isSelected?: boolean;
	isDisabled?: boolean;
	text: string;
	label: string;
	testId?: string;
	vcId?: string;
	customTriggerProps?: TriggerProps; // when using as a custom trigger for some popups (like Share),
	onClick?: (e: MouseEvent | KeyboardEvent) => void;
};

export const FilterButton = forwardRef<HTMLElement, FilterButtonProps>(
	(
		{
			isSelected,
			onClick,
			testId,
			vcId,
			isDisabled,
			text,
			label,
			customTriggerProps,
		}: FilterButtonProps,
		ref,
	) => (
		<Box xcss={buttonWrapperStyles} data-vc={`${vcId}-button${__SERVER__ ? '-ssr' : ''}`}>
			<Button
				isSelected={isSelected}
				onClick={onClick}
				ref={ref}
				testId={testId}
				isDisabled={isDisabled}
				iconAfter={
					<IconWrapper>
						<ChevronDownIcon label={label} color="currentColor" />
					</IconWrapper>
				}
				{...customTriggerProps}
			>
				{text}
			</Button>
		</Box>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
	/* Offset inner icon padding to match designs, as changing icon size to small (contrary
    /* to what Atlaskit docs say) does reduce icon shape size, and we don't want that */
	marginRight: token('space.negative.075', '-6px'),
});

const buttonWrapperStyles = xcss({
	position: 'relative',
});
