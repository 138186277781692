/**
 * @generated SignedSource<<1eb167471fb64a02bbc7a0985243c9bc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueAppWithData_issueResults$data = {
  readonly __id: string;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueNavigator">;
  readonly " $fragmentType": "main_issueNavigator_IssueAppWithData_issueResults";
};
export type main_issueNavigator_IssueAppWithData_issueResults$key = {
  readonly " $data"?: main_issueNavigator_IssueAppWithData_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueAppWithData_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "headerActions_issueNavigator"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "267fadd3844f360bfc7e5d9f255f3ee7";

export default node;
