import { useCallback, useEffect, useRef } from 'react';

/**
 * Creates a throttled function that only executes once every `wait` milliseconds.
 * The function executes on the first call, and performs no action until the `wait` period has passed.
 *
 * The returned function is stable across re-renders.
 *
 * @param fn function to call
 * @param wait milliseconds to wait before calling the function again
 * @returns A wrapped function that throttles the original function.
 */
export const useThrottle = (fn: (() => void) | undefined, wait = 100) => {
	const fnRef = useRef(fn);
	fnRef.current = fn;

	const waitRef = useRef(wait);
	waitRef.current = wait;

	const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

	const throttled = useCallback(() => {
		if (timeout.current !== undefined) {
			return;
		}

		fnRef.current?.();

		timeout.current = setTimeout(() => {
			timeout.current = undefined;
		}, waitRef.current);
		// Ensure the callback is not recreated when props change,
		// dependency array should be empty.
	}, []);

	// Cleanup the timeout on unmount.
	useEffect(() => {
		return () => {
			if (timeout.current !== undefined) {
				clearTimeout(timeout.current);
			}
		};
	}, []);

	return throttled;
};
