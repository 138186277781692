import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssues: {
		// i18n ID doesn't match package, as this message has been moved but we
		// do not want the translations to become invalidated

		id: 'issue-navigator.common.empty-list-icon.no-issues',
		defaultMessage: 'No issues were found matching your search',
		description: 'This message is displayed when no issues matched the search criteria',
	},
	noIssuesIssueTermRefresh: {
		id: 'issue-navigator.issue-results.list-view.no-issues.no-issues-issue-term-refresh',
		defaultMessage: "There's nothing matching your search",
		description: 'This message is displayed when no issues matched the search criteria',
	},
});
