import { useEffect } from 'react';
import { stringify } from 'query-string';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';

export const MEATBALL_MENU_ENDPOINT = '/rest/issueNav/1/issueNav/operations/tools';

export type MeatballMenuItem = {
	id: string;
	relativeLink: boolean;
	link: string;
	label: string;
	rel: string;
	styleClass?: string;
};

type ResponseItem = {
	items: MeatballMenuItem[];
	empty: boolean;
	label?: string;
	id?: string;
};

type Response = ResponseItem[];

export type RequestPayload = {
	searchResultsTotal: number;
	searchResultsPages: number;
	offset: number;
	useColumns: boolean;
	skipColumns: boolean;
	new?: boolean;
};

type ReturnObject = {
	loading: boolean;
	error: Error | undefined;
	data: Response | undefined;
	fetch: (arg1: RequestPayload) => Promise<Response | undefined>;
	requestPayload: RequestPayload;
};

export const fetchMeatballMenuItems = (options: RequestPayload): Promise<Response> =>
	performPostRequest(MEATBALL_MENU_ENDPOINT, {
		credentials: 'same-origin',
		body: stringify(options),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			'X-Atlassian-Token': 'no-check',
			__amdModuleName: 'jira/issue/utils/xsrf-token-header',
		},
	});

export const useMeatballMenuItems = (searchResultPageData: SearchResultPageData): ReturnObject => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { loading, error, data, fetch } = useService<Response, RequestPayload>(
		fetchMeatballMenuItems,
	);

	const { offset, pages, total } = searchResultPageData;

	// Issue count here is used to show warning message in
	// bulk edit page. We show a warning message if the issue count is > 999.
	const allIssues = total > 999 ? 1000 : 999;

	const requestPayload = {
		offset,
		searchResultsTotal:
			// eslint-disable-next-line jira/ff/no-preconditioning
			expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
			fg('jsc_m2_hierarchy_fe_changes')
				? allIssues
				: total,
		searchResultsPages: pages,
		// useColumns and skipColumns aren't used in the endpoint but is required for the constructor
		useColumns: false,
		skipColumns: false,
		new: true,
	};

	useEffect(() => {
		if (loading) {
			return;
		}

		if (error) {
			fireErrorAnalytics({
				error,
				event: createAnalyticsEvent({}),
				meta: {
					id: 'useMeatballMenuItems',
				},
			});
		} else if (data) {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'useMeatballMenuItems succeeded',
				'meatballMenu',
			);
		}
	}, [createAnalyticsEvent, data, error, loading]);

	return {
		loading,
		error,
		data,
		fetch,
		requestPayload,
	};
};
