import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const viewDetailsPopupExperience = new UFOExperience(
	'jira-issue-navigator.custom-filters.view-details-popup',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);

export const viewNewSubscriptionModal = new UFOExperience(
	'jira-issue-navigator.custom-filters.details-popup.view-new-subscription-modal',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);
