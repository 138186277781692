import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nextPageShortcutLabel: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.next-page-shortcut-label',
		defaultMessage: 'Next page',
		description: 'Label shown for the "Go to next page" shortcut key',
	},
	prevPageShortcutLabel: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.prev-page-shortcut-label',
		defaultMessage: 'Previous page',
		description: 'Label shown for the "Go to previous page" shortcut key',
	},
	prevPageButton: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.prev-page-button',
		defaultMessage: 'Previous page',
		description: 'Label shown for the "Previous page" button',
	},
	nextPageButton: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.next-page-button',
		defaultMessage: 'Next page',
		description: 'Label shown for the "Next page" button',
	},
	disabledButtonTooltip: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.disabled-button-tooltip',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more issues.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
	disabledButtonTooltipIssueTermRefresh: {
		id: 'issue-navigator.issue-results.detail-view.card-container.paginator.disabled-button-tooltip-issue-term-refresh',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more work items.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
});
