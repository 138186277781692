import type { ProductId } from '@atlaskit/share';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types.tsx';

const subProductMap: Record<SubProduct, ProductId> = {
	addon: 'jira-addon',
	core: 'jira-core',
	polaris: 'jira-polaris',
	portfolio: 'jira-portfolio',
	serviceDesk: 'jira-servicedesk',
	software: 'jira-software',
	platform: 'jira-platform',
	unknown: 'jira-unknown',
};

export const getShareSubProductId = (subProduct?: SubProduct | null): ProductId =>
	(subProduct && subProductMap[subProduct]) || 'jira-platform';
